import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export const navigateToPage = (route, navigate) => {
  if (route === '-1') {
    // Go back to the previous page
    window.history.back();
  } else if (route.includes('http://') || route.includes('https://')) {
    window.location.href = route;
  } else {
    navigate(route);
  }

};

