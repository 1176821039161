import { FETCH_POSTS } from "./type";
import { axiosCustom } from "../../utils/request";


export const fetchingFaq = () => async (dispatch) => {
    const response = await axiosCustom.get("pages/5058");
    const { data = false, status } = response;
    if (status === 200) {
        if (data) {
            dispatch({
                type: FETCH_POSTS,
                items: data.acf,
                pageTitle: data.title,
                metaTitle: data?.page_meta?.meta_title,
                metaImage:data?.page_meta?.meta_image,
                metaDescription: data?.page_meta?.meta_description
            });
        }
    }
};
