import { FETCH_POSTS, FETCH_POSTS_FILTER, FETCH_EXPERIENCES_DETAIL } from "../actions/Experiences/type";

const initialState = {
   itemsPopular: [],
   items: [],
   itemDetails: [],
   metaImage:"",
metaTitle:"",
   metaDescription:""
};

const ExperiencesReducer =  (state = initialState, action) => {
   switch (action.type) {
      case FETCH_POSTS:
         return {
            ...state,
            ...action,
            itemsPopular: action.items.popular,
         };
      case FETCH_POSTS_FILTER:
         return {
            ...state,
            ...action,
         };
      case FETCH_EXPERIENCES_DETAIL:
            return {
                ...state,
                itemDetails: action.items,
            };
      default:
         return state;
   }
};
export default ExperiencesReducer;
