import { FETCH_POSTS, FETCH_POSTS_DETAIL, FETCH_PLEDGE_OPTION } from "./type";
import { axiosCustom } from "../../utils/request";

export const fetchingAttraction = () => async (dispatch) => {
    const response = await axiosCustom.get("pages/5594");
    const { data = false, status } = response;
    if (status === 200) {
        if (data) {
            dispatch({
                type: FETCH_POSTS,
                items: data.acf,
                metaTitle: data?.page_meta?.meta_title,
                metaImage:data?.page_meta?.meta_image,
                metaDescription: data?.page_meta?.meta_description
            });
        }
    }
};


export const fetchingAttractionDetail = (id) => async (dispatch) => {
    console.log('id ===', id);
        const response = await axiosCustom.get(`attractions/${id}`);
        const { data = false, status } = response;
        if (status === 200) {
            if (data) {
                console.log('data fetched === ', data);
                dispatch({
                    type: FETCH_POSTS_DETAIL,
                    items: data.acf,
                    metaTitle: data?.page_meta?.meta_title,
                    metaImage:data?.page_meta?.meta_image,
                    metaDescription: data?.page_meta?.meta_description
                });
            }
        }
    };


    export const fetchPledgeOptions = () => async (dispatch) => {
            const response = await axiosCustom.get(`pages/5771`);
            const { data = false, status } = response;
            if (status === 200) {
                if (data) {
                    console.log('data fetched === ', data);
                    dispatch({
                        type: FETCH_PLEDGE_OPTION,
                        items: data.acf,
                    });
                }
            }
        };