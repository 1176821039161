import { combineReducers } from "redux";

import attraction from "./attraction";
import checkDevice from "./checkDevice";
import experiences from "./experiences";
import Faq from "./Faq";
import careandconservation from "./conservation";
import fishdetails from "./fishdetails";
import fishUpload from "./fishUpload";
import home from "./home";
import allanimals from "./allanimals";
import discover from "./discover";
import allChambers from "./allChambers";
import termsandconditions from "./termsandconditions";
import fishLibrary from "./fishLibrary";
import fishHistory from "./fishHistory";
import userDataReducer from "./userData";
import navigateUrlsReducer from "./navigateUrls";

export default combineReducers({
   attraction,
   checkDevice,
   experiences,
   Faq,
   careandconservation,
   fishdetails,
   fishUpload,
   home,
   discover,
   allanimals,
   termsandconditions,
   allChambers,
   fishLibrary,
   fishHistory,
   userDataReducer,
   navigateUrlsReducer

});
