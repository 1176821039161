import { FETCH_PAGES, FETCH_PAGES_ERROR, FETCH_PAGES_ANIMAL_DETAILS, FETCH_PAGES_ANIMAL_DETAILS_PAGINATION } from "./type";
import { axiosCustom } from "../../utils/request";

export const fetchingAnimals = () => async (dispatch) => {
  try {
    const response = await axiosCustom.get("pages/5580");
    const { data = false, status } = response;
    if (status === 200) {
      if (data) {
        dispatch({
          type: FETCH_PAGES,
          items: data.acf,
          metaTitle: data?.page_meta?.meta_title,
          metaImage:data?.page_meta?.meta_image,
          metaDescription: data?.page_meta?.meta_description
        });
      }
    } else {
      dispatch({
        type: FETCH_PAGES_ERROR,
        error: "Error: Unable to fetch pages.",
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_PAGES_ERROR,
      error: "Error: " + error.message,
    });
  }
};

export const fetchingAnimalsDetails = (term_id) => async (dispatch) => {
  try {
    const response = await axiosCustom.get("animals", {
      params: {
        populate: "chambers",
        "animal-categories": term_id,
        // "per_page":100
      }
    });
    const { data = [], status } = response; 
    if (status === 200) {
      if (data) {
        dispatch({
          type: FETCH_PAGES_ANIMAL_DETAILS,
          items: data,
        });
      }
    } else {
      dispatch({
        type: FETCH_PAGES_ERROR,
        error: "Error: Unable to fetch animal details.",
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_PAGES_ERROR,
      error: "Error: " + error.message,
    });
  }
};




export const fetchingAnimalDetailsPagination = () => async (dispatch) => {
    try {
      const response = await axiosCustom.get("animals?animal-categories");
      const { data = false, status } = response;
      if (status === 200) {
        if (data) {
          dispatch({
            type: FETCH_PAGES_ANIMAL_DETAILS_PAGINATION,
            items: data.acf,
          });
        }
      } else {
        dispatch({
          type: FETCH_PAGES_ERROR,
          error: "Error: Unable to fetch pages.",
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_PAGES_ERROR,
        error: "Error: " + error.message,
      });
    }
  };
