import React, { useState, useEffect } from "react";
import atlantisVideo from "../../assets/videos/Atlantis_Video.mp4";
import ActivitySlider from "../ActivitySlider/ActivitySlider";
import DesktopBannerContent from "./DesktopBannerContent";
import MobileBannerContent from "./MobileBannerContent";
import PlayVideo from "../PlayVideo/PlayVideo";
import { checkDeviceType } from '../../actions/CheckDevice';
import { useSelector, useDispatch } from "react-redux";

// import { fetchingActivity } from "../../actions/Experiences";
// import { useSelector, useDispatch } from "react-redux";

const HomeBanner = ({items, slideData, onButtonClick}) => {

  const dispatch = useDispatch();
const isloggedIn = localStorage.getItem("loginData");

  const deviceType = useSelector((state) => state.checkDevice.deviceType);


  useEffect(() => {
    dispatch(checkDeviceType());
  }, [dispatch]);

  // const handleResize = () => {
  //   setWindowWidth(window.innerWidth);
  // };
  // useEffect(() => {
  //   if (windowWidth == null) {
  //     handleResize();
  //   }
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // });

  return (
    <>
      <div className="home-banner">
        <div className={isloggedIn ?"video-container userLoggedIN": "video-container"}>
           <PlayVideo src={atlantisVideo} />
           <MobileBannerContent items={items}/>
        </div>
        {/* <div className="gradient-box"></div> */}
        {/* {
          windowWidth >= 970 ? <DesktopBannerContent items={items}/> : <MobileBannerContent items={items}/>
        }  */}
        <div className="home-activity">
          {/* {deviceType=='mobile'} */}
          <div 
           data-aos={deviceType=='mobile'?"fade-up":""}
           data-aos-delay={deviceType=='mobile'?"200":""}
          >
          <ActivitySlider
            activeClick={true}
            slideData={items?.section_experience?.experience_list}
            title={items?.section_experience?.tittle}
            Paragraph={items?.section_experience?.description}
            slidesToShow={1.2}
            customClassForTitle="d-center-m-left"
            customClassForCardName="d-m-center"
            buttonText="View More"
            btnWidth="160px"
            showButton={true}
            contentClickRoute={'/experiences/details/'}
            onClick={onButtonClick}
            responsiveSettings={[
              { breakpoint: 500, settings: { slidesToShow: 1.2 } },
              { breakpoint: 900, settings: { slidesToShow: 2 } },
              { breakpoint: 1500, settings: { slidesToShow: 2 } },
              { breakpoint: 1800, settings: { slidesToShow: 3.8 } },
              { breakpoint: 2500, settings: { slidesToShow: 4.1 } },
            ]} />
            </div>
        </div>
      </div>
    </>

  );
};

export default HomeBanner;
