import React from "react";

const YellowIconWithTitle = ({ numberCount, title, image, onClick, textColor}) => {
  return (
    <>
        <div className='number-card' onClick={onClick} >
          <div className={`light-bg ${image ? 'with-border' : ''}`}>
            {numberCount === null ? (
              <img className="img" alt="" src={image} />
            ) : (
              <h4 className="number-count">{numberCount}</h4>
            )}
          </div>
          <span className={textColor=='dark'?'species-name-dark':"species-name"}>{title}</span>
        </div>
    </>
  );
}

export default YellowIconWithTitle;
