import { FETCH_PAGES_ANIMAL_DETAILS, FETCH_PAGES_ANIMAL_DETAILS_PAGINATION, FETCH_PAGES } from "../actions/AllAnimals/type";

const initialState = {
  animalListResponseData: [],

  animalDetailsResponseData: [],
  metaTitle: "",
  metaDescription: "",

  itemsDetails: [{
    animals: [],
  }],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAGES:
      return {
        ...state,
        animalListResponseData: action.items,
        metaTitle: action.metaTitle,
        metaImage:action.metaImage,
        metaDescription: action.metaDescription,
      };
    case FETCH_PAGES_ANIMAL_DETAILS:
      return {
        ...state,
        animalDetailsResponseData: action.items,
      };
    case FETCH_PAGES_ANIMAL_DETAILS_PAGINATION:
      return {
        ...state,
        itemsDetails: {
          ...state.itemsDetails,
          [0]: {
            animals: [...state.itemsDetails[0].animals, ...action.items],
          },
        },
      };
    default:
      return state;
  }
};
