import React, { Fragment, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import images from '../../assets/images/images';
import Accordion from '../../components/Accordion/Accordion';
import TitleBlue from '../../components/UI_Elements/TitleBlue/TitleBlue';
import ParagraphDark from '../../components/UI_Elements/ParagraphDark/ParagraphDark';
import Button from '../../components/UI_Elements/Button/Button';
import GridItem from '../../components/UI_Elements/GridItem/GridItem';
import MainTitleContent from '../../components/UI_Elements/MainTitleContent/MainTitleContent';
import { useDispatch, useSelector } from "react-redux";
import { checkDeviceType } from '../../actions/CheckDevice';
import { fetchingActivity } from '../../actions/Experiences';
import PageMainHeadingContent from '../../components/UI_Elements/PageMainHeadingContent/PageMainHeadingContent';
import { navigateToPage } from '../../CustomHooks/navigateToRoute';
import { Helmet } from 'react-helmet';
import { setActiveUrl } from "../../actions/NavigateUrls";

const Experiences = () => {
  const deviceType = useSelector((state) => state.checkDevice.deviceType);
  const experiences = useSelector((state) => state.experiences.items);
  const metaTitle = useSelector(state => state.experiences.metaTitle);
  const metaImage = useSelector(state => state.experiences.metaImage);
  const metaDescription = useSelector(state => state.experiences.metaDescription);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setActiveUrl('/experiences'));
  }, [])

  useEffect(() => {
    dispatch(checkDeviceType());
    dispatch(fetchingActivity());
  }, [dispatch]);


  const renderContent = () => {
    return (
      <>
        {deviceType === 'mobile' ?
          renderExperiencesMobile()
          :
          renderExperiencesWeb()
        }
      </>
    )
  }
  const renderExperiencesWeb = (props) => {
    return (
      <>
        {experiences?.sections?.map((data, i) => (
          <div className='Experiences-wrap' key={i}>
            <div data-aos="fade-up" data-aos-delay="50">
              <MainTitleContent text={data?.title} customClass="d-m-left"  theme='themeDark' gapBetween="33px" />
            </div>
            <div className="experiences-content-web">
              {data?.list.map((item, i) => {
                console.log('index values==', i);
                return (
                  <GridItem
                    key={i}
                    keyVal={i}
                    item={item}
                    imgSrc={item?.image}
                    name={item?.name}
                    description={item?.desc}
                    heightWidthWeb='261px'
                    heightWidthMob='80px'
                    onClick={() => navigateToPage(`/experiences/details/${item?.ID}`, navigate)}
                    // customStyleContent="textLimit"  
                    contentList={
                      <>
                        <p>{item?.recurrence} for {item?.duration ? item.duration : '--'}</p>
                        <p>Age: {item?.age}</p>
                        {/* <p style={{ color: "#ffffff" }}>Price: {item?.price ? item.price : '--'}</p> */}
                        <p>Swimming Level: {item?.experienced}</p>
                      </>
                    }
                  >
                    <div style={{ width: '160px' }} onClick={() => navigateToPage(`/experiences/details/${item?.ID}`, navigate)}>
                      <Button customClass="DrakHoverButton" text="Know More" buttonType="btn-outlined" textColor="dark" />
                    </div>
                  </GridItem>
                )
              }
              )}
            </div>
          </div>
        ))}
      </>
    );
  };

  const renderExperiencesMobile = () => {
    return (
      <>
        {experiences?.sections?.map((data, i) => (
          <div
            key={i}
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <Fragment>
              <div className="test1231230000" style={{ padding: '6px 0 2px 0' }}
              >
                <TitleBlue text={data?.title} customClass="text-dark" />
              </div>
              <Accordion key={i} panels={data?.list} accordionId={i}
              >
                {data?.list.map((item, index) => (
                  <div key={index} style={{ width: '160px' }}
                    onClick={() => navigateToPage(`/experiences/details/${item?.ID}`, navigate)}
                  >
                    <Button text="Know More" buttonType="btn-outlined"  textColor="dark"/>
                  </div>
                ))}
              </Accordion>
            </Fragment>
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:secure_url" content={metaImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div className='page-experiences'>
        <div className='experiences-container dynamicMinHeight container'>
          <div data-aos="fade-up"
            data-aos-delay="50">
            <PageMainHeadingContent heading="" theme='themeLight' gapBetween="40px" />
          </div>
          {renderContent()}
        </div>
      </div>
    </>

  )
}

export default Experiences;
