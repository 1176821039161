import React, {useEffect} from 'react';
import MainTitleContent from "../../components/UI_Elements/MainTitleContent/MainTitleContent";
import images from "../../assets/images/images";
import ParagraphLight from '../UI_Elements/ParagraphLight/ParagraphLight';
import ParagraphDark from '../UI_Elements/ParagraphDark/ParagraphDark';
import { checkDeviceType } from '../../actions/CheckDevice';
import { useDispatch, useSelector } from "react-redux";


const FloatingDotImage = ({contentItems}) => {
  const deviceType = useSelector((state) => state.checkDevice.deviceType);
  const dispatch = useDispatch();
  
    useEffect(() => {
      dispatch(checkDeviceType());
   }, []);

  return (
    <div className='floating-dot-image-container'>
      {contentItems?.map((item, index) =>(
      <div className='floating-dot-image-wrap' key={index}>
          <div className='floating-details-wrap'
          data-aos={deviceType == 'desktop'?"fade-up": "fade-up"} 
          data-aos-delay={100}
          >
            <MainTitleContent text={item?.step?.english?.subheading} customClass='d-m-left'
                  theme='themeDark'
                  gapBetween="16px"
                  h3={true}>
                    <ParagraphDark htmlPara={true} para={item?.step?.english?.description}/>
            </MainTitleContent>
          </div>
          <div className='floating-image-wrap'
          data-aos={deviceType == 'desktop'?"fade-up": "fade-up"} 
          data-aos-delay={100}
          >
            <img src={item?.step?.image} alt=''/>
          </div>
          {/* <div className="dotImage"> 
               <img  src={deviceType=='mobile'? images.dottedLineMob : images.dottedLineWeb} alt=''/>
             </div> */}
        </div>
      ))}
    </div>
  )
}

export default FloatingDotImage