import { SET_LOGIN_DATA } from "../actions/UserData/type";


const initialState = {
  loginData: null
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_DATA:
      return {
        ...state,
        loginData: action.payload
      };
    default:
      return state;
  }
};
export default userDataReducer;