import React, { useEffect } from 'react';
import MainTitleContent from '../MainTitleContent/MainTitleContent';
import Button from '../Button/Button';
import { useDispatch, useSelector } from "react-redux";

import { checkDeviceType } from '../../../actions/CheckDevice';

const Card = (props) => {
  const deviceType = useSelector((state) => state.checkDevice.deviceType);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkDeviceType());
  }, [dispatch, deviceType]);


  const { title, linkTo, text, imgSrc ,btnText, positionToBottomWeb, onClick, cardAnimationType,  cardAnimationDuration} = props;
  return (

    <div className={`card ${deviceType == 'mobile' ? 'mobile-card' : 'web-card '} ${positionToBottomWeb && 'contentBottom' }`}
    data-aos={deviceType== 'mobile'?"fade-up":""} data-aos-delay="100"
    >
      {imgSrc &&
       <div className='imgContainer'>
        <img className="img" alt="" src={imgSrc} />
     </div>
      }
      <div className='contentContainer'>
          <MainTitleContent 
          text={title} 
          customClass="d-m-left" 
          theme='themeLight' 
          gapBetween="16px"  
          cardAnimationType={cardAnimationType} 
          cardAnimationDuration={cardAnimationDuration}>
            <p>{text}</p>
          </MainTitleContent>
        {props.children}
        <div className="dateContainer" data-aos={cardAnimationType} data-aos-delay="100">
          <Button text={btnText} buttonType='btn-outlined' textColor='dark' onClick={onClick}></Button>
        </div>
      </div>
    </div>
  );
};

export default Card;