/* eslint-disable */
import React, { useEffect, useState } from "react";
import { fetchFishUpload } from "../../actions/fishUpload";
import { useNavigate } from "react-router-dom";
import images from "../../assets/images/images";

import Button from "../../components/UI_Elements/Button/Button";
import scanBtn from "../../assets/images/svg/btn-scan.svg";
import scanBtnWhite from "../../assets/images/svg/btn-scan-white.svg"
import { navigateToPage } from "../../CustomHooks/navigateToRoute";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../actions/UserData";
import { FishScaning, clearScan, scannedFishDetail } from "../../actions/fishUpload";
import { Link, useLocation } from "react-router-dom";

// const fileUpload = React.createRef();
// export const openCamera = () => {
//   if (fileUpload.current) {
//     dispatch(clearScan());
//     fileUpload.current.click();
//   }
// };




const FishCameraButton = (props) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isHomePage = currentPath === "/";
  const isInnerPage = !isHomePage;

  const loginUserData = useSelector((state) => state.userDataReducer.loginData);
  const fishUpload = useSelector((state) => state.fishUpload.items);

  const [loginData, setLoginData] = useState(localStorage.getItem("loginData"));
  const [tokenId, setTokenId] = useState(localStorage.getItem('accessToken'));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fileUpload = React.createRef();
  const openCamera = () => {
    if (fileUpload.current) {
      //dispatch(clearScan());
      fileUpload.current.click();
    }
  };


  const handleImage = (event) => {

    dispatch(clearScan());

    console.log('handleImage called===');
    console.log(event.target.files[0]);
    const val = event.target.files[0];
    dispatch(fetchFishUpload(val));
    navigateToPage("/loading", navigate)

  };

  useEffect(() => {
    console.log('called==fishUpload=');
    if (fishUpload) {
      console.log('called==fishUpload11=');

      dispatch(FishScaning(fishUpload));
      console.log("dfghjk", fishUpload)

    }
  }, [dispatch, fishUpload]);

  useEffect(() => {
    console.log('Fish Camera Button called=== ');
    const handleStorageChange = () => {
      setLoginData(localStorage.getItem("loginData"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);


  return (
    <div>
      <input
        type="file"
        accept="image"
        style={{ display: "none" }}
        ref={fileUpload}
        id="fish-btn"
        onChange={handleImage}
        capture="camera"
      />
      {
        loginData
          // || tokenId 
          ? (
            // <button className="fishscan-button" style={style} onClick={openCamera} >
            //   {props.name ? props.name : "Scan fish"}{" "}
            //   <img className="scan" src={require("../../public/images/ic_scan_fish.svg")} alt="" />
            // </button>
            <>
              {props.btnType == 'nav' &&
                <div className="mb-footer-logo" onClick={openCamera}>
                  <span className="footer-icon" style={{ width: "unset" }}>
                    <img src={images.scan_fish} alt="scanfish" className="scanFish-img" />
                  </span>
                  <span className="footerIconText">Scan Fish</span>
                </div>
              }
              {props.btnType == 'button' &&
                <Button text={props.btnText} buttonType={props.btnStyle} textColor={props.textColor} onClick={openCamera} />

              }
              {props.btnType == 'picture' &&
                <div className="menu-btn  mb-footer-logo" onClick={openCamera}>
                  <img src={isInnerPage || (isHomePage && props.backgroundStatus) ? scanBtn : scanBtnWhite } alt="" className="scan-btn" id="scanBtn 123" />
                </div>

              }
            </>
          )
          :
          (
            <>
              {props.btnType == 'nav' &&

                <div className="mb-footer-logo" onClick={() => navigateToPage('/social-login', navigate)} >
                  <span className="footer-icon" style={{ width: "unset" }}>
                    <img src={images.scan_fish} alt="scanfish" className="scanFish-img" />
                  </span>
                  <span className="footerIconText">Scan Fish</span>
                </div>
              }
              {props.btnType == 'button' &&
                <Button text="Scan Fish" buttonType={props.btnStyle} textColor={props.textColor} onClick={() => navigateToPage('/social-login', navigate)} />
              }
              {props.btnType == 'picture' &&
                <div className=" menu-btn  mb-footer-logo menu-btn" onClick={() => navigateToPage('/social-login', navigate)} >
                  <img src={isInnerPage || (isHomePage && props.backgroundStatus) ? scanBtn : scanBtnWhite} alt="" className={isHomePage? "scan-btn-white" : "scan-btn" } id="scanBtn" />
                </div>
              }

            </>
          )

      }

    </div>
  );
};
export default FishCameraButton;
