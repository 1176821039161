import { FETCH_ALL_FISHES } from "./type";
import { axiosCustom } from "../../utils/request";

export const fetchingAllFishes = () => async dispatch => {
   const response = await axiosCustom.get("pages/5580");
   const { data = false, status } = response;
   if (status === 200) {
      if (data) {
         dispatch({
            type: FETCH_ALL_FISHES,
            payload: data.acf,
            metaTitle: data?.page_meta?.meta_title,
            metaImage:data?.page_meta?.meta_image,
            metaDescription: data?.page_meta?.meta_description
         });
         
      }
   }
};