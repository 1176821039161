import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import images from '../../assets/images/images';
import ImageBannerWithContent from '../../components/UI_Elements/ImageBannerWithContent/ImageBannerWithContent';
import CategoryList from "../../components/CategoryList/CategoryList";
import ImageBanner from '../../components/UI_Elements/ImageBanner/ImageBanner';
import MainTitleContent from "../../components/UI_Elements/MainTitleContent/MainTitleContent";
import { checkDeviceType } from '../../actions/CheckDevice';
import { fetchingAnimals } from '../../actions/AllAnimals';
import { Helmet } from 'react-helmet';
import { setActiveUrl } from "../../actions/NavigateUrls";
import ParagraphDark from '../../components/UI_Elements/ParagraphDark/ParagraphDark';


const AllAnimals = () => {
    const deviceType = useSelector((state) => state.checkDevice.deviceType);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(setActiveUrl('/all-animals'));
    }, [])

    useEffect(() => {
        dispatch(checkDeviceType());
    }, [deviceType]);

    const items = useSelector(state => state.allanimals.animalListResponseData);
    const metaTitle = useSelector(state => state.allanimals.metaTitle);
    const metaImage = useSelector(state => state.allanimals.metaImage);
    const metaDescription = useSelector(state => state.allanimals.metaDescription);

    useEffect(() => {
        dispatch(fetchingAnimals());
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <meta property="og:title" content={metaTitle} />
                <meta property="og:image" content={metaImage} />
                <meta property="og:image:secure_url" content={metaImage} />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="400" />
                <meta property="og:image:height" content="300" />
                <meta property="og:image:alt" content="" />
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
            </Helmet>
            <div className='animals-container dynamicMinHeight'>
                <div className='animals-banner'>
                    {
                        deviceType == 'desktop' ?
                            <ImageBannerWithContent
                                customClass="dolphinBay"
                                bannerImage={images.all_animals_bg}
                                heading={items?.section_banner?.title}
                                subHeading="With over 65,000 marine animals, you're sure to be mesmerised."
                                imgAnimationType="zoom-out"
                                imgAnimationDuration={100}
                                descAnimationType={deviceType == 'desktop' ? "fade-up" : "fade-up"}
                                descAnimationDuration={100}
                                titleAnimationType={deviceType == 'desktop' ? "fade-up" : "fade-up"}
                                titleAnimationDuration={100}
                                textColor={'light'}
                            ></ImageBannerWithContent>
                            :
                            <div style={{ position: "relative" }}>
                                <ImageBanner
                                    customClass="noContentBanner"
                                    customStyle={{ height: '452px', with: '100%', marginBottom: '0px' }}
                                    bannerImage={images.all_animals_bg}
                                    animationType={"fade-up"}
                                    animationDuration={100}
                                />
                                <div>
                                    <MainTitleContent
                                        text={items?.section_banner?.title}
                                        customClass="d-m-center"
                                        theme='themeWhitet'
                                        gapBetween="16px"
                                        cardAnimationType={"fade-up"}
                                        cardAnimationDuration={100}
                                    >
                                        <ParagraphDark para={"With over 65,000 marine animals, you're sure to be mesmerised"} htmlPara={true} textColor={'light'}/>

                                    </MainTitleContent>
                                </div>
                            </div>
                    }
                </div>
                <div className='categoryItems'>
                    {
                        deviceType == 'mobile' ?
                            <MainTitleContent text={"Select a category"} customClass="d-m-center" theme="themeDark" gapBetween="16px">
                            </MainTitleContent> : <div style={{ padding: '10px' }}></div>
                    }
                    <CategoryList items={items} />
                </div>
            </div>
        </>
    )
}

export default AllAnimals;
