import { FETCH_TC } from "./type";
import { axiosCustom } from "../../utils/request";

export const fetchingTC = () => async (dispatch) => {
    const response = await axiosCustom.get("pages/5238");
    const { data = false, status } = response;
    if (status === 200) {
        if (data) {
            dispatch({
                type: FETCH_TC,
                items: data.content,
                metaTitle: data?.page_meta?.meta_title,
                metaImage:data?.page_meta?.meta_image,
                metaDescription: data?.page_meta?.meta_description
            });
        }
    }
};

