import React, { useEffect, useState } from 'react';
import Button from '../../UI_Elements/Button/Button';
import CardCarousel from "../../Carousels/CardCarousel/CardCarousel";
import MainTitleContent from "../../UI_Elements/MainTitleContent/MainTitleContent";
import { useNavigate } from "react-router-dom";
import FishCameraButton from "../../FishCameraButton/FishCameraButton";
import { navigateToPage } from "../../../CustomHooks/navigateToRoute";
import BottomNavbar from "../../BottomNavbar/BottomNavbar";

const ScanResultFaliure = (props) => {
    const navigate = useNavigate();

    const handleImageClick = (id) => {
        console.log(`/scan-fish/${id}`);
        navigateToPage(`/scan-fish/${id}`, navigate)
    };

    console.log('props.fishList ==', props.fishList);

    return (
        <div className='modalContainer'
           // style={{ maxHeight: window.innerHeight - 300 }}

        >
            <div className='ScanResultFaliureContainer'>
                <div className='fishContainer'>
                    <img src={require(`../../../assets/images/icon-fish.png`)} alt="" />
                </div>
                <div className='actionContainer'>
                    <p className='message'>Oops! We’re unable to recognise this fish.</p>
                    <p className='instruction'>Please try retaking the picture.</p>
                    {/* <Button text='Try Again' buttonType='btn-outlined' textColor='dark'></Button> */}
                    <div onClick={props.onClose}>
                        <FishCameraButton btnType="button"  btnText="Try Again" btnStyle="btn-outlined"  textColor="dark"/>
                    </div>

                    <Button text='View Fish Library' buttonType='btn-backgrounded' onClick={() =>
                        navigateToPage('/fish-library', navigate)
                    }></Button>
                </div>
            </div>
            <div className='sliderContainer'>
                <MainTitleContent text={"Other fish nearby"} h6={true} customClass="d-m-center" theme='themeLight' gapBetween="15px">

                </MainTitleContent>
                <CardCarousel
                    isActiveClick={true}
                    //handleImageClick={handleImageClick}
                    //handleClick={handleClick} 
                    contentClickRoute={'/scan-fish/'}
                    slideData={props?.fishList?.related_fishes}
                    customClass={'slideSize80 roundSlide'}
                    slidesToShow={3.2}
                    responsiveSettings={[
                        { breakpoint: 500, settings: { slidesToShow: 3.2 } },
                        { breakpoint: 900, settings: { slidesToShow: 3.2 } },
                        { breakpoint: 1500, settings: { slidesToShow: 3.2 } },
                        { breakpoint: 1800, settings: { slidesToShow: 3.2 } },
                        { breakpoint: 2500, settings: { slidesToShow: 3.2 } },
                    ]}
                />

            </div>

        </div>
    );
};

export default ScanResultFaliure;