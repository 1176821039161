import React, { useEffect } from "react";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import Button from "../../components/UI_Elements/Button/Button";
import PageMainHeadingContent from "../../components/UI_Elements/PageMainHeadingContent/PageMainHeadingContent";
import BlueLinkTitle from "../../components/UI_Elements/BlueLinkTitle/BlueLinkTitle";
import images from "../../assets/images/images";
import ParagraphLight from "../../components/UI_Elements/ParagraphLight/ParagraphLight";
import MainTitleContent from "../../components/UI_Elements/MainTitleContent/MainTitleContent";
import ParagraphDark from "../../components/UI_Elements/ParagraphDark/ParagraphDark";
import TitleBlue from "../../components/UI_Elements/TitleBlue/TitleBlue";
import BlueBannerContent from "../../components/BlueBannerWithContent/BlueBannerContent";
import MapwithStarfish from "../../components/MapwithStarFish/MapwithStarfish";
import { fetchingHome } from "../../actions/Home";
import { useSelector, useDispatch } from "react-redux";
import ActivitySlider from "../../components/ActivitySlider/ActivitySlider";
import { useNavigate } from "react-router-dom";
import ImageBannerWithGradient from '../../components/UI_Elements/ImageBannerWithGradient/ImageBannerWithGradient';
import { checkDeviceType } from '../../actions/CheckDevice';
import { navigateToPage } from "../../CustomHooks/navigateToRoute";
import FullWidthCarousel from "../../components/Carousels/FullWidthCarousel/FullWidthCarousel";
import { setActiveUrl } from "../../actions/NavigateUrls";
import { Helmet } from 'react-helmet';
import FishCameraButton from "../../components/FishCameraButton/FishCameraButton";


const Home = () => {
  const deviceType = useSelector((state) => state.checkDevice.deviceType);
  const activePage = useSelector((state) => state.navigateUrlsReducer.activeUrl);

  const navigate = useNavigate()
  const items = useSelector(state => state.home.items);
  const metaTitle = useSelector(state => state.home.metaTitle);
  const metaImage = useSelector(state => state.home.metaImage);
  const metaDescription = useSelector(state => state.home.metaDescription);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveUrl('/'));
  }, [])

  useEffect(() => {
    dispatch(checkDeviceType());
    dispatch(fetchingHome());
  }, [dispatch]);




  const handleNavigation = (url) => {
    if (!(url.includes('http://') || url.includes('https://'))) {
      dispatch(setActiveUrl(url));
    }
    navigateToPage(url, navigate);
  };



  return (
    <>
      <Helmet>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:secure_url" content={metaImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className="home-page-container dynamicMinHeight">
        <HomeBanner items={items} onButtonClick={() => handleNavigation("/experiences")}
          // data-aos="fade-up"
          // data-aos-delay={100}
        />

        
        <div className="scan-fish-camera">
          <div className="container">
            <div className="scan-fish-section"
             data-aos="fade-up" 
             data-aos-delay={100}
            >
              <div className="camera-logos" >
                <div className="img-text">
                  <img src={images.camera_focus} alt="" />
                  <ParagraphDark para='Focus on the fish.' htmlPara={true} textColor={'light'}/>
                </div>
                <div className="img-text">
                  <img src={images.zap_flash_off} alt="" />
                  <ParagraphDark para='Turn off your camera flash.' htmlPara={true} textColor={'light'}/>
                </div>
              </div>
              <img src={images.scan_fish_camera} alt="" className="scan-fish-img"  data-aos="fade-up" data-aos-delay={100} />
              <div>
              <ParagraphDark htmlPara={true} para={'Discover Fish with AI'} textColor={'light'} customClass="d-m-center" />
              <ParagraphDark htmlPara={true} para='Simply press “Scan Fish” to learn more.' textColor={'light'}/>
              </div>
              <div>
                <FishCameraButton btnType="button" btnText="Scan Fish" btnStyle="btn-white-outlined" />
              </div>

              {/* <Button text='Scan Fish' textColor="dark" buttonType='btn-backgrounded' onClick={() => handleNavigation("/social-login")}></Button> */}
            </div>
          </div>
        </div>
        <div className="home-lost-chamber">

          <ImageBannerWithGradient
            // Please pass 'noBgImage' as custom class to remove the backgrounf image and gradient.
            customClass="withGradient "
            customStyle={
              deviceType == 'mobile' ?
                {
                  backgroundImage:
                  `linear-gradient(rgba(10, 124, 138,0.1) 10%, rgba(10, 124, 138,0.3) 50%, rgba(10, 124, 138,0.5) 90%,rgba(10, 124, 138,0.7) 100%), url("/static/media/seven-sages-chamber-bg.be8e072077a82e675f42.png")`,

                    // `linear-gradient(rgb(, 10, 29) 0%, transparent 20%, transparent 50%, rgb(2, 10, 29) 100%), url(${images.seven_sages_chamber_bg})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  position: 'relative',
                  //backgroundAttachment: 'fixed',
                  paddingBottom:'20px'
                } :
                {

                  backgroundImage:`linear-gradient(rgba(10, 124, 138,0.1) 10%, rgba(10, 124, 138,0.3) 50%, rgba(10, 124, 138,0.5) 90%,rgba(10, 124, 138,0.7) 100%),url(${images.seven_sages_chamber_bg})`,
                  height: '100%',
                  width: '100% ',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  position: 'relative',
                  backgroundAttachment: 'fixed',
                }
            }
            showDownloadLink={true}
            showMap={true}
            showButton={true}
            downloadBtnText='Download the map'
            bottomBtnText='Explore all Chambers'
            onclickbottomBtn={() => handleNavigation('/chambers')}
          >
            <div className='headings' 
            data-aos={deviceType == 'desktop'?"fade-up": "fade-up"} 
            data-aos-delay={100}>
              <MainTitleContent
                text={items?.section_map?.title}
                customClass="d-left-m-center compactHeading"
                theme="themeWhite"
                gapBetween="16px"
              >
                <ParagraphDark para={items?.section_map?.description} htmlPara={true} textColor={'light'} />
              </MainTitleContent>
            </div>
          </ImageBannerWithGradient>

        </div>
        <section className="protect-marine-species bg-white">
          <div className="container">
            <div className="protect-marine">
              <div className="left-content"  
              >
                <MainTitleContent
                  text={items?.section_protect_marine?.title}
                  customClass="d-left-m-center"
                  theme='themeLight'
                  gapBetween="26px"
                  cardAnimationType={deviceType=='desktop'?"fade-up": "fade-up"}
                  cardAnimationDuration="200"
                />
                <div className="marine-left-text"
                data-aos="fade-up"
                data-aos-delay="200"
                >
                  <div className="images-logo">
                    <img src={items?.section_protect_marine?.card?.image} alt=""
                     />
                    <img src={items?.section_protect_marine?.card?.image_2} alt=""
                    />
                  </div>
                  
                  <ParagraphLight
                    para={items?.section_protect_marine?.card?.description} 
                    htmlPara={true}
                    textColor={"light"}
                     />
                  <div className="protect-marine-btn-wrap"
                  >
                    <Button text="Know More" textColor="dark" buttonType='btn-outlined'
                      onClick={() => handleNavigation('/care-and-conservation')}></Button>
                  </div>
                  
                </div>
              </div>
              <div className="right-image">
                <img src={images.stingGrey} className="img-large-species" alt=""
                  data-aos="fade-up"
                  data-aos-delay="1050"
                />
                <img src={images.marineSpeciesSmall} className="img-small-species" alt=""
                  data-aos="fade-up"
                  data-aos-delay="1000"
                />
                {/* <img src={images.marine_species} alt="" className="marine-speies-img" />
              <img src={images.marine_speices_mb} alt="" className="marine-speies-img-mb" /> */}
              </div>
            </div>
          </div>
        </section>
        <section className="lost-chamber-section-2">
          <div className="container">
            <div className="lost-chambers">
              <div className="left-content">
                <MainTitleContent text={items?.section_story?.title} 
                customClass="d-left-m-center" 
                theme='themeWhite' 
                gapBetween="16px"
                cardAnimationType={deviceType=='desktop'?"fade-up": "fade-up"}
                cardAnimationDuration="100"
                >
                  <ParagraphDark para={items?.section_story?.description} htmlPara={true} textColor={'light'} />
                </MainTitleContent>
                <div style={{ width: "190px" }} className="explore-btn"
                
                data-aos={deviceType=='desktop'?"fade-up": "fade-up"}
                data-aos-delay={100}
                >
                  <Button
                    text="Explore all Chambers"
                    buttonType="btn-white-outlined"
                    textColor="light"
                    onClick={() => handleNavigation("/chambers")}
                  >
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-overlay"></div>
          <div className="mobile-bottom-bg-wrap"></div>
        </section>
        <div className="home-activity2">
          <div className="container">
            <ActivitySlider
              activeClick={true}
              // passing attraction except lost chambers data i sliderData
              slideData={items?.section_attractions?.attraction_list.filter(attraction => attraction.ID !== 4960)}
              title={items?.section_attractions?.title}
              Paragraph={items?.section_attractions?.description}
              contentClickRoute={'/attractions/detail/'}
              slidesToShow={1.2}
              customClassForCardName="d-m-center"
              showButton={false}
              customClassForTitle="d-m-center"
              responsiveSettings={[
                { breakpoint: 500, settings: { slidesToShow: 1.2 } },
                { breakpoint: 900, settings: { slidesToShow: 2.2 } },
                { breakpoint: 1500, settings: { slidesToShow: 2.2 } },
                { breakpoint: 1800, settings: { slidesToShow: 2.2 } },
                { breakpoint: 2500, settings: { slidesToShow: 3.2 } },
              ]}
            />
          </div>
        </div>
        <div className="container fun-facts-conatiner">
          <FullWidthCarousel items={items?.section_cool_fact}  />
        </div>
      </div>
    </>

  );
};

export default Home;

