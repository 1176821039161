import React from 'react'

const PageHeading = (props) => {
  const { gapBetween,theme,mobileTextAling, heading,children, cardAnimationType,  cardAnimationDuration } = props;

    const positionTo = {
        textAlign: `${mobileTextAling}`,
      }
      const headingGap = {
        marginBottom: `${gapBetween}`,
      }

  return (
    <div className={`PageHeadingContent-wrap ${theme} `}>
      {heading && 
      <h3 className="heading" style={headingGap} data-aos={cardAnimationType} data-aos-delay={cardAnimationDuration ? cardAnimationDuration :'0'}>
        {heading}
        </h3>}
      <div data-aos={cardAnimationType} data-aos-delay={cardAnimationDuration ? parseInt(cardAnimationDuration) + 100 : '0'}>
        {children}
      </div>
    </div>

  )
}

export default PageHeading