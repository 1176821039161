import { useEffect, useState } from 'react';

export const useCurrentYear = () => {
  const [currentYear, setCurrentYear] = useState(null);

  useEffect(() => {
    const getCurrentYear = () => {
      const date = new Date();
      const year = date.getFullYear();
      setCurrentYear(year);
    };

    getCurrentYear();
  }, []);

  return currentYear;
};

