import axios from "axios";
import { Environment } from "../env";

const Stagging = Environment.url;

const customStagging = Environment.api_url + "/index.php/wp-json/wp/v2/";

const fishApiBaseUrl = Environment.api_url + "/index.php/wp-json/fishes/v2/";


export const axiosCustom = axios.create({
  baseURL: customStagging,
  headers: {
    "Content-Type": "application/json",
  },
});


export const axiosFishApiBaseUrl = axios.create({
  baseURL: fishApiBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});


export const axiosPythonApi = axios.create({
  baseURL: Stagging,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default axios.create({
  baseURL: Stagging,
  headers: {
    "Content-Type": "application/json",
  },
});
