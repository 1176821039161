import React from 'react'

const MainTitle = (props) => {
  const { gapBetween,theme, customClass,text, h1,h2,h3,h4,h5,h6,children, cardAnimationType,  cardAnimationDuration } = props;
  
  const headingGap = {
    marginBottom: `${gapBetween}`,
  }
  return (
    <div className={`MainTitleContent-wrap ${theme} ${customClass}`} >
      <div data-aos={cardAnimationType} data-aos-delay={cardAnimationDuration ? cardAnimationDuration :'0'}>
        {h1 ?
        <h1 className="heading" style={headingGap}>{text}</h1>
        :
        h2 ?
        <h2 className="heading" style={headingGap}>{text}</h2>
        :
        h3 ?
        <h3 className="heading" style={headingGap}>{text}</h3>
        :
        h4 ?
        <h4 className="heading" style={headingGap}>{text}</h4>
        :
        h5 ?
        <h5 className="heading" style={headingGap}>{text}</h5>
        :
        h6 ?
          <h6 className="heading" style={headingGap}>{text}</h6>
        :
        text &&
        <h2 className="heading" style={headingGap}>{text}</h2>
        }
      </div>
      <div data-aos={cardAnimationType} data-aos-delay={cardAnimationDuration ? parseInt(cardAnimationDuration) + 100 : '0'}>
        {children}
      </div>
    </div>
  )
}

export default MainTitle