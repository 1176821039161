import React, { useState, useEffect, useRef } from 'react';
import images from '../../assets/images/images';
import { useDispatch, useSelector } from "react-redux";
import { checkDeviceType } from '../../actions/CheckDevice';

const FishDetectedCount = ({speciesCount, animationType, animationDelay}) => {
    const deviceType = useSelector((state) => state.checkDevice.deviceType);
    const _fishuUpload = useSelector((state) => state.fishUpload.items);
  
    const dispatch = useDispatch();
   
    useEffect(() => {
      dispatch(checkDeviceType());
    }, [dispatch, deviceType]);



  return (
    <div className='fishDetectedCount-section' data-aos={animationType}  data-aos-delay={animationDelay}> 
          <img src={images.fish_icon} alt="" />
          <span className="text">Fish Discovered: {speciesCount}</span>
    </div>
  )
}

export default FishDetectedCount


