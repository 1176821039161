import { FETCH_FISH, SCAN_RESULT, FISH_DETAIL_FETCHED, Fetch_FISH_INFORMATION } from '../actions/fishUpload/type';

const initialState = {
    items: null,
    scanResult: null,
    fishDetails:null,
    fishInfo:null,
    metaImage:"",
metaTitle:"",
    metaDescription:""
};

const fishUpload = (state = initialState, action) => {
    console.log('action.payload, == ', action);
    switch (action.type) {
        // case FETCH_FISH:
        case "FETCH_FISH":
            return {
                ...state,
                ...action,
            };
        case "SCAN_RESULT":
            return {
                ...state,
                ...action,
            };
        case "CLEAR_SCAN":
            return {
                ...state,
                ...action,
            };
        case "FISH_DETAIL_FETCHED" :
            return {
                ...state,
                ...action,
            };
        case "Fetch_FISH_INFORMATION":
            return {
                ...state,
                ...action,
            };
        default:
            return state;
    }
};

export default fishUpload;