
// import React, { useState } from 'react';
// import icons from '../../assets/icons/icons';

// const AccordionTitleContent = ({ title, content }) => {
//   const [isActive, setIsActive] = useState(false);

//   return (
//     <div className={`accordionTitleContent-item ${isActive ? 'active' : ''}`}>
//       <div className="accordion-item-title" onClick={() => setIsActive(!isActive)}>
//         <h5>{title}</h5>
//         <div className="accordionTitlecontent-icon-wrap">
//           <img src={isActive ? `${icons.icon_acc_minus}` : `${icons.icon_acc_plus}`} />
//         </div>
//       </div>
//       <div className={`accordionTitlecontent-content ${isActive ? 'active' : ''}`}>
//         <p>{content}</p>
//       </div>
//     </div>
//   );
// };

// export default AccordionTitleContent;


import React, { useState, useRef } from 'react';
import icons from '../../assets/icons/icons';

const AccordionTitleContent = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);
  const [height, setHeight] = useState(0);

  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsActive(!isActive);
    if (contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    }
  };

  const innerStyle = {
    height: `${isActive ? height : 0}px`,
  };

  
  return (
    <div className={`accordionTitleContent-item ${isActive ? 'active' : ''}`}>
      <div className="accordion-item-title" onClick={toggleAccordion}>
        <h5>{title}</h5>
        <div className="accordionTitlecontent-icon-wrap">
          {/* <img src={isActive ? `${icons.icon_acc_minus}` : `${icons.icon_acc_plus}`} /> */}
          <div className={`circle-plus ${!isActive ? "closed" :"opened"} `}>
            <div className="circle">
              <div className="horizontal"></div>
              <div className="vertical"></div>
            </div>
          </div>
        </div>
      </div>
      <div ref={contentRef} style={innerStyle} className={`accordionTitlecontent-content ${isActive ? 'active' : ''}`}>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default AccordionTitleContent;

