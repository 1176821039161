import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import YellowIconWithTitle from '../../YellowIconWithTitle/YellowIconWithTitle';

const NumberCardCarousel = ({items}) => {
   
    var settings = {
        dots: false,
        infinite: true,
        prevArrow: <></>,
        nextArrow: <></>,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1
    };
    const animalCategories = Object.values(items?.section_banner?.animal_categories || {});

    return (
        <>
            <div className='number-card-slider' style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                    <Slider {...settings}>
                            {animalCategories.map((item, index) => (
                                    <div key={index} data-aos="fade-up" data-aos-delay={`${index}00`} >
                                      <YellowIconWithTitle numberCount={item.count} title={item.name} textColor={'light'} />
                                    </div>
                                ))
                            }
                    </Slider>
                </div>
            </div>
        </>

    )
};

export default NumberCardCarousel;
