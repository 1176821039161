import React from "react";
import MainTitleContent from "../../components/UI_Elements/MainTitleContent/MainTitleContent";


const ConservationStatus = (props) => {
    const { items, showParagraph, theme, heading, children, showCurrentStatus, layoutClass, currentStatusVal } = props;
    const filteredItems = items.filter(item => item.type === currentStatusVal);

    return (
        <div className="conservation-section">
            <MainTitleContent 
            text={heading} 
            theme={theme} 
            gapBetween="16px" 
            customClass="d-left-m-center"
            cardAnimationType="fade-up"
            cardAnimationDuration={100}
            >
                {showParagraph && <>{children}</>}
            </MainTitleContent>

            {showCurrentStatus && filteredItems.length > 0 && (
                <div className="conservation-status active">
                    {filteredItems.map((icon, index) => (
                        <div key={icon.id} className="active-icons" data-aos="fade-up" data-aos-delay={`${index}50`}>
                            <img src={require(`../../assets/images/conservation-icons/${icon.image}`)} alt="" />
                            <span
                                className={`gridItem ${theme === 'themeLight' ? 'icons-title-dark' : 'icons-title-light'}`}
                            >
                                {icon.title}
                            </span>
                        </div>
                    ))}
                </div>
            )}

            <div className={`conservation-status ${props.layoutClass}`}>
                {items?.map((item, index) => {
                    return (
                        <div key={item.id} className='icons-list' data-aos="fade-up" data-aos-delay={`${index}50`}>
                            <img src={require(`../../assets/images/conservation-icons/${item.image}`)} alt="" />
                            <span
                                className={`gridItem ${theme == 'themeLight' ? 'icons-title-dark' : 'icons-title-light'}`}
                            >{item?.title}</span>
                        </div>
                    );
                })
                }
            </div>
        </div>
    )
}

export default ConservationStatus