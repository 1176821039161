import { SET_ACTIVE_URL } from "../actions/NavigateUrls/type";

const initialState = {
  activeUrl: "/",
};

const navigateUrlsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_URL:
      return {
        ...state,
        activeUrl: action.payload,
      };
    default:
      return state;
  }
};

export default navigateUrlsReducer;
