import React, { useState, useEffect } from 'react';
import images from '../../assets/images/images';
import PlayVideo from '../PlayVideo/PlayVideo';
import BottomNavbar from '../BottomNavbar/BottomNavbar';
import closeBtn from "../../assets/images/svg/close-btn.svg";
import videos from '../../assets/videos/videos';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import dottedLine from '../../assets/images/line-dotted.png'
import { useSelector, useDispatch } from "react-redux";
import { setActiveUrl } from "../../actions/NavigateUrls";
import { navigateToPage } from "../../CustomHooks/navigateToRoute";

const HamburgerContent = ({ setMobileMenuVisible }) => {
    const activePage = useSelector((state) => state.navigateUrlsReducer.activeUrl);
  
    const dispatch = useDispatch();
    const navigate = useNavigate()

    // useEffect(() => {
    //     dispatch(setActiveUrl(activePage));
    //   }, [dispatch,activePage])


    const handleLinkClick = (url) => {
        if (!(url.includes('http://') || url.includes('https://'))) {
            dispatch(setActiveUrl(url));
          }
        navigateToPage(url, navigate);

        setMobileMenuVisible(false);
    };
    return (
        <>

            <div className="hamburger-video">
                <PlayVideo src={videos.atlantis_menu_video} />
            </div>

            <div className='hamburger-wrapper'>
                <div className='hamburger-menu-wrapper'>
                    <div className='dotted-bg'>
                        <img src={dottedLine} alt="" />
                    </div>
                    <div className='menu-image-container'>
                        <ul>
                            <li>
                                <div className="title" onClick={()=>handleLinkClick('/attractions')} >
                                    Our Attractions
                                </div>
                            </li>
                            <li>
                                <div className="menu-item"  onClick={()=>handleLinkClick('/experiences/details/5139')}>
                                    Dolphin Bay
                                </div>
                            </li>
                            <li>
                                <div className="menu-item"  onClick={()=>handleLinkClick('/experiences/details/5140')}>
                                    Sea Lion Point
                                </div>
                            </li>
                            <li>
                                <div className="menu-item"  onClick={()=>handleLinkClick('/attractions/detail/5005')}>
                                    The Shark Lagoon
                                </div>
                            </li>

                        </ul>
                        <div className='image'>
                            <img src={images.location_logo} alt="" />
                        </div>
                    </div>
                    <div className='menu-image-container'>
                        <ul>
                            <li>
                                <div className="title"  onClick={()=>handleLinkClick('/experiences')}>
                                    Experiences
                                </div>
                            </li>
                            <li>
                                <div className="menu-item"  onClick={()=>handleLinkClick('/experiences/details/5133')}>
                                    Aquarium Experience
                                </div>
                            </li>
                            <li>
                                <div className="menu-item"  onClick={()=>handleLinkClick('/experiences/details/5135')}>
                                    Dolphin Experience
                                </div>
                            </li>
                            <li>
                                <div className="menu-item"  onClick={()=>handleLinkClick('/experiences/details/5056')}>
                                    Fish Tales Tour
                                </div>
                            </li>
                            <li>
                                <div className="menu-item"  onClick={()=>handleLinkClick('/experiences')} style={{ textDecoration: "underline" }}>
                                    View All
                                </div>
                            </li>

                        </ul>
                        <div className='image'>
                            <img src={images.aquventure_logo} alt="" />
                        </div>
                    </div>
                    <div className='menu-image-container'>

                        <ul>
                            <li>
                                <div className="title"  onClick={()=>handleLinkClick('/discover')}>
                                    Discover
                                </div>
                            </li>
                            <li>
                                <div className="menu-item"  onClick={()=>handleLinkClick('/chambers')}>
                                    Chambers
                                </div>
                            </li>
                            <li>
                                <div className="menu-item"  onClick={()=>handleLinkClick('/all-animals')}>
                                    Animals
                                </div>
                            </li>


                        </ul>
                        <div className='image'>
                            <img src={images.discover_logo} alt="" />
                        </div>
                    </div>
                    <div className='menu-image-container'>
                        <ul>
                            <li>
                                <div className="title"  onClick={()=>handleLinkClick('/care-and-conservation')}>
                                    Care and Conservation
                                </div>
                            </li>

                        </ul>
                        <div className='image'>
                            <img src={images.care_logo} alt="" />
                        </div>
                    </div>
                    <div className='menu-image-container'>
                        <ul>
                            <li>
                                <div className="title" onClick={()=>handleLinkClick('/faq')}>
                                    FAQ
                                </div>
                            </li>
                        </ul>

                        <div className='image'>
                            <img src={images.faq_logo} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            {/* <BottomNavbar /> */}

        </>
    );
};

export default HamburgerContent;
