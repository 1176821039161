import React from 'react'
import Button from '../UI_Elements/Button/Button';
import MainTitleContent from '../UI_Elements/MainTitleContent/MainTitleContent';
import PageMainHeadingContent from '../UI_Elements/PageMainHeadingContent/PageMainHeadingContent';
import TitleBlue from '../UI_Elements/TitleBlue/TitleBlue';
import ParagraphLight from '../UI_Elements/ParagraphLight/ParagraphLight';
import ParagraphDark from '../UI_Elements/ParagraphDark/ParagraphDark';
import ImageBannerWithContent from '../UI_Elements/ImageBannerWithContent/ImageBannerWithContent';
import Card from '../../components/UI_Elements/Card/Card';
import CardCarousel from '../Carousels/CardCarousel/CardCarousel';
import BlueLinkTitle from '../UI_Elements/BlueLinkTitle/BlueLinkTitle';
import BlueBannerContent from '../BlueBannerWithContent/BlueBannerContent';
import GridItem from '../UI_Elements/GridItem/GridItem';
import images from '../../assets/images/images';
import MapwithStarfish from '../MapwithStarFish/MapwithStarfish';
import ConservationStatus from '../ConservationStatus/ConservationStatus';
import ExpertsList from '../UI_Elements/ExpertsList/ExpertsList';
import YellowIconWithTitle from '../YellowIconWithTitle/YellowIconWithTitle';
import GridItemIcon from '../GridItemIcon/GridItemIcon';
import BlueIconwithText from '../BlueIconwithText/BlueIconwithText';
import { expertsEntries, ConservationIcons } from "../../utils/dummy";
import ActivitySlider from '../ActivitySlider/ActivitySlider';
import CardIconText from '../CardIconText/CardIconText';
import {ImageIconDummy, packageData} from "../../utils/dummy";

const Demonstration = () => {
    const shareUrl = 'https://www.example.com/post';

    const handleShare = () => {
        // Replace "your-content-here" with the actual content you want to share
        const contentToShare = encodeURIComponent('your-content-here');
        const instagramAppUrl = `instagram://library?AssetPath=${contentToShare}`;

        window.location.href = instagramAppUrl;
    };

    const handleImageClick = (data) => {
        console.log('Clicked:', data);
      };

    return (
        <div className='Demo-container container'>
            {/* ** --  Button design: yellow bg, transaprent bg with outlined  */}
            <Button text='Visit Website' buttonType='btn-backgrounded'></Button>
            <Button text='Visit Website' buttonType='btn-outlined'></Button>
            {/* --  ** */}

            {/* ** --  Main heading with description, heading font size for Mobile: 23.04px  Web: 33.18px */}
            <MainTitleContent text='Where to go next?' customClass=''
                theme='themeDark'
                gapBetween="6px"
                h1=""
                h2=""
                h3=""
                h4=""
                h5=""
                h6="">
                <ParagraphLight htmlPara={true} para="The place takes its name from the mythical lost city of Atlantis.
                    It tells the story of this city as well as introduces you to over 65,000 animals living in the waters."/>
            </MainTitleContent>


            <div style={{ backgroundColor: '#fff' }}>
                <MainTitleContent text='Where to go next?' customClass="d-m-center" theme='themeLight' gapBetween="16px">
                    <ParagraphLight htmlPara={true} para="The place takes its name from the mythical lost city of Atlantis.
                    It tells the story of this city as well as introduces you to over 65,000 animals living in the waters."/>
                </MainTitleContent>
            </div>
            {/* --  ** */}

            {/* ** -- Blue Text Heading */}
            <TitleBlue text='Sea Lion Dip and Play'></TitleBlue>
            {/* --  ** */}

            {/*   ** --  Paragraph with dark background  */}
            <ParagraphDark para='An amazing and immersive experience, as you snorkel around 65,000 marine animals in the mesmerising Ambassador Lagoon. For swimmers aged 6 and above' />
            {/*   ** --  Paragraph with light background  */}
            <ParagraphLight para='Find the best package for yourself and get discounts when you book online.' />
            {/* --  ** */}


            {/* --  ** */}
            <BlueLinkTitle text={'Download the map'} customClass="d-m-center"></BlueLinkTitle>
            {/* --  ** */}

            {/*   ** --  Blue text alingment ; text alignment custom classes */}
            <TitleBlue text={'Sea Lion Dip and Play'} customClass="d-m-left" ></TitleBlue>
            <TitleBlue text={'Sea Lion Dip and Play'} customClass="d-m-center" ></TitleBlue>
            <TitleBlue text={'Sea Lion Dip and Play'} customClass="d-left-m-center" ></TitleBlue>
            <TitleBlue text={'Sea Lion Dip and Play'} customClass="d-center-m-left" ></TitleBlue>
            {/* ** --  */}

            {/* ** -- Main page heading with description, heading font size for Mobile: 23.04px  Web: 58px */}
            <PageMainHeadingContent heading="Our Attractions" theme='themeLight' gapBetween="16px">
                <p>Explore Dubai’s Largest Aquarium, meet our resident Dolphins and South African fur seals.</p>
            </PageMainHeadingContent>
            {/* --  ** */}
            {/* ** -- Banner with Image, heading, subHeadig (Description) */}
            <ImageBannerWithContent
                customClass="dolphinBay"
                bannerImage="https://www.theoceantale.com/atlantis/wp-content/uploads/2020/03/PHY.MWP_.DBY_.2014_Dolphin_Trainers_Withdolphins-MR.jpg"
                heading="Dolphin Bay"
                subHeading="No trip to Dubai is complete without meeting our friendly bottlenose dolphins. You can experience a variety of programmes that provide an up-close look at these magnificent marine mammals and how we take care of them. Our aim is to build a life-long appreciation for marine life and encourage our guests to act on behalf of nature in the Atlas Village. Book one of our bucket-list activities, including options for every age and swimming ability, to round off your ultimate experience in Dubai."
           
           ></ImageBannerWithContent>
            {/*  -- ** */}

            {/* -- Purple Bg banner with content for more reference check demo ** */}
            <BlueBannerContent
                heading="Want to impress your friends with a cool fact?"
                paragraph="Jellyfish have no brain, heart, bones or eyes."
                image={images.jelly_fish}
                showShareButton={true}
            />
            {/* -- ** */}
            {/* -- Purple Bg banner with content for more reference check demo  ** */}
            <BlueBannerContent
                heading="Want to impress your friends with a cool fact?"
                paragraph="Jellyfish have no brain, heart, bones or eyes."
                RightButton1={{ text: "Visit this", buttonType: "btn-outlined" }}
            />
            {/* -- ** */}

            {/* -- ** */}
            <BlueIconwithText image={images.map_starfish} title={"jelly fish"} />
            {/* -- ** */}

            {/* -- Purple Bg banner with content for more reference check demo  ** */}
            <BlueBannerContent
                heading="Want to impress your friends with a cool fact?"
                paragraph="Jellyfish have no brain, heart, bones or eyes."
                RightButton1={{ text: "Visit this", buttonType: "btn-outlined", textColor:'dark' }}
                RightButton2={{ text: "Learn More", buttonType: "btn-outlined", textColor:'dark' }}
                Righttext="or for any enquiry, you can reach us at"
            />
            {/* -- ** */}
            {/* --** mapstarfish component for more details pls refer demo*/}
            <MapwithStarfish 
                heading="Uncover the secrets of the lost civilization"
                paragraph="Navigate your way through The Lost Chambers and explore all of its secrets using this map"
                showDownloadLink={true}
                bgImage={images.seven_sages_chamber_bg}
                buttonText={{
                    text: "Explore all Chambers",
                    buttonType: "btn-outlined"
                }} />

            <MapwithStarfish showDownloadLink={false}
                buttonText={{
                    text: "Explore all Chambers",
                    buttonType: "btn-outlined"
                }} />
            {/* --** */}

            {/* Card view with image */}
            <Card
                title="Dolphin Dip and Play"
                text="Get up-close in waist-deep water with our resident dolphins and learn more about their biology and behaviour. Join us in our daily playful enrichment sessions with our specialist team."
                created={new Date()}
                href="https://theoceantale.com/#/dolphin"
                imgSrc="https://www.theoceantale.com/atlantis/wp-content/uploads/2020/03/PHY.MWP_.DBY_.2014_Dolphin_Trainers_Withdolphins-MR.jpg"
                btnText="text"
            >
                {/* Pass child data */}
                <ul>
                    <li>Daily</li>
                    <li>Open to all ages</li>
                    <li>Swimming Level: beginner</li>
                </ul>
            </Card>


            {/* ** -- */}

            {/* ** -- Card Slider , need to pass the slidetoshow & responsive slidetoshow values */}

            <CardCarousel slidesToShow={1.3}
            isActiveClick={true}
                responsiveSettings={[
                    { breakpoint: 500, settings: { slidesToShow: 1.2 } },
                    { breakpoint: 900, settings: { slidesToShow: 2 } },
                    { breakpoint: 1500, settings: { slidesToShow: 2 } },
                    { breakpoint: 1800, settings: { slidesToShow: 3 } },
                    { breakpoint: 2500, settings: { slidesToShow: 3 } },
                ]}
                image={images.Activity_1}
                name={'jellyfish'}
                itemsState={'itemsState'}
                handleImageClick={handleImageClick}
            />

            {/* ** -- */}
            {/* ** -- */}
            <ActivitySlider
              slideData={packageData}
              customClassForTitle="d-m-left"
              customClassForCardName="d-m-left"
              buttonText="Learn More"
              btnWidth="245px"
              title="Where to go next?"
              Paragraph="Check out other attractions &  explore Dubai’s largest aquarium, meet our resident dolphins and South African fur seals."
              slidesToShow={1.2}
              showButton={true}
              responsiveSettings={[
                { breakpoint: 500, settings: { slidesToShow: 1.2 } },
                { breakpoint: 900, settings: { slidesToShow: 2.2 } },
                { breakpoint: 1500, settings: { slidesToShow: 4 } },
                { breakpoint: 1800, settings: { slidesToShow: 4 } },
                { breakpoint: 2500, settings: { slidesToShow: 4 } },
              ]} />
         
            {/* ** -- */}

            {/* ** -- */}
            {/* commenting the below component as it was throwing error */}
            <ConservationStatus
                showParagraph={false}
                items={ConservationIcons}
                theme={'themeDark'}
                heading={'Conservation Status'}
                showCurrentStatus={true}
            >
                <p>Once you scan a fish, make sure you check out its status to see if it is endangered in the wild.</p>
            </ConservationStatus>            {/* ** -- */}


            {/* ** -- */}
            <YellowIconWithTitle title={'Rhy Species'} image={images.jelly_fish} numberCount="" />
            {/* ** -- */}
            {/* ** -- */}
            <YellowIconWithTitle title={'Rhy Species'} numberCount={10} />
            {/* ** -- */}

            {/* ** -Grid Item- */}
            {/* refer experience page to see more information */}
            <GridItem key={"pass your key here"} item={"pass your data here"} imgSrc={''} name={''} description={''} heightWidthWeb='160px' heightWidthMob='100px'
                contentList={
                    <>
                        <p>{'Hi hlo'}</p>
                        <p>Age: {'Hi hlo'}</p>
                        <p>Price: {'Hi hlo'}</p>
                        <p>Swimming Level: {'Hi hlo'}</p>
                    </>
                }
            >
                {/* Pass child data */}
                <div style={{ width: '160px' }}>
                    <Button text="Know More" buttonType="btn-outlined" textColor="dark" />
                </div>
            </GridItem>

            {/* ** -- */}
                {/* blue icon and green text card with border */}
            <CardIconText text="The Entrance Portal" imgSrc={images.ambassador_lagon} onClick={''}/>

            {/* ** -- */}

            {/* Expert list compoennt ( pass header as children as below) */}

            <ExpertsList items={expertsEntries}>
                <MainTitleContent text={'Meet Our Experts'} customClass="d-m-center" theme='themeLight' gapBetween="16px">
                    <p>Our dedicated team work round-the-clock providing the highest standard of care.Learn about their roles and how they work to protect marine life.</p>
                </MainTitleContent>
            </ExpertsList>
            {/* ** -- */}

            {/* ** -- */}
            {/* Grid items layout  */}
            <GridItemIcon elements={Array(6).fill(ImageIconDummy)} itemsInRow='6' />
            {/* ** -- */}


            <Button onClick={handleShare} text='Social Share' buttonType='btn-backgrounded'></Button>



        </div>

    )
}

export default Demonstration
