import React from 'react'
import TitleBlue from '../../components/UI_Elements/TitleBlue/TitleBlue';
import DancingArrow from '../DancingArrow/DancingArrow';

const CardIconText = ({imgSrc, text, onClick, customClass}) => {
  return (
    <div className={`CardIconText ${customClass}`} onClick={onClick}>
        <div className='CardIconText-wrap'>
            <div className='CardIconText-img'>
            <img src={imgSrc} alt=''/>
            </div>
            <TitleBlue text={text} customClass="d-center-m-left" ></TitleBlue>
        </div>

        <DancingArrow delay={0} arrowAlingRight={true}/>
   </div>
  )
}

export default CardIconText