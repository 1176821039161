import React, { useState } from 'react';
import Button from '../../UI_Elements/Button/Button';
import ActivitySlider from "../../ActivitySlider/ActivitySlider";
import { slideData } from '../../../utils/dummy';
import CardCarousel from "../../Carousels/CardCarousel/CardCarousel";
import MainTitleContent from "../../UI_Elements/MainTitleContent/MainTitleContent";
import { useNavigate } from "react-router-dom";
import FishCameraButton from "../../FishCameraButton/FishCameraButton";
import { navigateToPage } from "../../../CustomHooks/navigateToRoute";

const ScanResult = (props) => {
    const navigate = useNavigate();
    const [selectedId, setSelectedId] = useState();

    const cardClicked = (id) => {
        console.log(id); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
        navigateToPage(`/scan-fish/${id}`, navigate)
    }

    const handleImageClick = (id) => {
        console.log(`/scan-fish/${id}`);
        navigateToPage(`/scan-fish/${id}`, navigate)
    };



    console.log('props.fishList ==', props.fishList);
    return (
        <div className='modalContainer'
            //style={{ maxHeight: window.innerHeight - 200 }}
        >
            <div className='sliderContainer'>
                <div style={{ paddingBottom: '10px    ' }}>
                    <MainTitleContent text={"You Discovered!"} h6={true} customClass="d-m-center" theme='themeLight' gapBetween="5px">
                        <p className='instruction'>Tap to view fish details!</p>
                    </MainTitleContent>
                </div>
                <CardCarousel
                    isActiveClick={true}
                    //handleClick={handleClick} 
                    //handleImageClick={handleImageClick}
                    contentClickRoute={'/scan-fish/'}
                    slideData={props?.fishList?.scan_fishes}
                    customClass={'slideSize110 roundSlide'}
                    slidesToShow={3.2}
                    responsiveSettings={[
                        { breakpoint: 500, settings: { slidesToShow: 3.2 } },
                        { breakpoint: 900, settings: { slidesToShow: 3.2 } },
                        { breakpoint: 1500, settings: { slidesToShow: 3.2 } },
                        { breakpoint: 1800, settings: { slidesToShow: 3.2 } },
                        { breakpoint: 2500, settings: { slidesToShow: 3.2 } },
                    ]}
                />

            </div>
            {props?.fishList?.related_fishes != null &&
                <div className='relatedfishContainer'>
                    <div style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                        <MainTitleContent text={"Related fishes"} h6={true} customClass="d-m-center" theme='themeLight' gapBetween="5px">
                            <p className='instruction'>Tap to view fish details!</p>
                        </MainTitleContent>
                    </div>
                    <CardCarousel
                        isActiveClick={true}
                        //handleClick={handleClick} 
                        //handleImageClick={handleImageClick}
                        contentClickRoute={'/scan-fish/'}
                        slideData={props?.fishList?.related_fishes}
                        customClass={'slideSize80 roundSlide'}
                        slidesToShow={3.2}
                        responsiveSettings={[
                            { breakpoint: 500, settings: { slidesToShow: 3.2 } },
                            { breakpoint: 900, settings: { slidesToShow: 3.2 } },
                            { breakpoint: 1500, settings: { slidesToShow: 3.2 } },
                            { breakpoint: 1800, settings: { slidesToShow: 3.2 } },
                            { breakpoint: 2500, settings: { slidesToShow: 3.2 } },
                        ]}
                    />
                </div>
            }
            <div className='actions'>
                <div className='actionContainer'>
                    <div onClick={props.onClose}>
                        <FishCameraButton btnType="button" btnText="Scan Again" btnStyle="btn-outlined" textColor="dark"/>
                    </div>
                    <Button text='View Fish Library' buttonType='btn-backgrounded' onClick={() =>
                        navigateToPage('/fish-library', navigate)
                    }></Button>
                </div>
            </div>
           
        </div>
    );
};

export default ScanResult;