import React, { useEffect, useState } from 'react'
import Carousel from 'react-grid-carousel'
import GridItem from '../../UI_Elements/GridItem/GridItem';
import TitleBlue from '../../UI_Elements/TitleBlue/TitleBlue';
import ReactPaginate from 'react-paginate';

const CarouselWithPagination = (props) => {

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = props.activeDeviceType == 'mobile' ? 3 : 6;
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };


    // const renderCarouselItems = (items) => {
    //     const startIndex = currentPage * itemsPerPage;
    //     const endIndex = startIndex + itemsPerPage;
    //     return items?.slice(startIndex, endIndex).map((item, i) => (
    //       <Carousel.Item key={i}>
    //         <GridItem key={i} item={item} 
    //         imgSrc={item?.image} 
    //         name={item?.title} 
    //         description={item?.description} 
    //         heightWidthWeb='160px' 
    //         heightWidthMob='100px'
    //         customClass={'d-m-left lg'}
    //         customStyleContent={{paddingTop:'10px'}}
    //         customStyleContainer={{paddingBottom:'20px'}}
    //           contentList={
    //             <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'row'}}>
    //               <p style={{ color: '#fff', marginRight:'5px' }}>Main Trainer:</p>
    //               <TitleBlue text={item?.main_trainer} customClass="d-m-left lg" ></TitleBlue>
    //             </div>
    //           }
    //         >
    //         </GridItem>
    //       </Carousel.Item>
    //     ));
    //   };


    const renderCarouselItems = (items) => {
      const startIndex = currentPage * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
    
      return items && Array.isArray(items)
        ? items.slice(startIndex, endIndex).map((item, i) => (
            <Carousel.Item key={i}>
              <GridItem
                keyVal={i}
                item={item}
                imgSrc={item?.image}
                name={item?.title}
                description={item?.description}
                heightWidthWeb="160px"
                heightWidthMob="100px"
                customClass="d-m-left lg"
                customStyleContent={{ paddingTop: '10px' }}
                customStyleContainer={{ paddingBottom: '20px' }}
                contentList={
                  item?.main_trainer?(
                  <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
                    
                    <p style={{ color: '#282828', marginRight: '5px' }}>Main Trainer:</p>
                    <TitleBlue text={item?.main_trainer} customClass="d-m-left lg" />
                  </div>
                  ):(null)
                }
              >
              </GridItem>
            </Carousel.Item>
          ))
        : null;
    };

    // classes used as props are 
    // 1.btn-outlined
    // 2.btn-backgrounded

    return (
        <div className='carouselWithPagination'>
                <Carousel
                  className="customCarouselContainer"
                  cols={2}
                  rows={3}
                  gap={10}
                  containerStyle={{
                    margin: 0, // Remove the default margin
                  }}
                  showDots={false}
                  responsiveLayout={[
                    {
                      breakpoint: 1200,
                      cols: 2,
                      row: 3
                    },
                    {
                      breakpoint: 990,
                      cols: 2,
                      row: 3
                    },
                    {
                      breakpoint: 670,
                      cols: 1,
                      row: 3
                    }
                  ]}
                  mobileBreakpoint={100}
                >
                  {renderCarouselItems(props.listItem)}
                </Carousel>
                <ReactPaginate
                  previousLabel="Previous"
                  nextLabel="Next"
                  breakLabel="..."
                  pageCount={Math.ceil(props.listItem.length / itemsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={1}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
    );
};

export default CarouselWithPagination;