import React, { useEffect } from 'react';
import MainTitleContent from '../MainTitleContent/MainTitleContent';
import { useDispatch, useSelector } from "react-redux";
import PlayVideo from '../../PlayVideo/PlayVideo'
import { checkDeviceType } from '../../../actions/CheckDevice';
import ParagraphDark from '../ParagraphDark/ParagraphDark';

const TakePledge = (props) => {
    const deviceType = useSelector((state) => state.checkDevice.deviceType);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(checkDeviceType());
    }, [dispatch, deviceType]);
    const { title, linkTo, text, mediaSrc, mediaType, children, cardAnimationType,  cardAnimationDuration } = props;
    return (
        <div className={`TakePledge  ${deviceType == 'mobile' ? 'mobile-Pledge' : 'web-Pledge'}`}>
            <div className='mediaContainer' >
                {mediaType == 'video' ?
                    <PlayVideo className="footer-video" src={mediaSrc} /> :
                    <img className="img" alt="card" src={mediaSrc} />
                }
            </div>
            <div className='mainContainer'>
                <div className='container'>
                    <div className={`pledgeSection ${deviceType == 'mobile' ? 'container' : ''}`}>
                        {deviceType == 'mobile' ?
                            // <p>Tap to play or pause the video</p> :
                           null:
                            null
                        }
                        <div data-aos={deviceType== 'desktop'?"fade-up":"fade-up"} data-aos-delay="100">
                            {children}
                        </div>
                    </div>
                    <div className={`${deviceType == 'mobile' ? '' : 'content-wraper'}`}>
                        <MainTitleContent 
                        text={title} 
                        customClass="d-left-m-center" 
                        theme='themeWhite' 
                        gapBetween="16px" 
                        cardAnimationType={cardAnimationType} 
                        cardAnimationDuration={cardAnimationDuration}>
                            <ParagraphDark para={text} htmlPara={true} textColor={props.textColor}/>
                        </MainTitleContent>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TakePledge;