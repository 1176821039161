import { FETCH_FISH_HISTORY } from "../actions/FishHistory/type";

const initialState = {
    detectedFishData: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FISH_HISTORY:
            return {
                ...state,
                detectedFishData: action.payload,
              };
            default:
              return state;
    }
};
