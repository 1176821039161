import React, { useState } from 'react';
import icons from '../../../assets/icons/icons';



const Button = ({ type, buttonType, onClick, pledgeIcon, textColor, text, customClass }) => {

  const [isHovered, setIsHovered] = useState(false);

  const btnTextType = (textColor == 'dark' ? 'btn-text-dark' : 'btn-text-light');

  // classes used as props are 
  // 1.btn-outlined
  // 2.btn-backgrounded

  return (
    // <button type={`${type ? type : 'button'}`} className={`button-wrap ${buttonType} ${btnTextType} ${customClass}`}  onClick={onClick}>
    //   {pledgeIcon ?
    //     <img src={pledgeIcon} alt="" style={{ marginRight: "5" }} /> :
    //     null
    //     }
    //   {text}
    // </button>


    <button
      type={type ? type : 'button'}
      className={`button-wrap ${buttonType} ${btnTextType} ${customClass}`}
      onClick={onClick}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {isHovered && pledgeIcon ? (
        <img src={icons.icon_heart_handshake_active} alt="" style={{ marginRight: 5 }} />
      ) : (
        pledgeIcon && <img src={pledgeIcon} alt="" style={{ marginRight: 5 }} />
      )}
      {text}
    </button>
  );
};

export default Button;