import React, { useState, useEffect, useRef } from 'react';
import images from '../../assets/images/images';
import { useDispatch, useSelector } from "react-redux";
import { checkDeviceType } from '../../actions/CheckDevice';

const FishDetection = (props) => {
    const deviceType = useSelector((state) => state.checkDevice.deviceType);
    const _fishUpload = useSelector((state) => state.fishUpload.items);
  
    const dispatch = useDispatch();
   
    useEffect(() => {
      dispatch(checkDeviceType());
    }, [dispatch, deviceType]);



  return (
    <div className='fishDetection-section'> FishDetectedCount
          <img src={images.fish_icon} alt="" />
          <span className="text">Fish Discovered : 3</span>
    </div>
  );
};

export default FishDetection