import React from 'react';

const ParagraphDark = (props) => {
  return (
    <>
      {props.htmlPara ? (
        <>
          {props.textColor == 'light' ?
            <div className={`p-white ${props.customClass}`} dangerouslySetInnerHTML={{ __html: props.para }} />
            :
            <div className={`p-dark ${props.customClass}`} dangerouslySetInnerHTML={{ __html: props.para }} />
          }
        </>
      ) : (
        <>
          {props.textColor == 'light' ?
            <p className={`p-white ${props.customClass}`}>{props.para}</p>
            :
            <p className={`p-dark ${props.customClass}`}>{props.para}</p>
          }
        </>
      )}
    </>
  );
}

export default ParagraphDark;
