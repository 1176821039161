import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { navigateToPage } from "../../../CustomHooks/navigateToRoute";
import { useNavigate } from "react-router-dom";


const CardCarousel = ({ isActiveClick, contentClickRoute, slidesToShow, responsiveSettings, slideData, customClassForCardName, customClass, handleImageClick }) => {
  const navigate = useNavigate();

  const [isDragging, setIsDragging] = useState(false);

  var settings = {
    dots: false,
    infinite: false,
    prevArrow: <></>,
    nextArrow: <></>,
    autoplay: false,
    autoplaySpeed: 0,
    slidesToScroll: 1,
    slidesToShow: slidesToShow,
    mobileFirst: true,
    responsive: responsiveSettings,
    beforeChange: () => {
      setIsDragging(true);
    },
    afterChange: () => {
      setIsDragging(false);
    }
  };

  const handleItemClick = (id) => {
    handleImageClick(id);
  };
  const handleNavigation = (link) => {
    if (!isDragging) {
      navigateToPage(link, navigate);
    }
  };


  return (
    <div className={`card-slider ${customClass}`}>
      {slideData?.length > 0 && (
        <Slider {...settings} >
          {slideData?.map((item, id) => (
            <div key={id} className='cardBody'
            //data-aos="fade-up" data-aos-delay={`${id}00`}
            >
              <div className={`cardBody-content `}>

                {isActiveClick ? (
                  <div className={`cardBody-Imgcontent ${contentClickRoute ? 'activeCardContent' : 'noActiveCardContent'} `}
                    onClick={() => handleNavigation(`${contentClickRoute}${item?.ID ? item?.ID : item?.id ? item?.id : ''}`)}>
                    <img className='cardImg' src={item?.image ? item?.image : undefined} alt='' />
                  </div>
                ) : (
                  <div className={`cardBody-Imgcontent ${contentClickRoute ? 'activeCardContent' : 'noActiveCardContent'} `}
                  >
                    <img className='cardImg' src={item?.image ? item?.image : undefined} alt='' />
                  </div>
                )}

                {
                isActiveClick ? (
                  <div onClick={() => handleNavigation(`${contentClickRoute}${item?.ID ? item?.ID : item?.id ? item?.id : ''}`)}>
                    {item?.title ? (
                      <h4 className={`species-name ${customClassForCardName}`}>{item?.title}</h4>
                    )
                      : item?.text ?
                        <p className='species-desc'>{item?.text}</p>
                        : (
                          <p className='species-desc'>{item?.description}</p>
                        )}
                  </div>
                ) : (
                  <div >
                    {item?.title ? (
                      <h4 className={`species-name ${customClassForCardName}`}>{item?.title}</h4>
                    )
                      : item?.text ?
                        <p>{item?.text}</p>
                        : (
                          <p className='species-desc'>{item?.description}</p>
                        )}
                  </div>
                )
                }
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default CardCarousel;
