import { FETCH_ALL_CHAMBERS, FETCH_CHAMBERS_DETAILS } from "../actions/AllChambers/type";

const initialState = {
    items: [],
    itemsDetails: [],
    metaImage: "",
    metaTitle: "",
    metaDescription: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_CHAMBERS:
            return {
                ...state,
                items: action.items,
                metaTitle: action.metaTitle,
                metaImage:action.metaImage,
                metaDescription: action.metaDescription
            };
        case FETCH_CHAMBERS_DETAILS:
            return {
                ...state,
                itemsDetails: action.items,
                metaTitle: action.metaTitle,
                metaImage:action.metaImage,
                metaDescription: action.metaDescription
            };
        default:
            return state;
    }
};
