import React, { useEffect }from 'react';
import TitleBlue from '../TitleBlue/TitleBlue';
import ParagraphDark from '../ParagraphDark/ParagraphDark';
import { checkDeviceType } from '../../../actions/CheckDevice';
import { useDispatch,useSelector } from "react-redux";


const GridItem = (props) => {
    const deviceType = useSelector((state) => state.checkDevice.deviceType);
    const dispatch = useDispatch();

    const { heightWidthWeb, heightWidthMob, item, contentList, name, description,imgSrc,onClick } = props;

    const HeightWidth = {
        height: `${deviceType === 'mobile'?heightWidthMob :heightWidthWeb}`,
        width: `${deviceType === 'mobile'?heightWidthMob :heightWidthWeb}`,
      }
    useEffect(() => {
        dispatch(checkDeviceType());
     }, []);
  return (
    <div className={`content-grid-wrap ${onClick && 'activeCardContent'}`}
     data-aos="fade-up" data-aos-delay={`${props.keyVal}00`} onClick={onClick}>
        <img src={imgSrc} alt=""  style={HeightWidth}/>
        <div className="content-grid-body" style={props?.customStyleContainer}  >
          <div>
            <TitleBlue text={name}  customClass={props.customClass}/>
            <div style={props.customStyleContent}>
             <ParagraphDark para={description} htmlPara={true}/>
            </div>
            <div className="content-details-wrap">
                {contentList}
            </div>
          </div>
            {props.children}
        </div> 
  </div>
  )
}

export default GridItem