import React, {useEffect} from 'react';

const useScrollDirection=() =>{
  const [scrollDirection, setScrollDirection] = React.useState(null);
  const [load, setLoad] = React.useState(true);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 0 || scrollY - lastScrollY < 0)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;

      // Check if the page has been scrolled or not
      if (load && scrollY !== 0) {
        setLoad(false);
      }
    };

    window.addEventListener("scroll", updateScrollDirection);
    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection, load]);

  return { scrollDirection, load };
}

export default useScrollDirection;
