import React from 'react'

const ParagraphLight = (props) => {
  return (
    <>
      {props.htmlPara ? (
        <div className={`p-light ${props.customClass}`} dangerouslySetInnerHTML={{ __html: props.para }} />
      ) : (
        <p className={`p-light ${props.customClass}`}>{props.para}</p>
      )}
    </>

  )
}

export default ParagraphLight