import React from 'react'
import ParagraphDark from '../ParagraphDark/ParagraphDark';

const ImageBanner = (props) => {

  return (
    <div
    data-aos={props.animationType} data-aos-delay={props.animationDuration}
      className={`ImageBanner ${props.customClass}`}
      style={props.customStyle}
      >
       <img src={props.bannerImage} alt='bannerImage'/>
    </div>
  )
}

export default ImageBanner