import React, { useEffect, useState } from 'react';
import images from "../../assets/images/images";
import ConservationStatus from "../../components/ConservationStatus/ConservationStatus";
import { ConservationIcons } from "../../utils/dummy";
import Button from "../../components/UI_Elements/Button/Button";
import { fishDetail } from '../../utils/dummy';
import { fishInformation } from "../../actions/fishUpload";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Loader from "react-js-loader";
import { clearScan } from "../../actions/fishUpload";
import FishCameraButton from "../../components/FishCameraButton/FishCameraButton";
import { Helmet } from 'react-helmet';
import ParagraphDark from '../../components/UI_Elements/ParagraphDark/ParagraphDark';
import { setActiveUrl } from "../../actions/NavigateUrls";


const ScanFish = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const fishInfo = useSelector((state) => state.fishUpload.fishInfo);

  const metaTitle = useSelector(state => state.fishUpload.metaTitle);
  const metaImage = useSelector(state => state.fishUpload.metaImage);
  const metaDescription = useSelector(state => state.fishUpload.metaDescription);


  const [loading, setLoading] = useState(false);


  // useEffect(() => {
  //   dispatch(clearScan());
  // },[]);

  useEffect(() => {
    dispatch(setActiveUrl('/scan-fish'));
  }, [])


  useEffect(() => {
    setLoading(true);
    dispatch(fishInformation(params.fishId))
      .then(() => setLoading(false))  // Set loading to false after the API call is completed
      .catch((error) => {
        setLoading(false);  // Set loading to false in case of an error
        console.log(error);
      });
  }, [dispatch, params.fishId]);

  console.log('fishInfo == ', fishInfo);


  return (
    <>
     <Helmet>
<meta property="og:title" content={metaTitle}/>
<meta property="og:image" content={metaImage} />
<meta property="og:image:secure_url" content={metaImage}/>
<meta property="og:image:type" content="image/png" />
<meta property="og:image:width" content="400" />
<meta property="og:image:height" content="300" />
<meta property="og:image:alt" content="" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      {loading ? (
        <div className="loaderContainer">
          <Loader type="spinner-cub" bgColor={"#2DCCD3"} title={"Finding Your Fish"} color={'#CCD1DB'} size={24} />
        </div>
      ) : (
        <div className="scanFishContainer container dynamicMinHeight">
          <div>
            {fishInfo?.section_filsh_profile &&
              <>
                <div className='infoText' 
                data-aos="fade-up"
                data-aos-delay={100}
            >
                  <p>You Discover</p>
                </div>
                <div className="heading" 
                data-aos="fade-up"
                data-aos-delay={100} >
                  <h1>{fishInfo?.section_filsh_profile?.title}</h1>
                </div>
                <div className='fishImage'
                data-aos="fade-up"
                data-aos-delay={100}
                >
                  <img src={fishInfo?.section_filsh_profile?.images} alt="fish" className="fishImg" />
                </div>
                <div className="fishdetailContainer"
                data-aos="fade-up"
                data-aos-delay={100}
                >
                  {/* width */}
                  <div className='item-list'>
                    <img src={images.fishWidth} alt="" />
                    <span
                      className='detail'
                    >{fishInfo?.section_filsh_profile?.width}</span>
                  </div>
                  {/* length */}
                  <div className='item-list'>
                    <img src={images.fishlength} alt="" />
                    <span
                      className='detail'
                    >{fishInfo?.section_filsh_profile?.length}</span>
                  </div>
                  {/* weight */}
                  <div className='item-list'>
                    <img src={images.fishweight} alt="" />
                    <span
                      className='detail'
                    >{fishInfo?.section_filsh_profile?.weight}</span>
                  </div>
                  {/* life */}
                  <div className='item-list'>
                    <img src={images.fishLife} alt="" />
                    <span
                      className='detail'
                    >{fishInfo?.section_filsh_profile?.life}</span>
                  </div>

                </div>
              </>
            }
          </div>
          {fishInfo?.section_conservation_status &&
            <>
              <ConservationStatus
                showParagraph={false}
                items={ConservationIcons}
                theme={'themeDark'}
                heading={'Conservation Status'}
                showCurrentStatus={true}
                currentStatusVal={fishInfo?.section_conservation_status?.status}
                layoutClass="dynamic-grid center"
              >
              </ConservationStatus>
              <div className='detail' 
                data-aos="fade-up"
                data-aos-delay={100}>
                <ParagraphDark customClass="description" para={fishInfo?.section_conservation_status?.description}  htmlPara='true' textColor={'dark'}/>
              </div>
            </>
          }
          {fishInfo?.section_map &&
            <>
              <div className='localisationContainer'>
                <h2 className='localisation'>
                  {fishInfo?.section_map?.title}
                </h2>
                <div className='LocationMap'>
                  <img src={fishInfo?.section_map?.map} alt="scanfish" className="fishImg" />
                </div>
              </div>
              <div className='actionContainer'>
                {/* <Button text='Scan Fish' buttonType='btn-backgrounded'></Button> */}
                < FishCameraButton btnType="button"  btnText="Scan Fish" btnStyle="btn-backgrounded" />
              </div>
            </>
          }
        </div>
      )}
    </>
  )
}

export default ScanFish