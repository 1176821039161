import { FETCH_FISH, SCAN_RESULT, CLEAR_SCAN, FISH_DETAIL_FETCHED, Fetch_FISH_INFORMATION } from './type';
import { axiosPythonApi } from "../../utils/request";
import { generateToken } from "../../utils/functions";
import { axiosCustom, axiosFishApiBaseUrl } from "../../utils/request";

export const fetchFishUpload = (data) => (dispatch) => {
    if (data) {
        dispatch({
            type: FETCH_FISH,
            items: data,
        });
    }
};

export const FishScaning = (file) => async (dispatch) => {
    console.log('file received ===', file);

    const token = generateToken();
    const tank = "t21";


    const formData = new FormData();
    formData.append("file", file);
    formData.append("token", token);
    formData.append("tank", tank);
    formData.append('email', localStorage.getItem("loginData"));
    formData.append('session', localStorage.getItem("accessToken"));

    const response = await axiosPythonApi.post(`photo?width=${window.innerWidth}&height=${window.innerHeight}`, formData);


    const { data = false, status } = response;
    if (status === 200) {
        if (data) {
            dispatch({
                type: SCAN_RESULT,
                scanResult: data,
            });
        }
    }



    console.log('response ==', response);

};



export const scannedFishDetail = (result) => async (dispatch) => {
    console.log('result of python scan === ', result);
    const scanFish=[];
    const relatedFish=[];
    if (result?.fishes?.length > 0) {
        result?.fishes?.forEach(fish => {
            if (!scanFish.includes(fish?.name)) {
                scanFish.push(fish?.name);
            }
            fish?.relatedFish?.forEach(related => {
                if (!relatedFish.includes(related)) {
                    relatedFish.push(related);
                }
            });
        });
    }
    else {
        result?.popularSpecies?.forEach(species => {
            if (!relatedFish.includes(species)) {
                relatedFish.push(species);
            }
        });
    }
    const response = await axiosFishApiBaseUrl.post("related", {
        scan_fishes: scanFish,
        related_fishes:relatedFish
    }
    )
    const { val = false, status } = response;
    if (status === 200) {
        console.log('response 200 =')
        if (response.data) {
            console.log('response =')
            dispatch({
                type: FISH_DETAIL_FETCHED,
                fishDetails: response.data
            });
        }
    }




}
export const clearScan = () => (dispatch) => {
    dispatch({
        type: CLEAR_SCAN,
        items: null,
        scanResult: null,
        fishDetails: null,
    });
};

// Single Fish information

export const fishInformation = (id) => async (dispatch) => {
    const response = await axiosFishApiBaseUrl.get(`get/${id}`);
    const { data = false, status } = response;
    if (status === 200) {
        if (data) {
            dispatch({
                type: Fetch_FISH_INFORMATION,
                fishInfo: data,
                metaTitle: data?.page_meta?.meta_title,
                metaImage:data?.page_meta?.meta_image,
                metaDescription: data?.page_meta?.meta_description
            });
        }
    }
};


