

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchingExperiencesDetail } from '../../actions/Experiences';
import { checkDeviceType } from '../../actions/CheckDevice';
import { useDispatch, useSelector } from 'react-redux';
import ImageBannerWithContent from "../../components/UI_Elements/ImageBannerWithContent/ImageBannerWithContent";
import ImageBanner from "../../components/UI_Elements/ImageBanner/ImageBanner";
import MainTitleContent from "../../components/UI_Elements/MainTitleContent/MainTitleContent";
import GridItemIcon from '../../components/GridItemIcon/GridItemIcon';
import Button from '../../components/UI_Elements/Button/Button';
import { ImageIconDummy, packageData } from "../../utils/dummy";
import ActivitySlider from '../../components/ActivitySlider/ActivitySlider';
import ParagraphDark from '../../components/UI_Elements/ParagraphDark/ParagraphDark';
import icons from '../../assets/icons/icons';
import TakePledge from '../../components/UI_Elements/TakePledge/TakePledge';
import SocialShare from '../../components/Modals/SocialShare/SocialShare';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { fetchPledgeOptions } from "../../actions/attraction";
import { Helmet } from 'react-helmet';
import { setActiveUrl } from "../../actions/NavigateUrls";


const ExperienceDetails = () => {
  const experiencesItem = useSelector((state) => state.experiences.itemDetails);
  const deviceType = useSelector((state) => state.checkDevice.deviceType);
  let pleadgeOption = useSelector((state) => state.attraction.pleadgeOption);

  const metaTitle = useSelector(state => state.experiences.metaTitle);
  const metaImage = useSelector(state => state.experiences.metaImage);
  const metaDescription = useSelector(state => state.experiences.metaDescription);


  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(setActiveUrl('/experience/details'));
  }, [])

  useEffect(() => {
    if (visible) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
    return () => {
      document.body.classList.remove("mobile-menu-visible");
    };
  }, [visible]);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(checkDeviceType());
    dispatch(fetchingExperiencesDetail(params.experienceId));
    dispatch(fetchPledgeOptions());

  }, [dispatch, deviceType, params.experienceId]);




  const renderBanner = () => {
    return (
      <div >
        {deviceType === 'desktop' ?
          <ImageBannerWithContent
            customClass="dolphinBay"
            bannerImage={experiencesItem?.section_banner?.image}
            heading={experiencesItem?.section_banner?.title}
            subHeading={experiencesItem?.section_banner?.description?.rendered}
            imgAnimationType="zoom-out"
          imgAnimationDuration={100}
            descAnimationType={deviceType == 'desktop' ? "fade-up" : "fade-up"}
            descAnimationDuration={100}
            titleAnimationType={deviceType == 'desktop' ? "fade-up" : "fade-up"}
            titleAnimationDuration={100}
            textColor={'light'}
          />
          :
          <div>
            <ImageBanner
              customClass="noContentBanner"
              customStyle={{ height: '341px', with: '100%', marginBottom: '25px' }}
              bannerImage={experiencesItem?.section_banner?.image}
              animationType={"fade-up"}
              animationDuration={100}
            />
            <div className='container'>
              <MainTitleContent 
              text={experiencesItem?.section_banner?.title} 
              customClass="d-m-center" 
              theme='themeLight' 
              gapBetween="16px"
              cardAnimationType={"fade-up"}
              cardAnimationDuration={100}
              >
                <ParagraphDark para={experiencesItem?.section_banner?.description?.rendered} htmlPara={true} />
              </MainTitleContent>
            </div>
          </div>
        }
      </div>
    )
  }

  const renderAnimalDetails = () => {
    console.log('experiencesItem == ', experiencesItem);
    return (
      <div className='container '>
        <div className='experiences-details-wrap'>
          <GridItemIcon elements={experiencesItem?.section_more_info} itemsInRow='6' textColor="dark" />

          <div style={{ width: "166px" }} data-aos={deviceType == 'desktop' ? "fade-up" : "fade-up"} data-aos-delay="200">
            <Button text={experiencesItem?.booking_text} buttonType='btn-outlined' textColor='dark'
              onClick={() =>
                window.open(experiencesItem?.bookingLink, "_blank")
              }>
            </Button>
          </div>
        </div>
      </div>
    )
  }
  const renderPackages = () => {
    return (
      <div className='experiences-packages-wrap'>
        <div className='container'>
          <ActivitySlider
            activeClick={false}
            //onClick={() => console.log('clicked')}
            slideData={experiencesItem?.includes?.list}
            customClassForTitle="d-m-center"
            customClassForCardName="d-m-center"
            gapBetween="4px"
            title={experiencesItem?.includes?.title}
            slidesToShow={3}
            responsiveSettings={[
              { breakpoint: 500, settings: { slidesToShow: 3.2 } },
              { breakpoint: 900, settings: { slidesToShow: 3.2 } },
              { breakpoint: 1500, settings: { slidesToShow: 3.2 } },
              { breakpoint: 1800, settings: { slidesToShow: 3.2 } },
              { breakpoint: 2500, settings: { slidesToShow: 3.2 } },
            ]} />
            
        </div>
      </div>
    )
  }

  const show = () => {
    setVisible(true);
  }

  const hide = () => {
    setVisible(false);
  }

  const CustomCloseIcon = () => {
    return <span>X</span>; // Custom close icon element or component
  };


  const renderTakeAPledge = () => {
    // var hasVideoProperty = Object.prototype.hasOwnProperty.call(experiencesItem?.take_a_pledge, "video");

    const hasVideoProperty = true;
    console.log('status', hasVideoProperty);
    return (
      <>
        {deviceType === 'mobile' ?
          <TakePledge
            mediaType={hasVideoProperty ? 'video' : 'image'}
            title={experiencesItem?.take_a_pledge?.title}
            text={experiencesItem?.take_a_pledge?.description}
            linkTo="https://theoceantale.com/#/dolphin"
            mediaSrc={hasVideoProperty ? experiencesItem?.take_a_pledge?.video : experiencesItem?.take_a_pledge?.image}
            cardAnimationType={deviceType=='desktop'?"fade-up": "fade-up"}
            cardAnimationDuration={100}
            textColor={'light'}
         >
            <Button text={experiencesItem?.take_a_pledge?.cta?.title} 
            onClick={show} 
            pledgeIcon={icons.icon_heart_handshake} buttonType='btn-white-outlined' textColor='light'></Button>
          
          </TakePledge>
          :
          null
        }
      </>
    )
  }

  const renderContents = () => {
    return (
      <>
        {renderBanner()}
        {renderAnimalDetails()}
        {renderPackages()}
        {renderTakeAPledge()}
      </>
    )
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:secure_url" content={metaImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className='experiences-details dynamicMinHeight'>
        {renderContents()}

        <Rodal visible={visible} onClose={hide}
          showCloseButton={true}
          width={deviceType === 'desktop' ? 730 : window.innerWidth}
          height={deviceType == 'desktop' ? 640 : 'auto'}
          className="pledgeModal"
          customStyles={{ backgroundColor: '#fff' }}
          animation='slideUp'
          //closeButton={ icons.modal_close_Icon} // Use the custom close icon component
          closeIcon={<CustomCloseIcon />}
        >
          {/* <PledgeForm onClose={hide} onSubmit={submitData} /> */}
          <SocialShare />
          {/* <ScanResult/> */}
        </Rodal>

      </div>
    </>
  );
};



export default ExperienceDetails;
