
// actions.js
import { SET_DEVICE_TYPE } from './type';

export const setDeviceType = (deviceType) => ({
  type: SET_DEVICE_TYPE,
  payload: deviceType,
});

export const checkDeviceType = () => (dispatch) => {
  const updateDeviceType = () => {
    const isMobile = window.innerWidth <= 768; // threshold for mobile devices
    const deviceType = isMobile ? 'mobile' : 'desktop';
    dispatch(setDeviceType(deviceType));
  };

  // Initial check
  updateDeviceType();

  // Add event listener for screen resize
  window.addEventListener('resize', updateDeviceType);

  // Cleanup by removing event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', updateDeviceType);
  };
};
