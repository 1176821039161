import React, { useEffect } from "react";
import ConservationStatus from "../../components/ConservationStatus/ConservationStatus";
import Button from "../../components/UI_Elements/Button/Button";
import ExpertsList from "../../components/UI_Elements/ExpertsList/ExpertsList";
import MainTitleContent from "../../components/UI_Elements/MainTitleContent/MainTitleContent";
import YellowIconWithTitle from "../../components/YellowIconWithTitle/YellowIconWithTitle";
import { ConservationIcons, expertsEntries } from "../../utils/dummy";
import { fetchingDiscover } from "../../actions/Discover";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { checkDeviceType } from '../../actions/CheckDevice';
import ParagraphLight from '../../components/UI_Elements/ParagraphLight/ParagraphLight';
import { navigateToPage } from '../../CustomHooks/navigateToRoute';
import { Helmet } from 'react-helmet';
import { setActiveUrl } from "../../actions/NavigateUrls";

const Discover = () => {

  const navigate = useNavigate();
  const deviceType = useSelector((state) => state.checkDevice.deviceType);

  const items = useSelector(state => state.discover.items);
  const animalCategories = Object.values(items?.section_aquarium_animals?.animal_categories || {});

  const metaTitle = useSelector(state => state.discover.metaTitle);
  const metaImage = useSelector(state => state.discover.metaImage);
  const metaDescription = useSelector(state => state.discover.metaDescription);
  const activePage = useSelector((state) => state.navigateUrlsReducer.activeUrl);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveUrl('/discover'));
  }, [])

  useEffect(() => {
    dispatch(fetchingDiscover());
    console.log("fetchingHome", items);
  }, [dispatch]);

  useEffect(() => {
    dispatch(checkDeviceType());
  }, [dispatch, deviceType]);

  useEffect(() => {
    console.log("items", items);
  }, [items]);


  return (
    <div>
      <Helmet>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:secure_url" content={metaImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div className="discover-container dynamicMinHeight">
        <div className='discover-banner-1' >
          <div data-aos={deviceType == 'desktop'?"zoom-out":"fade-up"} data-aos-delay="100">
            <div className='banner1' style={deviceType == 'desktop' ?
              { backgroundImage: `
              linear-gradient(90deg, rgba(10, 124, 138, 0.7), transparent 70%), url(${items?.section_chambers_aquarium?.image})` } :
              { backgroundImage: `url(${items?.section_chambers_aquarium?.image})` }
            }>
            </div>
          </div>
          <div className="banner-content">
            <div
              data-aos={deviceType == 'desktop' ? "fade-up" : "fade-up"}
              data-aos-delay="200">
              <MainTitleContent text={items?.section_chambers_aquarium?.title} customClass="d-m-center" theme='themeDark' gapBetween="16px">
                <div
                  className="detailConteiner"
                  dangerouslySetInnerHTML={{ __html: items?.section_chambers_aquarium?.description }}
                />
              </MainTitleContent>

              <Button customClass="DrakHoverButton" text='Explore all Chambers' 
              buttonType={deviceType == 'desktop' ? "btn-white-outlined" : "btn-backgrounded"}
              onClick={() => navigateToPage('/chambers', navigate)}></Button>
            </div>

          </div>
        </div>
        <section className="discover-banner-2">
          <div className='banner2' style={deviceType == 'desktop' ?
            { backgroundImage: `linear-gradient(90deg, transparent, transparent,#020A1D 100%),url(${items?.section_aquarium_animals?.image})` } :
            { backgroundImage: `url(${items?.section_aquarium_animals?.image})` }
          }>
            {/* <div className="bg-overlay"></div> */}
          </div>
          <div className="banner-content">

            <MainTitleContent text={items?.section_aquarium_animals?.title} customClass="d-m-center" theme='themeDark' gapBetween="16px"
              cardAnimationType={"fade-up"}
              cardAnimationDuration={"100"}>
              <div data-aos="fade-up" data-aos-delay="200">
                <div
                  className="detailConteiner"
                  dangerouslySetInnerHTML={{ __html: items?.section_aquarium_animals?.description }}
                />
              </div>
            </MainTitleContent>
            <div className="speices-list">
              {
                animalCategories?.map((item, index) => {
                  return (
                    <div key={item?.id} className='number-card-wrap' data-aos="fade-up" data-aos-delay={`${index}00`}>
                      <YellowIconWithTitle title={item.name} numberCount={item.count} textColor={'light'}/>
                    </div>
                  );
                })
              }
            </div>
            <div data-aos="fade-up" data-aos-delay="1000">
              <Button text='Explore all Animals' buttonType={deviceType == 'desktop'?'btn-white-outlined':'btn-outlined'} textColor={deviceType == 'desktop'?'light':'dark'} onClick={() => navigateToPage('/all-animals', navigate)}></Button>
            </div>
          </div>
        </section>

        <section className="discover-section3">
          {/* ------ section favorte */}
          <div className="wrapper-section">
            <div className="left-content">
              <div
                data-aos={deviceType == 'desktop' ? "fade-up" : "fade-up"}
                data-aos-delay="200">
                <MainTitleContent text={items?.section_favorte?.title} theme='themeLight' gapBetween="6px">
                  <div
                    className="detailConteiner"
                    dangerouslySetInnerHTML={{ __html: items?.section_favorte?.description }}
                  />

                </MainTitleContent>
              </div>
              <ConservationStatus
                showParagraph={false}
                items={ConservationIcons}
                theme={'themeLight'}
                heading={'Conservation Status'}
                showCurrentStatus={false}
              >
              </ConservationStatus>
            </div>
          </div>


          {Object.keys(items).map(key => {
            const section = items[key];
            // ---  Section Expertsx
            if (key == 'experts') {
              return (
                <div key={key} className="right-content">
                  <ExpertsList items={section?.list}>
                    <MainTitleContent text={section?.title} customClass="d-left-m-center" theme='themeLight' gapBetween="16px">
                      <ParagraphLight para={section?.description} htmlPara={true} />
                    </MainTitleContent>
                  </ExpertsList>
                </div>
              )
            }
          }
          )}

        </section>
      </div>
    </div>
  )
}

export default Discover;