import { FETCH_ALL_CHAMBERS, FETCH_CHAMBERS_DETAILS } from "./type";
import { axiosCustom } from "../../utils/request";

export const fetchingAllChambers = () => async dispatch => {
    const response = await axiosCustom.get("pages/5514");
    const { data = false, status } = response;
    if (status === 200) {
        if (data) {
            dispatch({
                type: FETCH_ALL_CHAMBERS,
                items: data.acf,
                metaTitle: data?.page_meta?.meta_title,
                metaImage:data?.page_meta?.meta_image,
                metaDescription: data?.page_meta?.meta_description
            });
        }
    }
};

export const fetchingChamberDetails = (id) => async (dispatch) => {
        const response = await axiosCustom.get(`chambers/${id}`);
        const { data = false, status } = response;
        if (status === 200) {
            if (data) {
                dispatch({
                    type: FETCH_CHAMBERS_DETAILS,
                    items: data.acf,
                    metaTitle: data?.page_meta?.meta_title,
                    metaImage:data?.page_meta?.meta_image,
                    metaDescription: data?.page_meta?.meta_description
                });
            }
        }
    };