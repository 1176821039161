const PlayVideo = (props) => {
    return (
        <>
            <video muted autoPlay="autoplay" loop="loop" playsInline src={props.src} type="video/mp4">
                Sorry, your browser doesn't support videos.
            </video>
        </>
    );
};

export default PlayVideo;