import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import images from '../../../assets/images/images';
import Slider from "react-slick";
import { get } from 'lodash';
import MainTitleContent from '../../UI_Elements/MainTitleContent/MainTitleContent';
import ParagraphLight from '../../UI_Elements/ParagraphLight/ParagraphLight';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { checkDeviceType } from '../../../actions/CheckDevice';
import ParagraphDark from '../../UI_Elements/ParagraphDark/ParagraphDark';


const LongCardwithImageText = ({ items }) => {
    const dispatch = useDispatch();
    const removeHtmlTags = (html) => {
        const regex = /(<([^>]+)>)/gi;
        return html.replace(regex, '');
    };
    const sliderRef = useRef(null);
    const deviceType = useSelector((state) => state.checkDevice.deviceType);


    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(0);
        }
    }, []);

    useEffect(() => {
        dispatch(checkDeviceType());
      }, [dispatch]);

    var settings = {
        dots: false,
        infinite: false,
        prevArrow: <></>,
        nextArrow: <></>,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: Math.min(items?.list?.length, 3),
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 500, settings: { slidesToShow: 1.1 } },
            { breakpoint: 900, settings: { slidesToShow: 1.2 } },
            { breakpoint: 1500, settings: { slidesToShow: 2.2 } },
            { breakpoint: 1800, settings: { slidesToShow: 2.2 } },
            { breakpoint: 2500, settings: { slidesToShow: 3.2 } },
        ],
    };

    const getStatusColor = status => {
        switch (status.toUpperCase()) {
            case "VU":
                return "#c69735";
            case "NT":
                return "#276464";
            case "LC":
                return "#276464";
            case "EN":
                return "#c16a43";
            case "CR":
                return "#be4340";
            case "EW":
                return "black";
            case "EX":
                return "black";
            case "DD":
                return "#646464";
            case "NE":
                return "#646464";
            default:
                return "red";
        }
    };

    const getStatusName = name => {
        switch (name.toUpperCase()) {
            case "VU":
                return "VU - Vulnerable".replace("-", "");
            case "NT":
                return "NT - Near Threatened".replace("-", "");
            case "LC":
                return "LC - Least Concerned".replace("-", "");
            case "EN":
                return "EN - Endangered".replace("-", "");
            case "CR":
                return "CR - Critically Endangered".replace("-", "");
            case "EW":
                return "EW - Extinct in the wild".replace("-", "");
            case "EX":
                return "EX - Extinct".replace("-", "");
            case "DD":
                return "DD - Data Deficient".replace("-", "");
            case "NE":
                return "NE - Not Evaluated".replace("-", "");
            default:
                return;
        }
    };

    return (
        <>
            <div className="sliderWapper">
                <div className="details-header"
                >
                    <MainTitleContent 
                    text={items?.name} 
                    customClass="d-left-m-center" 
                    theme='themeDark' 
                    gapBetween="16px"
                    cardAnimationType={deviceType=='desktop'?"fade-up":""}
                    cardAnimationDuration={100}
                    >
                        <ParagraphDark para={items?.description} htmlPara={true} textColor={'Dark'}/>
                    </MainTitleContent>
                </div>
                <Slider {...settings}>
                    {items?.list?.map((item) => {
                        const status = get(item?.acf, "conservation_status", "");
                        const name = getStatusName(status);
                        return (
                            <div className="longCardParent" key={Math.random()}
                            data-aos={deviceType=='mobile'?"fade-up":""} data-aos-delay={100}
                            >
                                <div className="image" 
                                 >
                                    <img src={item?.acf?.images} alt="" />
                                </div>
                                <div className='longContent-body'>
                                    <div className="section-one" >
                                        <MainTitleContent
                                            text={item?.title?.rendered}
                                            alignTo="left"
                                            theme="themeLight"
                                            gapBetween="16px"
                                            cardAnimationType={deviceType=='desktop'?"fade-up":""}
                                            cardAnimationDuration={100}
                                        >
                                            <p>{removeHtmlTags(item?.content?.rendered)}</p>
                                        </MainTitleContent>
                                    </div>
                                    <div className="section-two" data-aos={deviceType=='desktop'?"fade-up":""} data-aos-delay={100}>
                                        <p className="left-icon">
                                            <span
                                                style={{
                                                    backgroundColor: getStatusColor(status),
                                                }}
                                            >
                                                {name?.slice(0, 2)}
                                            </span>
                                            &nbsp;&nbsp;
                                            {name?.slice(2)}
                                        </p>
                                    </div>
                                    <div className="section-three" data-aos={deviceType=='desktop'?"fade-up":""} data-aos-delay={100}>
                                        <MainTitleContent
                                            text="Did you know?"
                                            alignTo="left"
                                            theme="themeLight"
                                            gapBetween="16px"
                                            cardAnimationType={deviceType=='desktop'?"fade-up":""}
                                            cardAnimationDuration={100}
                                        >
                                            <p>{item?.acf?.did_you_know}</p>
                                        </MainTitleContent>
                                    </div>

                                    <div className="section-four">
                                        <MainTitleContent
                                            text="You can see them here."
                                            alignTo="left"
                                            theme="themeLight"
                                            gapBetween="16px"
                                            cardAnimationType={deviceType=='desktop'?"fade-up":""}
                                            cardAnimationDuration={100}
                                        ></MainTitleContent>

                                        <div className="data-list">
                                            {Array.isArray(item?.acf?.chambers) ? (
                                                item?.acf?.chambers.map((chamber, index) => (
                                                    <div key={chamber?.id} data-aos={deviceType=='desktop'?"fade-up":""} data-aos-delay={`${index}00`} >
                                                        <a href="#" className="chamberList">
                                                            <div className="image">
                                                                <img src={chamber?.acf?.icon} alt="" />
                                                            </div>
                                                            <ParagraphLight para={chamber?.title} htmlPara={true} />
                                                        </a>
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No chambers available.</p>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </>
    )
}

export default LongCardwithImageText;
