import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchingTC } from "../../actions/TermsAndConditions";
import PageMainHeadingContent from '../../components/UI_Elements/PageMainHeadingContent/PageMainHeadingContent';
import TitleBlue from '../../components/UI_Elements/TitleBlue/TitleBlue';
import { useNavigate } from "react-router-dom";
import ParagraphDark from "../../components/UI_Elements/ParagraphDark/ParagraphDark";
import { Helmet } from 'react-helmet';
import { setActiveUrl } from "../../actions/NavigateUrls";

const TermsAndConditions = React.memo(() => {
  const navigate = useNavigate();
  let items = useSelector((state) => state.termsandconditions.items);
  const metaTitle = useSelector(state => state.termsandconditions.metaTitle);
  const metaImage = useSelector(state => state.termsandconditions.metaImage);
  const metaDescription = useSelector(state => state.termsandconditions.metaDescription);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveUrl('/terms-and-conditions'));
  }, [])


  useEffect(() => {
    dispatch(fetchingTC());
  }, [dispatch]);

  console.log('items == ', items);
  return (
    <>
     <Helmet>
<meta property="og:title" content={metaTitle}/>
<meta property="og:image" content={metaImage} />
<meta property="og:image:secure_url" content={metaImage}/>
<meta property="og:image:type" content="image/png" />
<meta property="og:image:width" content="400" />
<meta property="og:image:height" content="300" />
<meta property="og:image:alt" content="" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className="terms-condition-container dynamicMinHeight mainHeadingTopSpace container ">
        <div style={{ marginLeft: "8px", marginRight: "8px" }}>
          <PageMainHeadingContent heading={"Term and Conditions"} theme='themeLight' gapBetween="26px">
            <p>{items?.section_attractions?.description}</p>
          </PageMainHeadingContent>
        </div>
        <ParagraphDark para={items.rendered} htmlPara={true} />
      </div>
    </>
  );
});

export default TermsAndConditions;
