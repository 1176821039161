import {
    FETCH_POSTS,
    FETCH_POSTS_DETAIL,
    FETCH_PLEDGE_OPTION
} from "../actions/attraction/type";

const initialState = {
    items: [],
    data: [],
    pleadgeOption:null,
    metaImage:"",
    metaTitle:"",
    metaDescription:""
};

const AttractionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POSTS:
            return {
                ...state,
                items: action.items,
                metaTitle:action.metaTitle,
                metaImage:action.metaImage,
                metaDescription:action.metaDescription
            };
        case FETCH_POSTS_DETAIL:
            return {
                ...state,
                data: action.items,
                metaTitle:action.metaTitle,
                metaImage:action.metaImage,
                metaDescription:action.metaDescription
            };
        case FETCH_PLEDGE_OPTION:
            return {
                ...state,
                pleadgeOption: action.items,
            };
        default:
            return state;
    }
};
export default AttractionsReducer
