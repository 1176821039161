import React, { useState, useEffect } from "react";
import rightArrow from "../../assets/images/svg/arrow-right-blue.svg";
import leftArrow from "../../assets/images/svg/arrow-left-blue.svg";
import logoMobile from "../../assets/images/svg/logo-mobile.svg";
import scanBtn from "../../assets/images/svg/btn-scan.svg";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import DancingArrow from '../../components/DancingArrow/DancingArrow';

import icons from '../../assets/icons/icons';
import {navigateToPage} from "../../CustomHooks/navigateToRoute";

const NativeHeader = (props) => {
    const [windowWidth, setWindowWidth] = useState(null);
    const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);

    const navigate = useNavigate();


    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        if (windowWidth == null) {
            handleResize();
        }
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    if (windowWidth >= 970) {
        return <Header />;
    } else {
        return (
            <div className='mobile-header'>
                <div className="container">
                { props.currentUrl?.includes('/scan-fish') ?(
                    <div className="nav-menu">
                        <div className="goToHome">
                        <img src={icons.icon_home} alt="" className="menu-btn" id="menuBtn" onClick={() => 
                            navigateToPage('/', navigate)
                            } />
                        </div>
                        <div className="customLink">
                            <p className="HeaderLink" onClick={()=>
                                navigateToPage('/fish-library', navigate)
                                }>View Fish Library</p>
                            <DancingArrow delay={0} arrowAlingRight={true} />
                        </div>
                    </div>

                    ):(
                        <div className="nav-menu">
                        <div className="customLink">
                            <DancingArrow delay={0} arrowAlingRight={false} />
                            <p className="HeaderLink" onClick={()=> 
                                navigateToPage('-1', navigate)
                                }>Back</p>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        );
    }
};

export default NativeHeader;
