import React, { useState, useEffect } from 'react';
import Button from '../../components/UI_Elements/Button/Button';
import MainTitleContent from '../../components/UI_Elements/MainTitleContent/MainTitleContent';
import ParagraphLight from '../../components/UI_Elements/ParagraphDark/ParagraphDark';
import PageMainHeadingContent from '../../components/UI_Elements/PageMainHeadingContent/PageMainHeadingContent';
import { useNavigate } from "react-router-dom";
import { navigateToPage } from '../../CustomHooks/navigateToRoute';
import images from "../../assets/images/images";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import { setActiveUrl } from "../../actions/NavigateUrls";
import { checkDeviceType } from '../../actions/CheckDevice';

const PageNotFound = () => {
  const navigate = useNavigate();
  const deviceType = useSelector((state) => state.checkDevice.deviceType);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveUrl('/404'));
  }, [])


  useEffect(() => {
    dispatch(checkDeviceType());
  }, [dispatch, deviceType]);

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Page not Found | Atlantis Aquaventure" />
        <meta property="og:image" content="" />
        <meta property="og:image:secure_url" content="" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="" />
        <title>Page not Found | Atlantis Aquaventure</title>
        <meta name="description" content="Page not Found, Atlantis Aquaventure" />
      </Helmet>

      <div className='page-not-found-container'>
        <img src={images.pageNotFound} alt=""
          style={deviceType === 'mobile' ? { height: '190px' } : { height: '475px' }}
        />
        {/* <PageMainHeadingContent heading="Page not found" theme='themeLight' gapBetween="16px"/>
      */}
        <MainTitleContent text={'Page not found'} customClass="d-m-center" theme='themeDark' gapBetween="16px">
          <ParagraphLight htmlPara={true} para="This page has been submerged underwater." />
        </MainTitleContent>
        <div style={{ width: "200px" }}>
          <Button customClass="DrakHoverButton" text="Visit Homepage" buttonType="btn-outlined" textColor="dark" onClick={() => navigateToPage('/', navigate)} />
        </div>
      </div>
    </>
  )
}

export default PageNotFound