import React from "react";
import PageMainHeadingContent from "../UI_Elements/PageMainHeadingContent/PageMainHeadingContent";
import BlueLinkTitle from "../UI_Elements/BlueLinkTitle/BlueLinkTitle";
import images from "../../assets/images/images";
import Button from "../UI_Elements/Button/Button";

const MapwithStarfish = ({ bgImage, showDownloadLink, buttonText, onClick, heading, paragraph, linkClick }) => {
    return (
            <div className="lost-chamber-section" style={{ backgroundImage: `url(${bgImage})`}}>
                <div className="container">
                    <div className="lost-chambers">
                        <div className="left-content">
                            <PageMainHeadingContent heading={heading} theme="themeLight" gapBetween="16px">
                                <p>{paragraph}</p>
                                <div className="desktop-buttons">
                                {showDownloadLink && <BlueLinkTitle text="Download the map" onClick={linkClick}/>}
                                    <div href="#" style={{ display: "flex", width: "190px" }} >
                                        <Button
                                            text="Explore all Chambers"
                                            buttonType="btn-outlined" 
                                            textColor="dark"
                                            onClick={onClick}
                                            >
                                        </Button>
                                    </div>
                                </div>
                            </PageMainHeadingContent>
                        </div>
                        <div className="right-image">
                            <img className="map-image" src={images.download_map_image} alt="" />
                            <img className="star-image" src={images.start_fish_image} alt="" />
                        </div>
                        <div className="mb-buttons">
                          {showDownloadLink && <BlueLinkTitle text="Download the map" />}
                            <div href="#" style={{ display: "flex", width: "190px" }} >
                              <Button {...buttonText} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-overlay"></div>
            </div>
    )
}

export default MapwithStarfish