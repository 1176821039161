import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import images from '../../assets/images/images';
import MainTitleContent from '../../components/UI_Elements/MainTitleContent/MainTitleContent';
import ParagraphDark from '../../components/UI_Elements/ParagraphDark/ParagraphDark';
import { ImageIconDummy, galleryData } from "../../utils/dummy";
import BlueIconwithText from '../../components/BlueIconwithText/BlueIconwithText';
import GridCarousel from '../../components/Carousels/GridCarousel/GridCarousel';
import { fetchingAllFishes } from '../../actions/FishLibrary';
import { fetchingAnimalsDetails } from '../../actions/AllAnimals';
import { fetchingFishesHistory } from '../../actions/FishHistory';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { setActiveUrl } from "../../actions/NavigateUrls";

const FishLibrary = () => {
  const [isFishScanned, setIsFishScanned] = useState(false);
  const [categoryIdToLoad, setCategoryIdToLoad] = useState(null);
  const fishesList = useSelector(state => state.fishLibrary.allFishes)
  const filteredFishList = useSelector(state => state.allanimals.animalDetailsResponseData);
  const fishHistoryList = useSelector(state => state.fishHistory.detectedFishData);

  const metaTitle = useSelector(state => state.fishLibrary.metaTitle);
  const metaImage = useSelector(state => state.fishLibrary.metaImage);
  const metaDescription = useSelector(state => state.fishLibrary.metaDescription);


  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveUrl('/fish-library'));
  }, [])


  useEffect(() => {
    // fetching all fish categories
    dispatch(fetchingAllFishes());
    dispatch(fetchingFishesHistory(localStorage.getItem("loginData")));

  }, [dispatch]);

  useEffect(() => {
    if (fishesList?.animal_categories?.length > 0) {
      // get the category id to list
      setCategoryIdToLoad(fishesList.animal_categories[0].term_id);
    }
  }, [fishesList]);

  useEffect(() => {
    if (categoryIdToLoad !== null) {
      dispatch(fetchingAnimalsDetails(categoryIdToLoad));
    }
  }, [categoryIdToLoad, dispatch]);

  useEffect(() => {
    if (fishHistoryList?.data?.length > 0) {
      // get the fishes history of detected fishes
      dispatch(fetchingFishesHistory(localStorage.getItem("loginData")));
    }
  }, [dispatch]);



  const handleCategorySelection = (id) => {
    setCategoryIdToLoad(id);
  }
  const getDataFiltered = (items) => {

    const actionsData = items?.map(item => ({
      id: item?.id,
      image: item?.acf?.images[0],
      title: item?.title?.rendered,
    }));
    return actionsData;
  }

  return (
    <>
     <Helmet>
<meta property="og:title" content={metaTitle}/>
<meta property="og:image" content={metaImage} />
<meta property="og:image:secure_url" content={metaImage}/>
<meta property="og:image:type" content="image/png" />
<meta property="og:image:width" content="400" />
<meta property="og:image:height" content="300" />
<meta property="og:image:alt" content="" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className='fish-library-container dynamicMinHeight'>
        <MainTitleContent text={'Fish Library'} customClass='d-m-center' theme='themeDark' gapBetween="6px">
          <ParagraphDark para={'Discover and unlock all species!'} htmlPara={true} />
        </MainTitleContent>

        <div className='fish-list-category '>
          {fishesList?.animal_categories?.map((category, index) => (
             <div 
             key={index}
             data-aos="fade-up"
             data-aos-delay={`${index}00`}
             className="blue-icon-card"
             >
            <BlueIconwithText
              activeId={categoryIdToLoad}
              key={category?.term_id}
              term_id={category?.term_id}
              image={category?.acf?.icon}
              title={category?.name}
              initialLoadingCategory={categoryIdToLoad}
              onClick={() => handleCategorySelection(category?.term_id)}
              animationType="fade-up"
              animationDuration={`${index}00`}
            />
            </div>
          ))}

        </div>
        {filteredFishList?.list?.length > 0 &&
          <div className='fish-list'>
            <GridCarousel
              sliderData={getDataFiltered(filteredFishList?.list)}
              activeData={fishHistoryList?.data}
              navigateRoute='/scan-fish/'
              responsiveSettings={[
                { breakpoint: 500, settings: { slidesToShow: 2 } },
                { breakpoint: 900, settings: { slidesToShow: 2 } },
                { breakpoint: 1500, settings: { slidesToShow: 2 } },
                { breakpoint: 1800, settings: { slidesToShow: 2 } },
                { breakpoint: 2500, settings: { slidesToShow: 2 } },
              ]} />

          </div>
        }
      </div>
    </>
  )
}

export default FishLibrary