import React, { useEffect, useState } from 'react'
import MainTitleContent from '../../UI_Elements/MainTitleContent/MainTitleContent';
import Button from '../../UI_Elements/Button/Button';
import {
    FacebookShareButton, TwitterShareButton, TwitterIcon, LinkedinIcon, FacebookIcon,
    FacebookMessengerIcon, LinkedinShareButton, WhatsappShareButton, WhatsappIcon
} from 'react-share';
import { fetchPledgeOptions } from "../../../actions/attraction";
import { useDispatch, useSelector } from "react-redux";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SocialShare = (props) => {
    const dispatch = useDispatch();
    let pleadgeOption = useSelector((state) => state.attraction.pleadgeOption);
    const [activeKey, setActiveKey] = useState(0);
    const [activeImageUrl, setActiveImageUrl] = useState( null);


    useEffect(() => {
        dispatch(fetchPledgeOptions);
        setActiveImageUrl(pleadgeOption?.images[0])
    }, [pleadgeOption]);


    const handleImageClick = (index, item) => {
        console.log('clicked');
        setActiveKey(index);
        setActiveImageUrl(item)

    };
    const settings = {
        dots: false,
        infinite: false,
        prevArrow: <></>,
        nextArrow: <></>,
        autoplay: false,
        autoplaySpeed: 0,
        slidesToScroll: 1,
        slidesToShow: 1,
        responsive: [
            { breakpoint: 500, settings: { slidesToShow: 1.05 } },
            { breakpoint: 900, settings: { slidesToShow: 1.1 } },
            { breakpoint: 1500, settings: { slidesToShow: 2 } },
            { breakpoint: 1800, settings: { slidesToShow: 2 } },
            { breakpoint: 2500, settings: { slidesToShow: 2 } },
        ], // Adjust the value to show 1.1 images
    };

    return (
        <div className='socialShareContainer'
            style={{overflowY: scroll }}
        >
            <div className='actionContainer'>
                <MainTitleContent text={pleadgeOption?.title ? pleadgeOption?.title : 'Select  Your Pledge'} customClass="d-m-left" theme='themeLight' gapBetween="1px">
                </MainTitleContent>
                <p className='instruction'>{pleadgeOption?.description}</p>
            </div>

            <div style={{ width: '100%' }} className="pledgeSlider">
                {pleadgeOption?.images?.length > 0 && (
                    <Slider {...settings}>
                        {pleadgeOption?.images.map((item, index) => (
                            <div
                                className={`cardImg ${activeKey == index ? 'active' : 'nonActive'}`}
                                key={index} onClick={() => handleImageClick(index, item)}>
                                <img src={item ? item : undefined} alt='' />
                            </div>
                        ))}
                    </Slider>
                )}
            </div>

            <p className='info'>Please select the platform on which you would like to share this.</p>
            <div className='shareActionContainer'>
                <FacebookShareButton url={activeImageUrl} quote="activeImageUrl"
                    media={[activeImageUrl]}
                >
                    <FacebookIcon size={50} round />
                </FacebookShareButton>

                <WhatsappShareButton url={activeImageUrl}>
                    <WhatsappIcon size={50} round />
                </WhatsappShareButton>
                {/* <TwitterShareButton url={'http://theoceantales.com/#/home'} title="hello" via="string">
                    <TwitterIcon />
                </TwitterShareButton>

                <LinkedinShareButton url={'http://theoceantales.com/#/home'} title="hello" summary="this is dummy content">
                    <LinkedinIcon />
                </LinkedinShareButton> */}
            </div>
        </div>
    );
};

export default SocialShare;