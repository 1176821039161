import { FETCH_POSTS } from "../actions/Faq/type";

const initialState = {
    items: [],
    pageTitle:[],
    metaImage:"",
metaTitle:"",
    metaDescription:""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POSTS:
            return {
                ...state,
                ...action,
            };

        default:
            return state;
    }
};
