import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkDeviceType } from '../../actions/CheckDevice';
import { fetchingAllChambers } from '../../actions/AllChambers';
import ImageBannerWithContent from '../../components/UI_Elements/ImageBannerWithContent/ImageBannerWithContent';
import ImageBannerWithGradient from '../../components/UI_Elements/ImageBannerWithGradient/ImageBannerWithGradient';
import MainTitleContent from '../../components/UI_Elements/MainTitleContent/MainTitleContent';
import MapwithStarfish from '../../components/MapwithStarFish/MapwithStarfish';
import CardIconText from '../../components/CardIconText/CardIconText';
import ParagraphDark from '../../components/UI_Elements/ParagraphDark/ParagraphDark';
import { navigateToPage } from '../../CustomHooks/navigateToRoute';
import { Helmet } from 'react-helmet';
import { setActiveUrl } from "../../actions/NavigateUrls";

const Chambers = () => {
  const deviceType = useSelector((state) => state.checkDevice.deviceType);
  const allChambersList = useSelector((state) => state.allChambers.items);

  const metaTitle = useSelector(state => state.allChambers.metaTitle);
  const metaImage = useSelector(state => state.allChambers.metaImage);
  const metaDescription = useSelector(state => state.allChambers.metaDescription);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setActiveUrl('/chambers'));
  }, [])

  useEffect(() => {
    dispatch(checkDeviceType());
    dispatch(fetchingAllChambers());
  }, [dispatch]);

  const renderBannerContent = () => {
    if (!allChambersList || !allChambersList.section_banner) {
      return null;
    }

    const { image, title, description } = allChambersList.section_banner;

    if (deviceType === 'desktop') {
      return (
        <ImageBannerWithContent
          customClass="dolphinBay"
          bannerImage={image}
          heading={title}
          subHeading={description?.rendered}
          imgAnimationType="zoom-out"
          imgAnimationDuration={100}
          descAnimationType={deviceType == 'desktop' ? "fade-up" : "fade-up"}
          descAnimationDuration={100}
          titleAnimationType={deviceType == 'desktop' ? "fade-up" : "fade-up"}
          titleAnimationDuration={100}
          textColor={'light'}
        />
      );
    }

    const onclickbottomBtn = () => {
      navigateToPage('/chambers', navigate)
    }
    return (
      <div>
        <ImageBannerWithGradient
          // Please pass 'noBgImage' as custom class to remove the backgrounf image and gradient.
          customClass="withGradient "
          customStyle={{ backgroundImage: `linear-gradient(#020A1D  0%,transparent 20%, transparent 60%, #020A1D  100%), url(${image})` }}
          showDownloadLink={true}
          showMap={true}
          showButton={false}
          downloadBtnText='Download the map'
          bottomBtnText='Explore all Chambers'
          onclickbottomBtn={onclickbottomBtn}
        >
          <div className='headings'
          data-aos={deviceType == 'desktop'?"fade-up": "fade-up"} data-aos-delay={100}
          >
            <MainTitleContent
              text={title}
              customClass="d-m-center compactHeading"
              theme="themeWhite"
              gapBetween="16px"
            >
              <ParagraphDark para={description?.rendered} htmlPara={true} textColor={'light'}/>
            </MainTitleContent>
          </div>
        </ImageBannerWithGradient>
      </div>
    );
  };



  const renderChamberList = () => {
    if (!allChambersList || !allChambersList.chambers) {
      return null;
    }

    return (
      <div className="all-chambers-list container">
        {allChambersList.chambers.map((item, index) => (
          <div data-aos="fade-up" key={index} data-aos-delay={`${index}00`}>
            <CardIconText
              customClass="hoverAnimationCard"
              key={item?.ID}
              text={item?.post_title}
              imgSrc={item?.acf?.icon}
              onClick={() => navigateToPage(`/chambers/details/${item.ID}`, navigate)}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:secure_url" content={metaImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className="all-chambers-container dynamicMinHeight">
        {renderBannerContent()}
        {renderChamberList()}
      </div>
    </>
  );
};

export default Chambers;
