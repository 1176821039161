import React from "react";

const BlueLinkTitle = (props) => {
  return (
    <a className={`Blue-Linktitle ${props.customClass}`} onClick={props.onClick} data-aos={props.animationType}  data-aos-delay={props.animationDelay}>
      {props.text}
    </a>
  )
}

export default BlueLinkTitle;
