import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logoMobile from "../../assets/images/svg/logo-mobile.svg";

// import logoMobilePrimary from "../../assets/images/svg/logo-mobile-primary.svg";
// import logoMobileWhite from "../../assets/images/svg/logo-mobile-white.svg";

import logoMobilePrimary from "../../assets/images/png/wordlogoblue.png";
import logoMobileWhite from "../../assets/images/png/WorldReversedLogo.png";

import useScrollDirection from '../../CustomHooks/useScrollDirection';

const DesktopHeader = () => {
  const { scrollDirection, load } = useScrollDirection();
  const [ScrollStateClass, setScrollStateClass] = useState(null);

  const [shouldHaveBackground, setShouldHaveBackground] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (load) {
      setScrollStateClass('on-load');
    } else {
      setScrollStateClass(scrollDirection === 'down' ? 'scroll-down' : 'scroll-up');
    }
  }, [load, scrollDirection]);


  useEffect(() => { 
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 50;

      if (scrollPosition >= scrollThreshold) {
        setShouldHaveBackground(true);
      } else {
        setShouldHaveBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const currentPath = location.pathname;
  const isHomePage = currentPath === "/";
  const isInnerPage = !isHomePage;

  return (
    <div className={`desktop-header  ${isInnerPage || (isHomePage && shouldHaveBackground) ? "bg-color-for-inner-pages" : ""}  headers  ${ScrollStateClass}`}>
      <div className="container">
        <div className="nav-menu">
          <div className={`menu-list ${isInnerPage || (isHomePage && shouldHaveBackground) ? "inner-pages-link" : "home-pages-link"}`}>
            <ul className="flex ">
              <li>
                <Link to='/' className={currentPath === "/" ? "active" : ""}>
                  Home
                </Link>
              </li>
              <li>
                <Link to='/attractions' className={currentPath === "/attractions" ? "active" : ""}>
                  Our Attractions
                </Link>
              </li>
              <li>
                <Link to='/experiences' className={currentPath === "/experiences" ? "active" : ""}>
                  Experiences
                </Link>
              </li>
            </ul>
          </div>
          <div className={`logo ${isInnerPage || (isHomePage && shouldHaveBackground) ? "inner-pages-logo" : "home-pages-logo"}`}>
            <Link to="/">
            {isInnerPage || (isHomePage && shouldHaveBackground) ? 
              <img src={logoMobilePrimary} alt="" className="logo" />
              :
              <img src={logoMobileWhite} alt="" className="logo" />
            }
            </Link>
          </div>
          <div className={`menu-list ${isInnerPage || (isHomePage && shouldHaveBackground) ? "inner-pages-link" : "home-pages-link"}`}>
            <ul className="flex">
              <li>
                <Link to='/discover' className={currentPath === "/discover" ? "active" : ""}>
                  Discover
                </Link>
              </li>
              <li>
                <Link to='/care-and-conservation' className={currentPath === "/care-and-conservation" ? "active" : ""}>
                  Care and Conservation
                </Link>
              </li>
              <li>
                <Link to='/faq' className={currentPath === "/faq" ? "active" : ""}>
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopHeader;
