import React, { useState, useEffect} from 'react';
import AccordionItem from '../AccordionItem/AccordionItem';
import TitleBlue from '../UI_Elements/TitleBlue/TitleBlue';
import { checkDeviceType } from '../../actions/CheckDevice';
import { useDispatch, useSelector } from 'react-redux';


const Accordion=({ panels}) =>{
  const [activeTab, setActiveTab] = useState('00');
  const dispatch = useDispatch();
  const deviceType = useSelector((state) => state.checkDevice.deviceType);

  useEffect(() => {
    dispatch(checkDeviceType());
  }, [dispatch]);


  const activateTab = (index) => {
    console.log('Activating index', index);
    console.log('Activating --->', activeTab);

    setActiveTab((prevActiveTab) =>
      prevActiveTab === index ? -1 : index
    );
  };

  return (
    <div className="accordion" role="tablist"
    data-aos={deviceType == 'mobile'?"fade-up": ""} 
    data-aos-delay={0}
    >

      {panels?.map((panel, index) => (
          <AccordionItem
            data-aos={deviceType == 'desktop'?"fade-up": ""} 
            data-aos-delay={`${index}00`}
            key={index}
            activeTab={activeTab}
            index={index}
            item={panel}
            id={panel.ID}
            activateTab={() => activateTab(index)}
          />
      ))}
    </div>
  );
}
export default Accordion;