import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkDeviceType } from '../../../actions/CheckDevice';

const ExpertsList = (props) => {
    const deviceType = useSelector((state) => state.checkDevice.deviceType);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(checkDeviceType());
    }, [dispatch, deviceType]);
    const { items } = props;
    console.log('items received EXPERTS === ', items);
    return (
        <div className="ExpertList">
            <div className="expertHead"
                data-aos="fade-up"
                data-aos-delay={100}
            >
                {props.children}
            </div>
            <div className="gridContainer">
                {items.map((entry, index) => (
                    <div
                        key={entry.ID}
                        className={`gridItem 
                             ${index === items.length - 1 && deviceType == 'mobile' && items.length > 2 ? '' : ''}
                            `}
                        data-aos="fade-up" data-aos-delay={`${index}50`}
                    >
                        <img className="image" src={entry.image} alt="" />
                        <div>
                            <p className="expertName">{entry.title}</p>
                            <p className="expertPosition">{entry.designation}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExpertsList;
