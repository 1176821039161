import {
    FETCH_TC,
} from "../actions/TermsAndConditions/type";

const initialState = {
    items: [],
    metaTitle: "",
    metaDescription: ""
};

const TermsAndConditionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TC:
            return {
                ...state,
                items: action.items,
                metaTitle: action.metaTitle,
                metaImage:action.metaImage,
                metaDescription: action.metaDescription
            };
        default:
            return state;
    }
};
export default TermsAndConditionsReducer
