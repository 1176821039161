import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { fetchingCareConservation } from "../../actions/Conservation";
import TakePledge from '../../components/UI_Elements/TakePledge/TakePledge';
import icons from '../../assets/icons/icons';
import Button from '../../components/UI_Elements/Button/Button';
import { ImageIconDummy, galleryData } from "../../utils/dummy";
import GridItemIcon from '../../components/GridItemIcon/GridItemIcon';
import MainTitleContent from '../../components/UI_Elements/MainTitleContent/MainTitleContent';
import ParagraphDark from '../../components/UI_Elements/ParagraphDark/ParagraphDark';
import ConservationStatus from '../../components/ConservationStatus/ConservationStatus';
import { ConservationIcons } from "../../utils/dummy";
import Card from '../../components/UI_Elements/Card/Card';
import ParagraphLight from '../../components/UI_Elements/ParagraphLight/ParagraphLight';
import FloatingDotImage from "../../components/FloatingDotImage/FloatingDotImage";
import images from '../../assets/images/images';
import CardCarousel from '../../components/Carousels/CardCarousel/CardCarousel';
import ActivitySlider from '../../components/ActivitySlider/ActivitySlider';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { checkDeviceType } from '../../actions/CheckDevice';
import { fetchPledgeOptions } from "../../actions/attraction";
import SocialShare from '../../components/Modals/SocialShare/SocialShare';
import { Helmet } from 'react-helmet';
import { setActiveUrl } from "../../actions/NavigateUrls";


const CareAndConservation = () => {
  const items = useSelector(state => state.careandconservation.items);
  let pleadgeOption = useSelector((state) => state.attraction.pleadgeOption);
  const deviceType = useSelector((state) => state.checkDevice.deviceType);

  const metaTitle = useSelector(state => state.careandconservation.metaTitle);
  const metaImage = useSelector(state => state.careandconservation.metaImage);
  const metaDescription = useSelector(state => state.careandconservation.metaDescription);


  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(setActiveUrl('/care-and-conservation'));
  }, [])

  useEffect(() => {
    if (visible) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
    return () => {
      document.body.classList.remove("mobile-menu-visible");
    };
  }, [visible]);



  useEffect(() => {
    dispatch(checkDeviceType());
    dispatch(fetchingCareConservation());
    dispatch(fetchPledgeOptions());
  }, [dispatch, deviceType]);


  const show = () => {
    setVisible(true);
  }

  const hide = () => {
    setVisible(false);

  }
  const CustomCloseIcon = () => {
    return <span>X</span>; // Custom close icon element or component
  };



  const getOurActionsData = (items) => {
    const actionsData = items?.map(item => ({
      image: item?.image,
      title: item?.description?.english.match(/<span>(.*?)<\/span>/)?.[1] || "",
    }));

    return actionsData;
  }

  const openLink = (link) => {
    console.log('openLinkkkk', link)
    window.open(link, "_blank")
  }

  const getGridData = (items) => {
    const gridData = items?.map(item => ({
      icon: item?.image,
      text: item?.keyword?.english,
    }));
    return gridData;
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:secure_url" content={metaImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className='care-conservation-container dynamicMinHeight'>
      {items?.section1?(
        <TakePledge
          mediaType={'video'}
          title={items?.section1?.textall?.heading?.english}
          text={items?.section1?.textall?.description?.english}
          mediaSrc={items?.section1?.medias[0]?.media}
          pledges={items?.section1?.pledges}
          cardAnimationType={deviceType == 'desktop' ? "fade-up" : "fade-up"}
          cardAnimationDuration={100}
          textColor={'light'}
        >
          <Button text={items?.section1?.cta?.title}
            onClick={show}
            pledgeIcon={icons.icon_heart_handshake} buttonType='btn-white-outlined' textColor='light'></Button>
        </TakePledge>
        ):(null)}

        {items?.section2?(
        <div className='care-section-2'
            data-aos={deviceType=='mobile'?"fade-up":""} 
            data-aos-delay="100"
        >
          <div className='container'>
            <ActivitySlider
              activeClick={false}
              slideData={getOurActionsData(items?.section2?.pairs)}
              customClassForTitle="d-m-left"
              customClassForCardName="d-m-center"
              buttonText={items?.section2?.cta?.title}
              onClick={() => openLink('https://www.aquaventureworld.com/atlantis-atlas-project')}
              btnWidth="245px"
              title={items?.section2?.textall?.heading?.english}
              Paragraph={items?.section1?.textall?.description?.english}
              slidesToShow={1.2}
              showButton={true}
              responsiveSettings={[
                { breakpoint: 500, settings: { slidesToShow: 1.2 } },
                { breakpoint: 900, settings: { slidesToShow: 2.2 } },
                { breakpoint: 1500, settings: { slidesToShow: 4 } },
                { breakpoint: 1800, settings: { slidesToShow: 4 } },
                { breakpoint: 2500, settings: { slidesToShow: 4 } },
              ]} />
          </div>
        </div>
        ):(null)}

        <div className='container care-section-3'>
          <MainTitleContent
            text={items?.section3?.textall?.heading?.english}
            customClass='d-left-m-center' theme='themeDark'
            gapBetween="16px"
            cardAnimationType={deviceType == 'desktop' ? "fade-up" : "fade-up"}
            cardAnimationDuration={100}
          >
            <ParagraphDark para={items?.section3?.textall?.description?.english} htmlPara={true} />
          </MainTitleContent>
          <GridItemIcon elements={getGridData(items?.section3?.pairs)} itemsInRow='3' textColor="dark" />
        </div>
        {deviceType == 'mobile' ? (
          <div className='care-section-4'
          >
            {items?.section4?(
            <div
            data-aos={deviceType=='mobile'?"fade-up":""} 
            data-aos-delay="100"
            >
            <Card
              title={items?.section4?.textall?.heading?.english}
              text={items?.section4?.textall?.description?.english}
              imgSrc={items?.section4?.medias[0]?.media}
              btnText={items?.section4?.cta?.title}
              onClick={() => openLink(items?.section4?.cta?.link)}
              positionToBottomWeb={true}
              cardAnimationType={deviceType == 'desktop' ? "fade-up" : ""}
              cardAnimationDuration={100}
            >
            </Card>
            </div>
            ):(null)}
            <ConservationStatus
              showParagraph={true}
              items={ConservationIcons}
              theme={'themeLight'}
              heading={items?.section_conservation_1?.title}
              showCurrentStatus={false}
              layoutClass="dynamic-grid"
            >
              <ParagraphLight para={items?.section_conservation_1?.description} htmlPara={true} />
            </ConservationStatus>
          </div>
        ) : (
          <div className='care-section-4-tablet setBackground50 ' style={{
            backgroundImage: `url(${items?.section4?.medias[0]?.media})`,
            backgroundSize: '50% 150%', /* Display only 50% of the height, while maintaining full width */
            backgroundRepeat: 'no-repeat',
            backgroundPositionY: 'center'
          }}>

            
            <div className='container layoutContainer'
            data-aos={deviceType== 'desktop'?"fade-up":"fade-up"} data-aos-delay="100"
            >
              <Card
                title={items?.section4?.textall?.heading?.english}
                text={items?.section4?.textall?.description?.english}
                imgSrc={''}
                btnText={items?.section4?.cta?.title}
                onClick={() => openLink(items?.section4?.cta?.link)}
                positionToBottomWeb={true}
                cardAnimationType={deviceType == 'desktop' ? "fade-up" : "fade-up"}
                cardAnimationDuration={100}
              >
              </Card>
              <ConservationStatus
                showParagraph={true}
                items={ConservationIcons}
                theme={'themeLight'}
                heading={items?.section_conservation_1?.title}
                showCurrentStatus={false}
                layoutClass="dynamic-grid"
              >
                <ParagraphLight para={items?.section_conservation_1?.description} htmlPara={true} />
              </ConservationStatus>
            </div>

          </div>

        )
        }




        <div className='care-section-5 container'>
          <div className="care-section-5-main">
            <MainTitleContent
              text={items?.section6?.textall?.heading?.english}
              customClass='d-left-m-center'
              theme='themeDark'
              gapBetween="24px"
              cardAnimationType={deviceType == 'desktop' ? "fade-up" : "fade-up"}
              cardAnimationDuration={100}
            >
              <ParagraphDark para={items?.section6?.textall?.description?.english} htmlPara={true} />
            </MainTitleContent>
          </div>

          <FloatingDotImage contentItems={items?.section6?.steps} />
        </div>
        <div className='care-section-6 bg-white'
        data-aos={deviceType=='mobile'?"fade-up":""} 
        data-aos-delay="100"
        >
          <div className='care-section-6-wrap container'
          data-aos={deviceType=='desktop'?"fade-up":""}
          data-aos-delay={100}
          >
            <div className='association-images-wrap'
             >
              <img src={items?.section_conservation?.image_one} alt='' />
              <img src={items?.section_conservation?.image_two} alt='' />
            </div>
            <div className='association-content-wrap'
             >
              <MainTitleContent 
              text={items?.section_conservation?.title} 
              customClass='d-left-m-center' 
              theme='themeLight' 
              gapBetween="14px"
               >
                <ParagraphLight para={items?.section_conservation?.description} htmlPara={true} />
              </MainTitleContent>
              <div style={{ maxWidth: '150px', margin: '0 auto' }} className='association-btn-wrap'>
                <Button text='Know More' buttonType='btn-outlined' textColor='dark'
                  onClick={() => openLink('https://www.atlantis.com/-/media/atlantis/dubai/atp/resort/pdfs/EnvironmentalSustainability.pdf?rev=f3e0d7a1e9754415a06db84f0ae11a51')}></Button>
              </div>
            </div>
          </div>
        </div>

        <Rodal visible={visible} onClose={hide}
          showCloseButton={true}
          width={deviceType === 'desktop' ? 730 : window.innerWidth}
          height={deviceType == 'desktop' ? 640 : 'auto'}
          className="pledgeModal"
          customStyles={{ backgroundColor: '#fff' }}
          animation='slideUp'
          //closeButton={ icons.modal_close_Icon} // Use the custom close icon component
          closeIcon={<CustomCloseIcon />}
        >
          {/* <PledgeForm onClose={hide} onSubmit={submitData} /> */}
          <SocialShare />
          {/* <ScanResult/> */}
        </Rodal>


      </div>
    </>
  )
}

export default CareAndConservation