import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NumberCard from "../Carousels/NumberCardCarousels/NumberCardCarousel";
import FishDetectedCount from "../FishDetectedCount/FishDetectedCount";
import BlueLinkTitle from "../UI_Elements/BlueLinkTitle/BlueLinkTitle";
import { checkDeviceType } from '../../actions/CheckDevice';
import { useDispatch, useSelector } from 'react-redux';
import { navigateToPage } from "../../CustomHooks/navigateToRoute";
import { fetchingFishesHistory } from '../../actions/FishHistory';
import { setUserData } from "../../actions/UserData";

const MobileBanner = ({ items }) => {
  const deviceType = useSelector((state) => state.checkDevice.deviceType);
  const fishHistoryList = useSelector(state => state.fishHistory.detectedFishData);


  const [loginData, setLoginData] = useState(localStorage.getItem('loginData'));
  const [speciesCount, setSpeciesCount] = useState(null);
  const loginDataFromStorage = localStorage.getItem('loginData');

  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    // setLoginData(localStorage.getItem('loginData'));
    dispatch(checkDeviceType());
    if(loginData){
      dispatch(fetchingFishesHistory(loginData));
    }
  }, [dispatch, loginData]);

  useEffect(() => {
    // dispatch(fetchingFishesHistory(loginDataFromStorage));
    // if (fishHistoryList?.data?.length > 0) {
    console.log('fishHistory count', fishHistoryList);
    setSpeciesCount(fishHistoryList?.data?.length);
    // }
  }, [dispatch, fishHistoryList]);



  return (
    <div className="banner-content">
      <div className="container"
      data-aos="fade-up"  data-aos-delay={100}
      >
        {deviceType == "mobile" &&
          <>
            {!loginData ? <p className="top-text">Your presence is undetected:  <span className="highlight">{" Lost Nemo"}</span></p> :
              <p className="top-text">You are logged in as: <span className="highlight"> {loginData}</span></p>
            }
          </>
        }
        <div className="mobile-banner-content">
          <h2>
            {items?.section_banner?.title}
          </h2>
          <p>
            {items?.section_banner?.description}
          </p>
          {deviceType === 'desktop' ?
            <>
              {items?.section_banner?.statistics?.text!=null ? (
                <div>
                <h4 className="total-count">{items?.section_banner?.statistics?.count}</h4>
                <h2 className="marine-species">{items?.section_banner?.statistics?.text}</h2>
              </div>
              ) : (null)}
            </>
            :
            <NumberCard items={items}/>
          }
          <BlueLinkTitle
            // animationType="fade-up" animationDelay={100}
            text={items?.section_banner?.view_more_cta?.title}
            onClick={() =>
              navigateToPage("/all-animals", navigate)
            }
          />
        </div>
      </div>
      {deviceType == "mobile" && loginData && fishHistoryList?.data?.length > 0 && 
      <FishDetectedCount 
      speciesCount={speciesCount}
      animationType="fade-up"  animationDelay={100}
      />}
    </div>
  );
};

export default MobileBanner;
