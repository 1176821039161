import { FETCH_ALL_FISHES } from '../actions/FishLibrary/type';

const initialState = {
  allFishes: null,
  metaImage:"",
metaTitle:"",
  metaDescription:""
};

const fishLibrary = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_FISHES:
      return {
        ...state,
        allFishes: action.payload,
        metaTitle:action.metaTitle,
        metaImage:action.metaImage,
        metaDescription:action.metaDescription
      };
    default:
      return state;
  }
};

export default fishLibrary;