import React, { useState, useEffect, useRef } from 'react';
import PageMainHeadingContent from "../UI_Elements/PageMainHeadingContent/PageMainHeadingContent";
import Button from "../UI_Elements/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { checkDeviceType } from '../../actions/CheckDevice';

const BlueBannerContent = (props) => {
const { image, showShareButton, Righttext, RightButton1, RightButton2, heading, paragraph, onClick, cardAnimationType,  cardAnimationDuration  } = props
    const impressFriendsClass = image ? "impress-friends" : "impress-friends no-image";
    const deviceType = useSelector((state) => state.checkDevice.deviceType);

    const dispatch = useDispatch();
   

    useEffect(() => {
        dispatch(checkDeviceType());
      }, [dispatch, deviceType]);
      
    return (
        <>
            <section className="share-with-friends"
            data-aos={deviceType=='desktop'?"":"fade-up"} 
            data-aos-delay={100}
            >
                <div className={impressFriendsClass}>
                    <div className="left-content"  
                    
                    >
                        <PageMainHeadingContent
                            heading={heading}
                            theme="themeLight"
                            gapBetween="16px"
                            cardAnimationType={deviceType=='desktop'?cardAnimationType:""} cardAnimationDuration={cardAnimationDuration}
                        >
                           <p dangerouslySetInnerHTML={{ __html: paragraph }} />
                            {showShareButton && (
                                <a href="#" style={{ display: "flex", width: "190px" }}>
                                    <Button text="Share This" buttonType="btn-outlined" textColor="dark" onClick={onClick} />
                                </a>
                            )}
                        </PageMainHeadingContent>
                    </div>
                    <div className="right-image"
                    >
                        {/* {!image && Righttext && <p className="custom-para">{Righttext}</p>} */}
                        {image ? (
                            <img src={image} alt="" 
                            data-aos={deviceType=='desktop'?"fade-up":""} 
                            data-aos-delay={100}
                            />
                        ) : (
                            <>
                            {deviceType == 'mobile'?
                            (
                            <div className="custom-button" data-aos={deviceType=='desktop'?"fade-up":""} data-aos-delay={100}>
                                {RightButton1 &&
                                    <div className="button-1">
                                        <Button {...RightButton1} />
                                    </div>
                                }
                                {RightButton2 &&
                                    <div className="button-2">
                                        {Righttext && <p className="custom-para">{Righttext}</p>}
                                        {RightButton2 && <Button {...RightButton2} />}
                                    </div>
                                }
                            </div>
                            ):(
                                <div className="custom-button desktopLayout"
                                data-aos={deviceType== 'desktop'?"fade-up":""}
                                data-aos-delay={100}
                                >
                                {Righttext && <p className="custom-para">{Righttext}</p>}
                                <div>
                                {RightButton1 &&
                                    <div className="button-1">
                                        <Button {...RightButton1} />
                                    </div>
                                }

                                {RightButton2 &&
                                    <div className="button-2">
                                       
                                        {RightButton2 && <Button {...RightButton2} />}
                                    </div>
                                }
                                </div>
                            </div>
                            )}
                            </>
                        )}

                    </div>
                </div>
            </section>
        </>
    );
};

export default BlueBannerContent;
