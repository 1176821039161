import React, { useEffect } from 'react';
import ParagraphDark from '../ParagraphDark/ParagraphDark';
import Button from '../Button/Button';
import { checkDeviceType } from '../../../actions/CheckDevice';
import { useDispatch, useSelector } from "react-redux";
import fileToDowload from '../../../assets/images/dowload/map-file.pdf'
const ImageBannerWithGradient = (props) => {
  const dispatch = useDispatch();
  const deviceType = useSelector((state) => state.checkDevice.deviceType);

  useEffect(() => {
    dispatch(checkDeviceType());
  }, []);
  // showDownloadLink={true}
  // showMap={true}
  // showButton={true}

  return (
    <>
      {deviceType == 'mobile' ? (
        <div
          className={`ImageBannerWithGradient ${props.customClass} ${props.showButton ? 'large' : 'small'}`}
          style={props.customStyle}
        >
          {props.children}
          {props.showMap ? (
            <div className='mapSection'>
              <img src={require('../../../assets/images/png/map-starfish.png')} alt='mapImage'  data-aos={deviceType == 'desktop'?"fade-up": "fade-up"} data-aos-delay={100} />
              {props.showDownloadLink ? (
                <a  data-aos={deviceType == 'desktop'?"fade-up": "fade-up"} data-aos-delay={50} className='downloadLink' href={fileToDowload} download="map.pdf">Download the map</a>
              ) : (null)}
              {props.showButton ? (
                <div data-aos={deviceType == 'desktop'?"fade-up": "fade-up"} data-aos-delay={50} >
                <Button
                  text="Explore all Chambers"
                  buttonType="btn-white-outlined"
                  textColor="light"
                  onClick={props.onclickbottomBtn}
                >
                </Button>
                </div>
              ) : (null)}
            </div>
          ) : (null)}
        </div>
      ) : (
        <div
          className={`ImageBannerWithGradient ${props.customClass} ${props.showButton ? 'large' : 'small'}`}
          style={props.customStyle}
        >
          <div className='container mainContainer'>
          <div style={{flex:1}}>
            {props.children}
            <div className='linkSectionWeb'
             data-aos="fade-up" 
             data-aos-delay={100} 
            >
            {props.showDownloadLink ? (
              <a className='downloadLink' href={fileToDowload} download="map.pdf"
              >Download the map</a>
             
            ) : (null)}
            {props.showButton ? (
             
              <Button
              customClass={props.customClass}
                text="Explore all Chambers"
                buttonType="btn-white-outlined"
                textColor="light"
                onClick={props.onclickbottomBtn}
              >
              </Button>
             
            ) : (null)}
            </div>
          </div>

          {props.showMap ? (
            <div className='mapSection' style={{flex:1}} data-aos={deviceType == 'desktop'?"fade-up": "fade-up"} data-aos-delay={200} >
              <img src={require('../../../assets/images/png/map-starfish.png')} alt='mapImage' />
            </div>
          ) : (null)}
          </div>
        </div>
      )}
    </>
  )
}

export default ImageBannerWithGradient