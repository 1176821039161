import React, { useState, useEffect } from "react";
import DesktopHeader from "./DesktopHeader";
import menuBtn from "../../assets/images/svg/btn-menu.svg";
//import logoMobile from "../../assets/images/svg/logo-mobile.svg";
import scanBtn from "../../assets/images/svg/btn-scan.svg";
import HamburgerContent from "../HamburgerContent/HamburgerContent";
import { Link, useLocation } from "react-router-dom";
import { checkDeviceType } from '../../actions/CheckDevice';
import { useDispatch, useSelector } from "react-redux";
import useScrollDirection from '../../CustomHooks/useScrollDirection';
import FishCameraButton from "../FishCameraButton/FishCameraButton";
import closeIcon from "../../assets/images/svg/close-btn.svg";
import videos from '../../assets/videos/videos';
import PlayVideo from '../PlayVideo/PlayVideo';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';
import { setActiveUrl } from "../../actions/NavigateUrls";
import LoaderBg from "../../assets/videos/LoaderBg.mp4";

import logoMobile from "../../assets/images/png/wordlogoblue.png";
import logoMobileWhite from "../../assets/images/png/WorldReversedLogo.png";

const Header = () => {

  const { scrollDirection, load } = useScrollDirection();
  const [ScrollStateClass, setScrollStateClass] = useState(null);
  const activePage = useSelector((state) => state.navigateUrlsReducer.activeUrl);
  const dispatch = useDispatch();


  // useEffect(() => {
  //     dispatch(setActiveUrl(activePage));
  //   }, [dispatch,activePage])


  useEffect(() => {
    if (load) {
      setScrollStateClass('on-load');
    } else {
      setScrollStateClass(scrollDirection === 'down' ? 'scroll-down' : 'scroll-up');
    }
  }, [load, scrollDirection]);

  const deviceType = useSelector((state) => state.checkDevice.deviceType);

  const [windowWidth, setWindowWidth] = useState(null);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [isMenuClicked, setMenuClicked] = useState(false);



  useEffect(() => {
    dispatch(checkDeviceType());
  }, [dispatch]);


  const handleMenuButtonClick = () => {
    setMobileMenuVisible(!isMobileMenuVisible);
    setMenuClicked(!isMenuClicked);
  };

  const [shouldHaveBackground, setShouldHaveBackground] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isMobileMenuVisible) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
    return () => {
      document.body.classList.remove("mobile-menu-visible");
    };
  }, [isMobileMenuVisible]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 50;

      if (scrollPosition >= scrollThreshold) {
        setShouldHaveBackground(true);
      } else {
        setShouldHaveBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const currentPath = location.pathname;
  const isHomePage = currentPath === "/";
  const isInnerPage = !isHomePage;

  const handleLinkClick = (url) => {
    if (!(url.includes('http://') || url.includes('https://'))) {
      dispatch(setActiveUrl(url));
    }
    setMobileMenuVisible(false);
  };

  const renderHeaderWeb = () => {
    return (
      <DesktopHeader />
    )
  }

  const renderHeaderMobile = () => {
    return (
      <>
        {/*  video is added here to preload the video to play in hamburger content */}
        {/* <div className="hamburger-video"
          style={{ height: "0", width: "0", opacity: '0', zIndex: '-1', position: "relative" }}>
          <video className="video-bg" autoPlay loop muted>
              <source src={LoaderBg} type="video/mp4" />
          </video>
        </div> */}

        <div className={`${isMenuClicked && 'showContent'} mobile-header headers ${ScrollStateClass} ${isHomePage ? "" : ""} ${isInnerPage || (isHomePage && shouldHaveBackground) ? "bg-color-for-inner-pages" : "transparentBg"}`}>
          <div className="container">
            <div className="nav-menu">
              {/* <img
                src={isMenuClicked && isMobileMenuVisible ? closeIcon : menuBtn}
                alt=""
                // className="menu-btn" 
                className={`menu-btn ${isMenuClicked ? 'menu-open' : ''}`}
                id="menuBtn" onClick={handleMenuButtonClick}
              /> */}

              <button className={`menu-btn ${isMenuClicked && isMobileMenuVisible ? 'menu-open' : ''}`} onClick={handleMenuButtonClick}>
                {
                  isMenuClicked && isMobileMenuVisible ?
                    <CloseRoundedIcon
                      style={{ color: isInnerPage || (isHomePage && shouldHaveBackground) ? "#0A7C8A": 'white' }}
                    /> :
                    <NotesRoundedIcon
                      style={{ color: isInnerPage || (isHomePage && shouldHaveBackground) ? "#0A7C8A": 'white' }}
                    />}
              </button>


              {/* <img src={menuBtn} alt="" className="menu-btn" id="menuBtn" onClick={handleMenuButtonClick} /> */}
              <Link to="/">
                {isInnerPage || (isHomePage && shouldHaveBackground) ?
                  <img src={logoMobile} alt="" className="logo " />
                  :
                  <img src={logoMobileWhite} alt="" className="logo " />
                }
                {/* <img src={isHomePage?logoMobileWhite: logoMobile} alt="" className="logo" /> */}
              </Link>
              <FishCameraButton btnType="picture" backgroundStatus={shouldHaveBackground} />
            </div>

          </div>

          {/* {isMobileMenuVisible &&  */}
          <div className={`hamburger-menu ${isMobileMenuVisible && 'hamburger-menu-active'}`}>
            <HamburgerContent setMobileMenuVisible={setMobileMenuVisible} />
          </div>
          {/* } */}
        </div>

      </>
    )
  }

  const renderContent = () => {
    return (
      <>
        {deviceType === 'mobile' ?
          renderHeaderMobile()
          :
          renderHeaderWeb()
        }
      </>
    )
  }
  return (
    <>
      {renderContent()}
    </>

  )



};

export default Header;
