import React, { useEffect } from 'react';
import PageMainHeadingContent from '../../components/UI_Elements/PageMainHeadingContent/PageMainHeadingContent';
import AccordionTitleContent from '../../components/AccordionTitleContent/AccordionTitleContent'
import BlueBannerContent from '../../components/BlueBannerWithContent/BlueBannerContent';
import MainTitleContent from '../../components/UI_Elements/MainTitleContent/MainTitleContent';
import { useDispatch, useSelector } from "react-redux";
import { fetchingFaq } from '../../actions/Faq';
import { handleMailButtonClick } from '../../utils/functions';
import { Helmet } from 'react-helmet';
import { checkDeviceType } from '../../actions/CheckDevice';
import { setActiveUrl } from "../../actions/NavigateUrls";


const Faq = () => {
  const faqData = useSelector((state) => state.Faq.items);
  const faqPageTitle = useSelector((state) => state.Faq.pageTitle);
  const metaTitle = useSelector(state => state.Faq.metaTitle);
  const metaImage = useSelector(state => state.Faq.metaImage);
  const metaDescription = useSelector(state => state.Faq.metaDescription);
  const deviceType = useSelector((state) => state.checkDevice.deviceType);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveUrl('/faq'));
  }, [])


  useEffect(() => {
    dispatch(fetchingFaq());
  }, []);

  useEffect(() => {
    dispatch(checkDeviceType());
  }, [dispatch, deviceType]);



  const openLink = () => {
    window.open(faqData?.section_visit_planing?.button_one?.link, "_blank")
  }

  const renderFAQContent = () => {
    return (
      <div className='faq-wrapper'   data-aos="fade-up"
      data-aos-delay="400"
      data-aos-duration="1000">
        {faqData?.sections?.map((section, index) => (
          <div className="faq-accordion-wrap" key={index}>
            <MainTitleContent text={section?.title.charAt(0).toUpperCase() + section?.title.slice(1)} customClass="d-m-left" theme='themeDark' gapBetween="4px" />
            <div className='faq-accordion-details'>
              {section?.list?.map((data, index) => (
                <AccordionTitleContent key={index} title={data.main} content={data.details} />
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  const renderFaqEnquiry = () => {

    return (
      <div className="faq-share-wrap">
        {faqData?.section_visit_planing?(
        <BlueBannerContent
          heading={faqData?.section_visit_planing?.title}
          paragraph={faqData?.section_visit_planing?.description}
          RightButton1={{
            text: faqData?.section_visit_planing?.button_one.title,
            buttonType: "btn-backgrounded",
            textColor:"dark",
            onClick: openLink
          }}
          RightButton2={{
            text: faqData?.section_visit_planing?.button_two.title,
            textColor:"dark",
            buttonType: "btn-outlined",
            onClick: () => handleMailButtonClick(faqData?.section_visit_planing?.button_two.title, faqData?.section_visit_planing?.button_two?.email_subject)
          }}
          Righttext={faqData?.section_visit_planing?.enquiry_text}
          cardAnimationType={deviceType=='desktop'?"fade-up": "fade-up"}
          cardAnimationDuration={"100"}
        />
        ):(null)}
      </div>
    )
  }


  return (
    <>
     <Helmet>
        <meta property="og:title" content={metaTitle}/>
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:secure_url" content={metaImage}/>
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className='page-faq dynamicMinHeight container'>
        <div data-aos="fade-up"
             data-aos-delay="200"
             >
          <PageMainHeadingContent heading={faqPageTitle?.rendered} theme='themeLight' gapBetween="26px"  />
        </div>
        {renderFAQContent()}
        {renderFaqEnquiry()}
      </div>
    </>
  )
}

export default Faq;
