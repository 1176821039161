import React, { useState, useEffect } from "react";
import CardCarousel from "../Carousels/CardCarousel/CardCarousel";
import Button from "../UI_Elements/Button/Button";
import MainTitleContent from '../UI_Elements/MainTitleContent/MainTitleContent';
import ParagraphLight from "../UI_Elements/ParagraphLight/ParagraphLight";
import { checkDeviceType } from '../../actions/CheckDevice';
import { useSelector, useDispatch } from "react-redux";


const ActivitySlider = ({ activeClick, contentClickRoute, slideData, title, Paragraph, slidesToShow, responsiveSettings, buttonText, customClassForTitle, btnWidth, gapBetween, onClick, customClassForCardName }) => {

  const dispatch = useDispatch();

  const handleImageClick = (data) => {
    console.log('Clicked', data);
  };
  const deviceType = useSelector((state) => state.checkDevice.deviceType);


  useEffect(() => {
    dispatch(checkDeviceType());
  }, [dispatch]);

  return (
    <div className="activity-slider">
      <div data-aos={deviceType == 'desktop' ? "fade-up" : ""} data-aos-delay="200">
        <MainTitleContent text={title} customClass={customClassForTitle} theme='themeLight' gapBetween={gapBetween ? gapBetween : "10px"}>
          <ParagraphLight para={Paragraph} htmlPara={true} />
        </MainTitleContent>
      </div>
      <div className="activity-content-slider"
        data-aos="fade-up" data-aos-delay="200"
      >
        <CardCarousel
          slideData={slideData}
          slidesToShow={slidesToShow}
          responsiveSettings={responsiveSettings}
          customClassForCardName={customClassForCardName}
          contentClickRoute={contentClickRoute}
          handleImageClick={handleImageClick()}
          isActiveClick={activeClick}
        />
      </div>
      {buttonText && (
        <div className='activityButton' style={{ maxWidth: btnWidth }} data-aos={deviceType == 'desktop' ? "fade-up" : ""} data-aos-delay="200">
          <Button
            text={buttonText}
            textAlign="center"
            textColor="dark"
            buttonType="btn-outlined"
            onClick={onClick}
          >
          </Button>
        </div>
      )}

    </div>
  );
};

export default ActivitySlider;
