import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TitleBlue from '../UI_Elements/TitleBlue/TitleBlue';
import ParagraphDark from '../UI_Elements/ParagraphDark/ParagraphDark';
import Button from '../UI_Elements/Button/Button';
import { checkDeviceType } from '../../actions/CheckDevice';
import { useDispatch, useSelector } from 'react-redux';

import { navigateToPage } from "../../CustomHooks/navigateToRoute";
import { style } from '@mui/system';

const AccordionItem = ({ item, activeTab, index, activateTab }) => {
  const dispatch = useDispatch();
  const [height, setHeight] = useState(0);
  const navigate = useNavigate();
  const panelInnerRef = useRef(null);
  const deviceType = useSelector((state) => state.checkDevice.deviceType);

  useEffect(() => {
    dispatch(checkDeviceType());
  }, [dispatch]);

  useEffect(() => {
    checkContentHeight();
  }, [index, activeTab]);

  const checkContentHeight = () => {
    if (panelInnerRef.current) {
      const contentHeight = panelInnerRef.current.scrollHeight;
      setHeight(contentHeight);
    }
  };

  const isActive = activeTab === index;
  const innerStyle = {
    height: `${isActive ? height : 0}px`,
  };

  return (
    <div id={`panel-${index}`} className="panel" role="tabpanel" aria-expanded={isActive}>
      <button
        className="panel__label"
        role="tab"
        onClick={() => activateTab(index)}
        data-aos={deviceType=='desktop'?"fade-up":""}
        data-aos-delay={`${index}00`}
      >
        <div className='panel-content-wrap'>
          <div className="panel-img-wrap">
            <img src={item?.image} alt="" />
          </div>
          <TitleBlue text={item?.name} />
        </div>
        <div className={`circle-plus ${!isActive ? "closed" :"opened"} `}>
            <div className="circle">
              <div className="horizontal"></div>
              <div className="vertical"></div>
            </div>
        </div>
      </button>
      <div
        className="panel__inner"
        style={innerStyle}
        aria-hidden={!isActive}
        ref={panelInnerRef}
        // data-aos="fade-down"
        // data-aos-delay={`${index}00`}
      >
        <div className="content-grid-wrap">
          <div className="panel-img-wrap" style={{ minWidth: '80px' }}></div>
          <div className="content-grid-body">
            {item?.desc &&
              <ParagraphDark para={item?.desc} htmlPara={true} />
            }
            <div className="content-details-wrap">
              <p>{item?.recurrence} for {item?.duration ? item.duration : '--'}</p>
              <p>Age: {item?.age}</p>
              {/* <p style={{ color: '#ffffff' }}>Price: {item?.price ? item.price : '--'}</p> */}
              <p>Swimming Level: {item?.experienced}</p>
            </div>
            <div style={{ width: '160px' }} onClick={() =>
              navigateToPage(`/experiences/details/${item?.ID}`, navigate)
            }>
              <Button text="Know More" buttonType="btn-outlined" textColor="dark"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;

