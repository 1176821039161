import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlueBannerContent from '../../BlueBannerWithContent/BlueBannerContent';

const FullWidthCarousel = ({items}) => {

const settings = {
    dots: true,
    infinite: false,
    prevArrow: <></>,
    nextArrow: <></>,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1
};

return (
    <>
        <div className='single-slider ' >
                <Slider {...settings}>
                {items?.map((item, index) => (
                    <div className="bluebanner container" key={index}>
                        <BlueBannerContent 
                            heading={item?.title}
                            paragraph={item?.description}
                            image={item?.image}
                            showShareButton={false}
                            />
                    </div>

                ))}
                </Slider>
        </div>
    </>

)
};

export default FullWidthCarousel