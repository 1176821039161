import { configureStore } from '@reduxjs/toolkit';
import reducer from "./reducers";

export default configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }),
});