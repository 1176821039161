import { FETCH_POSTS, FETCH_POSTS_FILTER, FETCH_EXPERIENCES_DETAIL } from "./type";
import { axiosCustom } from "../../utils/request";

export const fetchingActivity = () => async dispatch => {
   const response = await axiosCustom.get("pages/5046");
   const { data = false, status } = response;
   if (status === 200) {
      if (data) {
         dispatch({
            type: FETCH_POSTS,
            items: data.acf,
            metaTitle: data?.page_meta?.meta_title,
            metaImage:data?.page_meta?.meta_image,
            metaDescription: data?.page_meta?.meta_description
         });
         
      }
   }
};

export const fetchingExperiencesDetail = (id) => async (dispatch) => {
   console.log('id ===', id);
       const response = await axiosCustom.get(`experiences/${id}`);
       const { data = false, status } = response;
       if (status === 200) {
           if (data) {
               console.log('detailsfetched ===> ', data);
               dispatch({
                   type: FETCH_EXPERIENCES_DETAIL,
                   items: data.acf,
                   metaTitle: data?.page_meta?.meta_title,
                   metaImage:data?.page_meta?.meta_image,
                   metaDescription: data?.page_meta?.meta_description
               });
           }
       }
   };


// Filtered Data
export const fetchingFilteredData = selectedFilter => async dispatch => {
   const response = await axiosCustom("filter/v1/managefilter", {
      method: "POST",
      data: selectedFilter,
   });
   const { data = false, status } = response;
   if (status === 200) {
      if (data) {
         dispatch({
            type: FETCH_POSTS_FILTER,
            items: data,
         });
      }
   }
};
