import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from "./page/Home/Home";
import Demonstration from "./components/Demonstrations/Demonstrations";
import Attractions from "./page/Attractions/Attractions";
import AttractionDetail from "./page/AttractionDetail/AttractionDetail";
import ScanFish from "./page/ScanFish/ScanFish";
import Experiences from "./page/Experiences/Experiences";
import Discover from "./page/Discover/Discover";
import ExperienceDetails from "./page/ExperienceDetails/ExperienceDetails";
import PageNotFound from "./page/PageNotFound/PageNotFound";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Chambers from "./page/Chambers/Chambers";
import ChamberDetails from "./page/ChamberDetails/ChamberDetails";
import SocialLogin from "./page/SocialLogin/SocialLogin";
import Faq from "./page/Faq/Faq";
import AllAnimals from "./page/AllAnimals/AllAnimals";
import FishLibrary from "./page/FishLibrary/FishLibrary";
import CareAndConservation from "./page/CareAndConservation/CareAndConservation";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import NativeHeader from "./components/NativeHeader/NativeHeader";
import BottomNavbar from './components/BottomNavbar/BottomNavbar'
import PlayVideo from "./components/PlayVideo/PlayVideo";
import atlantisVideo from "./assets/videos/Atlantis_Video.mp4";

import footerVideo from "./assets/videos/footer_video.mp4";
import TermsAndConditions from "./page/TermsAndConditions/TermsAndConditions";
import FishDetection from "./components/FishDetection/FishDetection";
import Loading from "./components/UI_Elements/Loading/Loading";
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  const location = useLocation();

  const hideHeaderFooter =
    location.pathname === '/social-login' ||
    location.pathname === '/fish-library' ||
    location.pathname === '/loading' ||
    location.pathname.includes('/scan-fish');

  const showNativeHeader =
    location.pathname === '/fish-library' ||
    location.pathname.includes('/scan-fish');

  const headerSpace =
  location.pathname === '/social-login' ||
    location.pathname === '/loading';


  const isMobile = window.innerWidth <= 768;



  useEffect(() => {
  
      AOS.init({
        offset: 100, 
        once: true, 
        // easing: 'ease-in',
        // duration: 1000,
        duration: 600,
        easing: 'ease-in-sine',
        delay: 0,

        initClassName: 'aos-init',
        animatedClassName: 'aos-animate',
        useClassNames: false,
        disableMutationObserver: false,
       
        }); 
        //AOS.refresh();

  }, []);

  // setTimeout(function () { AOS.init(); }, 1000);
  return (

    <>
      <div className="atlantis-container" id='atlantis-container'>
        {!hideHeaderFooter && <header ><Header /></header>}
        {showNativeHeader && <header><NativeHeader currentUrl={location.pathname} /></header>}
        <div className={`${!headerSpace ? 'main-body-content' : 'header-noSpace'}`}>
          <Routes>

            <Route exact path='/' element={<Home />} />
            <Route path='/demo' element={<Demonstration />} />
            {/* Attraction module routes */}
            <Route path='attractions'  >
              <Route index element={<Attractions />} />
              <Route path="detail/:attractionId" element={<AttractionDetail />} />
            </Route>
            <Route path='scan-fish/:fishId' element={<ScanFish />} />

            <Route path="detect-fish" element={<FishDetection />} />

            {/* Experience module routes */}
            <Route path='experiences'  >
              <Route index element={<Experiences />} />
              <Route path="details/:experienceId" element={<ExperienceDetails />} />
            </Route>
            <Route path='chambers' >
              <Route index element={<Chambers />} />
              <Route path="details/:chamberId" element={<ChamberDetails />} />
            </Route>
            <Route path='faq' element={<Faq />} />
            <Route path='all-animals' element={<AllAnimals />} />
            <Route path='discover' element={<Discover />} />
            <Route path='fish-library' element={<FishLibrary />} />
            <Route path='care-and-conservation' element={<CareAndConservation />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="Terms-and-Conditions" element={<TermsAndConditions />} />
            {isMobile && (
              <Route path="social-login" element={<SocialLogin />} />
            )}

            {isMobile && (
              <Route path="loading" element={<Loading />} />
            )}

          </Routes>
          {!hideHeaderFooter &&
          <div className="footer-video container-fluid" >
             <PlayVideo src={footerVideo} />
          </div>
          }
        </div>

        {!hideHeaderFooter && <footer><Footer /></footer>}
        {!hideHeaderFooter && <BottomNavbar />}
        <ScrollToTop />
      </div>
    </>
  );
}

export default App;
