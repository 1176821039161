import { FETCH_FISH_HISTORY, FETCH_DATA_ERROR } from "./type";
import { axiosPythonApi } from "../../utils/request";

export const fetchingFishesHistory = (email) => async (dispatch) => {
    try {
      const response = await axiosPythonApi.get("history/", {
        params: {
          "email": email
        }
      });
      const { data = [], status } = response; 
      console.log("history chekkk-->",data);
      if (status === 200) {
        if (data) {
          dispatch({
            type: FETCH_FISH_HISTORY,
            payload: data,
          });
        }
      } else {
        dispatch({
          type: FETCH_DATA_ERROR,
          error: "Error: Unable to fetch animal details.",
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_DATA_ERROR,
        error: "Error: " + error.message,
      });
    }
  };