import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import images from '../../../assets/images/images';
import TitleBlue from '../../../components/UI_Elements/TitleBlue/TitleBlue';
import { useNavigate } from "react-router-dom";
import { navigateToPage } from "../../../CustomHooks/navigateToRoute";

const GridCarousel = ({ sliderData, navigateRoute, activeData }) => {
  const navigate = useNavigate();
  const [fishLog, setFishLog] = useState(null);
  const [sortedActiveFirstList, setSortedActiveFirstList] = useState(null);

  const settings = {
    dots: true,
    arrows: false,
    rows: 10,
    slidesPerRow: 2,
    infinite: false
  };

  const getComparedData = () =>
    sliderData.map((x) => {
      const flag = activeData?.some((y) => y.detected_species.toLowerCase() === x.title.toLowerCase());
      return {
        id: x.id,
        image: x.image,
        title: x.title,
        active: flag,
      };
    });

  useEffect(() => {
    const comparedData = getComparedData();
    const sortedList = comparedData.sort((a, b) => {
      if (a.active && !b.active) {
        return -1; // a comes first when a.active is true and b.active is false
      } else if (!a.active && b.active) {
        return 1; // b comes first when a.active is false and b.active is true
      } else {
        return 0; // no change in ordering if both a.active and b.active are either true or false
      }
    });
    setFishLog(comparedData);
    setSortedActiveFirstList(sortedList);
  }, [sliderData, activeData]);

  return (
    <div className='grid-carousel-container'>
      <Slider {...settings}>
        {sortedActiveFirstList?.map((item, index) => (
          <div className='grid-carousel-list' key={item?.id}
          data-aos="fade-up"
          data-aos-delay={`${index}00`}
          >
            <div className='grid-carousel-list-item' key={index}>
              <div
                className={`fish-scan-wrap ${item?.active ? 'active' : ''}`}
                onClick={() =>
                  navigateToPage(`${navigateRoute}${item?.id}`, navigate)
                }
              >
                <img src={item?.active ? item?.image : images.iconFish} alt='' />
              </div>
              <TitleBlue text={item?.title} customClass='sm' />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default GridCarousel;
