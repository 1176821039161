import React, { useEffect, useState } from 'react';

const BlueIconwithText = ({ image, title, onClick, initialLoadingCategory, term_id, customClass, animationType, animationDuration }) => {
  const [activeKey, setActiveKey] = useState(initialLoadingCategory);

  useEffect(() => {
    setActiveKey(initialLoadingCategory);
  }, [initialLoadingCategory])

  const handleClick = (categoryId) => {
    if (onClick) {
      onClick(categoryId);
    }
    setActiveKey(categoryId);
  };

  const handleBlur = () => {
    setActiveKey(0);
  };
  return (
    <>
      <div
        className={`blue-icon-card ${activeKey == term_id ? 'active' : 'nonActive'} ${customClass}`}
        onBlur={handleBlur}
        tabIndex={0}
        onClick={() => handleClick(term_id)}
        // data-aos={animationType} data-aos-delay={animationDuration}
        
      >
        <div className='blue-icon-card-content'>
          <div className='image'>
            <img className='img' alt='' src={image} />
          </div>
          <span className='species-name'>{title}</span>
        </div>
      </div>
    </>
  );
};

export default BlueIconwithText;
