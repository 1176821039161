import React, { useEffect, useState } from 'react';
import { useNavigate, useHis } from "react-router-dom";
import LoaderBg from "../../../assets/videos/LoaderBg.mp4";
import Loader from "react-js-loader";
import { useDispatch, useSelector } from 'react-redux';
import { FishScaning, clearScan, scannedFishDetail } from "../../../actions/fishUpload";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import ScanResult from '../../Modals/ScanResult/ScanResult';
import ScanResultFaliure from '../../Modals/ScanResultFaliure/ScanResultFaliure';
import { navigateToPage } from "../../../CustomHooks/navigateToRoute";

export default () => {
    const [successVisible, setSuccessVisible] = useState(false);
    const [faliure, setFaliure] = useState(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const fishUpload = useSelector((state) => state.fishUpload.items);
    const result = useSelector((state) => state.fishUpload.scanResult);
    const deviceType = useSelector((state) => state.checkDevice);

    const fishDetails = useSelector((state) => state.fishUpload.fishDetails);


    useEffect(() => {
        if (successVisible || faliure ) {
          document.body.classList.add("mobile-menu-visible");
        } else {
          document.body.classList.remove("mobile-menu-visible");
        }
        return () => {
            document.body.classList.remove("mobile-menu-visible");
          };
      }, [successVisible, faliure]);



    console.log('fishUpload == ', fishUpload);
    useEffect(() => {
        if(fishUpload==null)
        {
            navigateToPage("/", navigate);
        }else{
            dispatch(FishScaning(fishUpload));
        }
    }, [dispatch, deviceType]);


    useEffect(() => {
        if (result !== null) {
            console.log('result receuved == ', result);
            dispatch(scannedFishDetail(result));
        }
        // return () => {
        //     console.log('component un mounted ==');
        //     dispatch(clearScan());
        //     setSuccessVisible(successVisible);
        //     setFaliure(faliure);
        // };

    }, [dispatch, result]);

    useEffect(() => {
        if (fishDetails !== null) {
            console.log('fishDetails === ', fishDetails);
            if (result?.fishes?.length > 0) {
                console.log('If called');
                setSuccessVisible(true);
            }
            else {
                console.log('else called');
                setFaliure(true);
            }
        }
    }, [dispatch, fishDetails]);

    const handleFailureClose = () => {
        setFaliure(false);
    };
    const handleSuccessClose = () => {
        setSuccessVisible(false);
    };

    const hide = () => {
        dispatch(clearScan());
        setSuccessVisible(false);
        setFaliure(false);
        navigateToPage("/", navigate);
    }
    const CustomCloseIcon = () => {
        return <span>X</span>; // Custom close icon element or component
    };

    return (
        <>
            <div className="video-container"
                style={{ height: window.innerHeight }}
            >
                <video className="video-bg" autoPlay loop muted >
                    <source src={LoaderBg} type="video/mp4" />
                </video>
                <div className="loaderContainer">
                    <Loader type="spinner-cub" bgColor={"#2DCCD3"} title={"Finding Your Fish"} color={'#CCD1DB'} size={24} />
                </div>
            </div>

            {/* Scan Successed */}

            <Rodal visible={successVisible} onClose={hide}
                showCloseButton={true}
                width={deviceType === 'desktop' ? 730 : window.innerWidth}
                height={deviceType == 'desktop' ? 640 : 'auto'}
                className="pledgeModal"
                customStyles={{ backgroundColor: '#fff' }}
                animation='slideUp'
                closeIcon={<CustomCloseIcon />}
            >
                {/* <PledgeForm onClose={hide} onSubmit={submitData} /> */}
                {/* <SocialShare/> */}
                <ScanResult fishList={fishDetails} onClose={handleSuccessClose} />
            </Rodal>

            {/* Scan Falied */}
            <Rodal visible={faliure} onClose={hide}
                showCloseButton={true}
                width={deviceType === 'desktop' ? 730 : window.innerWidth}
                height={deviceType == 'desktop' ? 640 : 'auto'}
                className="pledgeModal"
                customStyles={{ backgroundColor: '#fff' }}
                animation='slideUp'
                closeIcon={<CustomCloseIcon />}
            >
                {/* <PledgeForm onClose={hide} onSubmit={submitData} /> */}
                {/* <SocialShare/> */}
                <ScanResultFaliure fishList={fishDetails} onClose={handleFailureClose} />
            </Rodal>

        </>
    );
};
