import React from 'react'
import YellowIconWithTitle from "../../components/YellowIconWithTitle/YellowIconWithTitle";
const GridItemIcon = (props) => {
    const {elements, itemsInRow} = props;
    console.log("gsdgsh",elements)
    return (
        <div className='grid-item-icon-container'>
            <div  className={`grid-item-icon--item item-grid--${itemsInRow}`}>
                {elements?.length>0 &&
                    elements?.map((element, index) => (
                        <div data-aos="fade-up" data-aos-delay={`${index}00`}  key={index}>
                            <YellowIconWithTitle textColor={props.textColor} title={element?.text} image={element?.icon} numberCount={element?.number ? element?.number:null}/>
                        </div>
                    ))} 
            </div>
        </div>
    )
    }

export default GridItemIcon