import React, { useEffect, useCallback, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import images from "../../assets/images/images";
import PlayVideo from "../../components/PlayVideo/PlayVideo";
import videos from "../../assets/videos/videos";
import { checkDeviceType } from "../../actions/CheckDevice";
import { useDispatch, useSelector } from "react-redux";
import { navigateToPage } from "../../CustomHooks/navigateToRoute";
import { fetchFishUpload } from "../../actions/fishUpload";
import { setUserData } from "../../actions/UserData";
import { Helmet } from "react-helmet";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import { setActiveUrl } from "../../actions/NavigateUrls";
import logoMobileWhite from "../../assets/images/svg/logo-mobile-white.svg";


const CustomFacebookButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="facebook-btn">
      Sign in with Facebook
    </button>
  );
};
const CustomGoogleButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="google-btn">
      Sign in with Google
    </button>
  );
};

const SocialLogin = ({ loginData }) => {
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();



  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileUpload = React.createRef();

  const loginUserData = useSelector(state => state.userDataReducer.loginData);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isLogin, setIsLogin] = useState(localStorage.getItem("loginData"));

  const openCamera = () => {
    if (fileUpload.current) {
      fileUpload.current.click();
    }
  };

  useEffect(() => {
    dispatch(setActiveUrl('/social-login'));
  }, [])

  useEffect(() => {
    console.log("islogin out", isLogin);
    if (isLogin) {
      navigateToPage("/", navigate);
      console.log("islogin", isLogin);
    }
  }, [isLogin]);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const InputEmail = null;

  const checkoutSocialLogin = () => {
    navigateToPage("-1", navigate);
  };


  



  const handleChange = event => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else {
      setError(null);
    }
    setEmail(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if(!error){
      dispatch(setUserData(email));
      localStorage.setItem("loginData", email);
      const unique_id = Math.random().toString(36).substring(2, 35);
      localStorage.setItem("accessToken", unique_id);

      navigateToPage("/", navigate);
    }
  };

  const handleSkip = event => {
    event.preventDefault();
    // navigateToPage("/", navigate);
    const unique_id = Math.random().toString(36).substring(2, 35);
    localStorage.setItem("loginData", "");
    localStorage.setItem("accessToken", "");
    openCamera();
  };

  const handleImage = event => {
    console.log(event.target.files[0]);
    const val = event.target.files[0];
    dispatch(fetchFishUpload(val));
    navigateToPage("/loading", navigate);
  };

  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content="Social Login | Atlantis Aquaventure"
        />
        <meta property="og:image" content="" />
        <meta property="og:image:secure_url" content="" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="" />
        <title>Social Login | Atlantis Aquaventure</title>
        <meta name="description" content="Social Login, Atlantis Aquaventure" />
      </Helmet>
      <div className="social-login-wrapper">
        <input
          type="file"
          accept="image"
          style={{ display: "none" }}
          ref={fileUpload}
          id="fish-btn"
          onChange={handleImage}
          capture="camera"
        />
        <div className="video-container">
          <PlayVideo src={videos.atlantis_bg_video} />
        </div>
        <div className="gradient-box"></div>
        <div className="socialLogin-container">
          <div className="social-logo">
            <Link to="/">
              <img src={logoMobileWhite} alt="" className="logo" />
            </Link>
          </div>
          <div className="login-social-contents">
            <div className="top-section">
              <div className="top-section-1">
                <h5 className="title">Sign In to Continue</h5>

                <div className="login-social-btn">
      
                  <div className="social-btn">
                    <img
                      src={images.facebook_logo}
                      className="social-icon"
                      alt=""
                    />
                    <LoginSocialFacebook
                      appId={"292131049975721"}
                      onResolve={({ provider, data }) => {
                        setProvider(provider);
                        setProfile(data);
                        console.log("new faceebook data", data);
                        localStorage.setItem("loginData", data.email);
                        localStorage.setItem("accessToken", data.accessToken);
                        if (data.email) {
                          navigateToPage("/", navigate);
                        }
                        console.log(provider, "provider");
                      }}
                      onReject={err => {
                        console.log(err);
                      }}
                    >
                      <CustomFacebookButton />
                    </LoginSocialFacebook>
                  </div>
                  <div className="social-btn">
                    <img
                      src={images.google_logo}
                      alt=""
                      className="google-icon"
                    />
                    <LoginSocialGoogle
                      client_id="376447249716-bjulerkju5ufl71grsuhs1qh0e079cgc.apps.googleusercontent.com"
                      onResolve={({ provider, data }) => {
                        setProvider(provider);
                        setProfile(data);
                        console.log("goooogle data", data);
                        localStorage.setItem("loginData", data.email);
                        localStorage.setItem("accessToken", data.access_token);

                        if (data.email) {
                          navigateToPage("/", navigate);
                        }
                        console.log(provider, "provider");
                      }}
                      onReject={err => {
                        console.log("hbhbdhd", err);
                      }}
                    >
                      <CustomGoogleButton />
                    </LoginSocialGoogle>
                  </div>
                </div>

         
                <p className="description d-m-center">
                  By continuing, you agree with our
                  <br />
                  <span
                    className="highlight"
                    onClick={() =>
                      navigateToPage("/Terms-and-Conditions", navigate)
                    }
                  >
                    Terms & Conditions.
                  </span>
                </p>
              </div>
            </div>
            <div className="middle-section">
              <div className="image">
                <img src={images.seahorse_logo} alt="" />
              </div>
              <span className="d-m-left">
                Or enter your email address and we’ll verify you..
              </span>
            </div>

            <div className="bottom-section">
              <div className="bottom-btn">
                <form>
                  <input
                    className="email-input"
                    type="email"
                    placeholder="Enter your email address"
                    name="email"
                    id="email"
                    value={email}
                    onChange={handleChange}
                  />
                  {error && (
                    <p className="errorMessage" style={{fontSize: "12px", 
                      color: '#ff0000',
                      padding: '2px 20px 0'
                    }}>{error}</p>
                  )}
                  <button
                    className="submit-btn"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!email}
                  >
                    Submit
                  </button>
                </form>

                <div className="bottom-two-section">
                  <span onClick={checkoutSocialLogin}>Cancel Scan Fish</span>
                  <span
                    onClick={handleSkip}
                    // onClick={openCamera}
                  >
                    Skip &nbsp; <img src={images.chevron_right} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialLogin;
