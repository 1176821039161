
import icon_acc_plus from "./btn-acc-plus.svg";
import icon_acc_minus from "./btn-acc-minus.svg";
import icon_menu from "./btn-acc-plus.svg";
import icon_scan from "./btn-acc-plus.svg";
import icon_heart_handshake from "./heart-handshake.svg";
import icon_heart_handshake_active from "./heart-handshake-active.svg";
import icon_home from "./btn-home.svg";
import close from "./close.svg";
import homeNavIcon from './footer-nav-icons/home.svg';
import attractionsNavIcon from './footer-nav-icons/attractions.svg';
import experiencesNavIcon from './footer-nav-icons/experiences.svg';
import experiencesNavIconWhite from './footer-nav-icons/experiences-white.svg';
import discoverNavIcon from './footer-nav-icons/discover.svg';

export default {
    icon_acc_plus, 
    icon_menu, 
    icon_scan, 
    icon_heart_handshake,
    icon_heart_handshake_active,
    icon_acc_minus, 
    icon_home,
    close,
    discoverNavIcon,
    experiencesNavIcon,
    experiencesNavIconWhite,
    attractionsNavIcon,
    homeNavIcon
}