
export const ConservationIcons = [
    {
        "id": 1,
        "image": "icon1.svg",
        "title": "Extinct",
        "type":"EX"
    },
    {
        "id": 2,
        "image": "icon2.svg",
        "title": "Extinct in the Wild",
        "type":"EW"
    },
    {
        "id": 3,
        "image": "icon3.svg",
        "title": "Critically Endangered",
        "type":"CR"
    },
    {
        "id": 4,
        "image": "icon4.svg",
        "title": "Endangered",
        "type":"EN"
    },
    {
        "id": 5,
        "image": "icon5.svg",
        "title": "Vulnerable",
        "type":"VU"
    },
    {
        "id": 6,
        "image": "icon6.svg",
        "title": "Near Threatened",
        "type":"NT"
    },
    {
        "id": 7,
        "image": "icon7.svg",
        "title": "Least Concern",
        "type":"LC"
    },
    {
        "id": 8,
        "image": "icon8.svg",
        "title": "Data Deficient",
        "type":"DD"
    },
    {
        "id": 9,
        "image": "icon9.svg",
        "title": "Not Evaluated",
        "type":"NE"
    },
];


export const expertsEntries = [
    { id: 1, name: 'Natasha Christie', position: 'Director, The Lost Chambers Aquarium and Ticketing', img: 'https://www.theoceantale.com/atlantis/wp-content/uploads/2020/03/malika-p-atlantis.jpg' },
    { id: 2, name: 'Robert Bennet', position: 'Manager, The Lost Chambers Aquarium', img: 'https://www.theoceantale.com/atlantis/wp-content/uploads/2020/03/apurba-samanta.jpg' },
    { id: 3, name: 'Natasha Christie', position: 'Director, The Lost Chambers Aquarium and Ticketing', img: 'https://www.theoceantale.com/atlantis/wp-content/uploads/2020/03/malika-p-atlantis.jpg' },
    { id: 4, name: 'Robert Bennet', position: 'Manager, The Lost Chambers Aquarium', img: 'https://www.theoceantale.com/atlantis/wp-content/uploads/2020/03/apurba-samanta.jpg' },
    { id: 5, name: 'Natasha Christie', position: 'Director, The Lost Chambers Aquarium and Ticketing', img: 'https://www.theoceantale.com/atlantis/wp-content/uploads/2020/03/malika-p-atlantis.jpg' },
];

export const fishDetail=[
    { id: 1,label:'length', value:'20cm'},
    { id: 2,label:'weight', value:'100g'},
    { id: 3,label:'height', value:'0-5m'},
    { id: 4,label:'heart', value:'10 years'}
]

export const ImageIconDummy=
    { image: '12', title:'Popular'}

export const galleryData={
        image:"https://www.theoceantale.com/atlantis/wp-content/uploads/2020/02/6-1.jpg", name:"Preserving our Natural Environment"
    }

    export const packageData={
        image:"https://www.theoceantale.com/atlantis/wp-content/uploads/2020/02/6-1.jpg", 
        text:'20 minutes swimming on the surface and FREE same-day access to The Lost Chambers Aquarium'
    }

export const slideData=[
{
    image:"https://www.theoceantale.com/atlantis/wp-content/uploads/2020/03/atlantis_cropped.jpg",
     name: "Tiger Shovelnose Catfish"
},
{
    image:"https://www.theoceantale.com/atlantis/wp-content/uploads/2020/03/atlantis_cropped.jpg",
     name: "Tiger Shovelnose Catfish"
},
{
    image:"https://www.theoceantale.com/atlantis/wp-content/uploads/2020/03/atlantis_cropped.jpg",
     name: "Tiger Shovelnose Catfish"
},
{
    image:"https://www.theoceantale.com/atlantis/wp-content/uploads/2020/03/atlantis_cropped.jpg",
     name: "Tiger Shovelnose Catfish"
},
{
    image:"https://www.theoceantale.com/atlantis/wp-content/uploads/2020/03/atlantis_cropped.jpg",
     name: "Tiger Shovelnose Catfish"
}

]
