import React, { useEffect, useState } from 'react';
import ImageBannerWithContent from '../../components/UI_Elements/ImageBannerWithContent/ImageBannerWithContent';
import ImageBanner from '../../components/UI_Elements/ImageBanner/ImageBanner';
import Card from '../../components/UI_Elements/Card/Card';
import TakePledge from '../../components/UI_Elements/TakePledge/TakePledge';
import ExpertsList from '../../components/UI_Elements/ExpertsList/ExpertsList';
import { useDispatch, useSelector } from "react-redux";
import { fetchingAttractionDetail, fetchPledgeOptions } from "../../actions/attraction";
import { checkDeviceType } from '../../actions/CheckDevice';
import MainTitleContent from '../../components/UI_Elements/MainTitleContent/MainTitleContent';
import CarouselWithPagination from '../../components/Carousels/CarouselWithPagination/CarouselWithPagination';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import icons from '../../assets/icons/icons';
import Button from '../../components/UI_Elements/Button/Button';
import InputField from '../../components/UI_Elements/InputField/InputField';
import PledgeForm from '../../components/Modals/PledgeForm/PledgeForm';
import ScanResult from '../../components/Modals/ScanResult/ScanResult';
import BlueBannerContent from '../../components/BlueBannerWithContent/BlueBannerContent';
import { useNavigate } from "react-router-dom";
import { expertsEntries } from "../../utils/dummy";
import SocialShare from '../../components/Modals/SocialShare/SocialShare';
import { useParams } from 'react-router-dom';
import ParagraphLight from '../../components/UI_Elements/ParagraphLight/ParagraphLight';
import ParagraphDark from '../../components/UI_Elements/ParagraphDark/ParagraphDark';
import { navigateToPage } from "../../CustomHooks/navigateToRoute";
import { Helmet } from 'react-helmet';
import { setActiveUrl } from "../../actions/NavigateUrls";

const AttractionDetail = () => {
const navigate = useNavigate();
const params = useParams();

const deviceType = useSelector((state) => state.checkDevice.deviceType);
let pageDetail = useSelector((state) => state.attraction.data);
let pleadgeOption = useSelector((state) => state.attraction.pleadgeOption);

const metaTitle = useSelector((state) => state.attraction.metaTitle);
const metaImage = useSelector((state) => state.attraction.metaImage);
const metaDescription = useSelector(
(state) => state.attraction.metaDescription
);

const dispatch = useDispatch();

useEffect(() => {
  dispatch(setActiveUrl("/attractions/details"));
}, []);

useEffect(() => {
  dispatch(checkDeviceType());
  dispatch(fetchingAttractionDetail(params.attractionId));
  dispatch(fetchPledgeOptions());
}, [dispatch, deviceType, params.attractionId]);

const [visible, setVisible] = useState(false);
const [socialShareVisible, setSocialShareVisible] = useState(false);

useEffect(() => {
  if (visible) {
    document.body.classList.add("mobile-menu-visible");
  } else {
    document.body.classList.remove("mobile-menu-visible");
  }
  return () => {
    document.body.classList.remove("mobile-menu-visible");
  };
  }, [visible]
);

const show = () => {
  setVisible(true);
};

const hide = () => {
  setVisible(false);
};

const submitData = () => {
  setVisible(false);
  setSocialShareVisible(true);
};

const LearnMore = () => {
  window.open(
  "https://www.atlantis.com/dubai/atlantis-aquaventure/atlas-village",
  "_blank"
  );
};

const CustomCloseIcon = () => {
  return <span>X</span>; // Custom close icon element or component
};

const CustomCloseButton = ({ onClick }) => (
  <button className="custom-close-button" onClick={onClick}>
    <img src="/path/to/close-icon.png" alt="Close" />
  </button>
);

let importantNoticeRendered = false;

  return (
    <>
      <Helmet>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:secure_url" content={metaImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className="attractionDetail-container dynamicMinHeight">
        {
        Object.keys(pageDetail).map(key => {
          const section = pageDetail[key];

          if (key === "section_banner") {
            return (
              <div key={key}>
              {deviceType === "desktop" ? (
                <ImageBannerWithContent
                  customClass="dolphinBay"
                  bannerImage={section?.image}
                  heading={section?.title}
                  subHeading={section?.description?.rendered}
                  imgAnimationType="zoom-out"
                  imgAnimationDuration={100}
                  descAnimationType={deviceType === "desktop" ? "fade-up" : "fade-up"}
                  descAnimationDuration={100}
                  titleAnimationType={deviceType === "desktop" ? "fade-up" : "fade-up"}
                  titleAnimationDuration={100}
                  textColor="light"
                />
                ) : (
                <div>
                  <ImageBanner
                    customClass="noContentBanner"
                    customStyle={{height: "250px", width: "100%", marginBottom: "25px",}}
                    bannerImage={section?.image}
                    animationType="fade-up"
                    animationDuration={100}
                  />
                  <div className="meetDolphinSection container">
                  <MainTitleContent
                    cardAnimationType="fade-up"
                    cardAnimationDuration={100}
                    text={section?.title}
                    customClass="d-m-center"
                    theme="themeDark"
                    gapBetween="16px"
                  >
                    <ParagraphDark
                      para={section?.description?.rendered}
                      htmlPara={true}
                    />
                  </MainTitleContent>
                </div>
              </div>
                )}
              </div>
            );
          }

          if (!importantNoticeRendered && key !== "uimportant_notice" && pageDetail["uimportant_notice"]?.title && pageDetail["uimportant_notice"]?.description) {
            importantNoticeRendered = true;
            return (
              <div key="uimportant_notice" className={`container important_notice ${
                deviceType === "mobile" ? "spaceTB4020" : "spaceTB4020"}`}
                style={{ marginBottom: '40px' }}>
                <BlueBannerContent
                  heading={pageDetail["uimportant_notice"]?.title}
                  paragraph={pageDetail["uimportant_notice"] ?.description}
                  cardAnimationType={deviceType === "desktop" ? "fade-up" : "fade-up"}
                  cardAnimationDuration={100}
                />
              </div>
            );
          }

          if (key === "section_meet_ours") {
            return (
              <>
                {section.species.length > 0 ? (
                  <div key={key} className="meetDolphinSection container">
                    <MainTitleContent
                      text={section?.title}
                      customClass="d-left-m-center"
                      theme="themeDark"
                      gapBetween="16px"
                      cardAnimationType="fade-up"
                      cardAnimationDuration={100}
                    >
                      <ParagraphDark para={section?.description} htmlPara={true}/>
                    </MainTitleContent>
                    <CarouselWithPagination
                      listItem={section?.species}
                      activeDeviceType={deviceType}
                    />
                  </div>
                ) : (
                  <div style={{ padding: "15px" }}></div>
                )
                }
              </>
            );
          }

          if (key === "section_experiences") {
            return (
              <div key={key} className="container">
                <div>
                  <Card
                    title={section?.experiences?.post_title}
                    text={section?.experiences?.post_content}
                    imgSrc={section?.experiences?.image}
                    btnText={section?.cta?.title}
                    onClick={() => navigateToPage(`/experiences`, navigate)}
                    cardAnimationType={deviceType === 'desktop' ? "fade-up" : ""}
                    cardAnimationDuration={100}
                  >
                    <ul data-aos={deviceType === 'desktop' ? "fade-up" : ""} data-aos-delay="100">
                      {section?.experiences?.acf?.recurrence && (
                      <li>{section?.experiences?.acf?.recurrence.charAt(0).toUpperCase() + section?.experiences?.acf?.recurrence.slice(1)}</li>
                      )}
                      {section?.experiences?.acf?.age && (
                        <li>{section?.experiences?.acf?.age.charAt(0).toUpperCase() + section?.experiences?.acf?.age.slice(1)}</li>
                    )}
                      {section?.experiences?.acf?.experienced && (
                        <li>Swimming Level: {section?.experiences?.acf?.experienced}</li>
                      )}
                    </ul>
                  </Card>
                </div>
              </div>
            );
          }

          if (key === 'take_a_pledge') {
            const hasVideoProperty = Object.prototype.hasOwnProperty.call(section, "video");
            return (
              <div key={key}>
                <TakePledge
                  mediaType={hasVideoProperty ? 'video' : 'image'}
                  title={section?.title}
                  text={section?.description}
                  linkTo="https://theoceantale.com/#/dolphin"
                  mediaSrc={hasVideoProperty ? section?.video : section?.image}
                  cardAnimationType={deviceType === 'desktop' ? "fade-up" : "fade-up"}
                  cardAnimationDuration={100}
                  textColor='light'
                >
                <Button text={section?.cta?.title} onClick={show} pledgeIcon={icons.icon_heart_handshake} buttonType='btn-white-outlined' textColor='light' />
                </TakePledge>
              </div>
            );
          }

          if (key === 'experts') {
            return (
              <div className='whiteContainer' key={key}>
                <div className='container'>
                  <ExpertsList items={section?.list}>
                  <MainTitleContent text={section?.title} customClass="d-left-m-center" theme='themeLight' gapBetween="16px"
                  cardAnimationType="fade-up"
                  cardAnimationDuration={100}>
                  <ParagraphLight para={section?.description} htmlPara={true} />
                  </MainTitleContent>
                  </ExpertsList>
                </div>
              </div>
            );
          }

          if (key === 'do_you_know') {
            return (
              <div key={key} className={`container ${deviceType === 'mobile' ? 'spaceTB40' : 'spaceTB40'}`}>
                <BlueBannerContent
                  heading={section?.title}
                  paragraph={section?.description}
                  RightButton1={{
                  text: section?.cta?.title,
                  textColor: "dark",
                  buttonType: "btn-backgrounded",
                  onClick: LearnMore
                  }}
                  cardAnimationType={deviceType === 'desktop' ? "fade-up" : "fade-up"}
                  cardAnimationDuration={100}
                />
              </div>
            );
          }

          return null;
          }
        )
      }

          <Rodal visible={visible} onClose={hide}
            showCloseButton={true}
            width={deviceType === 'desktop' ? 730 : window.innerWidth}
            height={deviceType === 'desktop' ? 640 : 'auto'}
            className="pledgeModal"
            customStyles={{ backgroundColor: '#fff' }}
            animation='slideUp'
            closeIcon={<CustomCloseIcon />}
            >
            <SocialShare />
          </Rodal>
        </div>
    </>
  );
}

export default AttractionDetail;