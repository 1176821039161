import React, {useState, useEffect} from "react";
import rightArrow from "../../assets/images/svg/arrow-right-blue.svg";
import leftArrow from "../../assets/images/svg/arrow-left-blue.svg";

const DancingArrow = (props) => {
  const [animation, setAnimation] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAnimation("dance-loop");
    }, props.delay);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [props.delay]);
  return (
    <div className="dancing-arrow-container">
      <div className={`dancing-arrow ${animation}`}>
        {props.arrowAlingRight?(
        <img src={rightArrow} alt="" />
        ):(
          <img src={leftArrow} alt="" />
        )}
      </div>
    </div>
  );
};

export default DancingArrow;
