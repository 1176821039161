import React, { useEffect, useState } from 'react';
import BlueIconwithText from '../../components/BlueIconwithText/BlueIconwithText';
import LongCardwithImageText from '../Carousels/LongCardwithImageText/LongCardwithImageText';
import { fetchingAnimalsDetails } from '../../actions/AllAnimals';
import { useDispatch, useSelector } from 'react-redux';


const CategoryList = ({ items }) => {
  const [isSticky, setIsSticky] = useState(false);
  const animalCategories = Object.values(items?.animal_categories || {});
  const dispatch = useDispatch();
  const animalsDetails = useSelector(state => state.allanimals.animalDetailsResponseData);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      setIsSticky(scrollPosition >= 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
const body = {
    term_id: "14",
  };
  const [initialLoadCategory, setInitialLoadCategory] = useState(body.term_id);
  const renderLongCard = animalsDetails?.list?.length > 0 && <LongCardwithImageText items={animalsDetails} />;
  useEffect(() => {
    // Fetch initial data when the component mounts
    dispatch(fetchingAnimalsDetails(initialLoadCategory));
  }, [dispatch, initialLoadCategory]);

  const handleClick = (term_id) => {
    dispatch(fetchingAnimalsDetails(term_id));
    setInitialLoadCategory(term_id);
  };

  return (
    <>

      <div className='category-border'>
        <div className='container'>
          <div className="category-list">
            {animalCategories?.map((item, index) => (
              <div 
              key={index}
              data-aos="fade-up"
              data-aos-delay={`${index}00`}
              >
              <BlueIconwithText
                key={index}
                image={item?.acf?.icon}
                title={item.name}
                onClick={handleClick}
                term_id={item.term_id}
                initialLoadingCategory={initialLoadCategory}
                animationType="fade-up"
                animationDuration={`${index}00`}
              />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='animalsDetails'>
        <div className='container'>
          {renderLongCard}
        </div>
      </div>
    </>
  );
};

export default CategoryList;
